import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UiUtilityService } from '../ui-utility.service';
import { finalize } from 'rxjs/operators';
import { ApiResponseModel } from './api-response.model';
import { LoggerService } from '../log.service';

@Injectable({
  providedIn: 'root',
})
export class DataService<T> {
  readonly headers: any = { headers: { 'Content-Type': 'application/json' } };
  // private readonly apiURL: string = 'assets/data/data1.json';

  constructor(
    public http: HttpClient,
    private uiUtilityService: UiUtilityService,
    private loggerService: LoggerService
  ) { }

  handleError = (error: HttpErrorResponse) => {
    const errorLog = `${error.url}-${error.status}-${error.statusText}`;
    this.loggerService.saveErrorToFile(errorLog);
    this.uiUtilityService.showAlert('An error occurred while processing your request.', 'Error');
    return throwError(error);
  }

  getData(url: string, params = {}): Observable<ApiResponseModel<T>> {
    const fullUrl: string = environment.baseUrl + url;
    return this.http.get<ApiResponseModel<T>>(fullUrl, { params }).pipe(
      catchError(this.handleError),
      finalize(() => this.uiUtilityService.closeLoader())
    );
  }

  getBlob(url: string, params = {}): Observable<Blob> {
    const fullUrl: string = environment.baseUrl + url;
    return this.http.get(fullUrl, { ...params, responseType: 'blob' }).pipe(
      catchError(this.handleError),
      finalize(() => this.uiUtilityService.closeLoader())
    );
  }

  post(
    url: string,
    payload: any,
    headers: any = { headers: { 'Content-Type': 'application/json' } }
  ): Observable<HttpEvent<ApiResponseModel<T>>> {
    const fullUrl: string = environment.baseUrl + url;
    return this.http
      .post<ApiResponseModel<T>>(fullUrl, payload, headers)
      .pipe(catchError(this.handleError), finalize(() => this.uiUtilityService.closeLoader()));
  }

  put(url: string, payload: any): Observable<HttpEvent<ApiResponseModel<T>>> {
    const headers: any = { headers: { 'Content-Type': 'application/json' } };
    const fullUrl: string = environment.baseUrl + url;
    return this.http
      .put<ApiResponseModel<T>>(fullUrl, payload, headers)
      .pipe(catchError(this.handleError), finalize(() => this.uiUtilityService.closeLoader()));
  }

  delete(
    url: string,
    payload: any
  ): Observable<HttpEvent<ApiResponseModel<T>>> {
    const headers: any = {
      headers: { 'Content-Type': 'application/json' },
      body: payload,
    };
    const fullUrl: string = environment.baseUrl + url;
    return this.http
      .delete<ApiResponseModel<T>>(fullUrl, headers)
      .pipe(catchError(this.handleError), finalize(() => this.uiUtilityService.closeLoader()));
  }
}
