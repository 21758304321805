import { Component } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { WearerService } from 'src/app/features/wearer-management/services/wearer.service';
import { BandEnum, BandPageFlowEnum } from 'src/app/shared/enums/band.enum';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { Band } from 'src/app/shared/models/wearer.model';
import { BandAssociationService } from '../../services/band-association.service';
import { BleService } from '../../services/ble.service';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { BandCompletionPage } from '../band-completion/band-completion.page';
import { AssetDetailsPage } from 'src/app/features/asset-management/pages/asset-details/asset-details.page';
import { WearerDetailsPage } from 'src/app/features/wearer-management/pages/wearer-details/wearer-details.page';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';

@Component({
  standalone: true,
  selector: 'app-band-scan',
  templateUrl: './band-scan.page.html',
  styleUrls: ['./band-scan.page.scss'],
  imports: [IonicModule, CommonModule, FormsModule],
  providers: [BLE, LocationAccuracy, Diagnostic, BleService],
})
export class BandScanPage {
  bandIcon = 'src/assets/images/bandicon.png';
  bandInstructions =
    'Press the hidden switch behind the initial "O" of the ONDO logo on the Band.';
  rssi = -130;
  readonly bandName: string = 'ONDO_BAND';
  bandFindingIntervalTime = 10000;
  bandScanTimeout = 60000;
  wearerBandData: any = null;
  bandId = '';
  deviceInfo: DeviceInfo | undefined;
  timeOut: any = null;
  title = '';
  bandAdvertisements: any[] = [];
  backUrl = '';
  bandFlow = '';
  isWaiting = true;
  associated: any = { text: '', status: '', img: '/assets/images/lookup_both.png', bandIdText: 'Band/Tag ID: ?', wearerText: 'Wearer/Asset ID: ?', hint: '', findAnother: false, details: '' };

  constructor(
    private ble: BLE,
    private bandAssociation: BandAssociationService,
    private wearerService: WearerService,
    public bandService: BandAssociationService,
    private bleService: BleService,
    private previousRouteService: PreviousRouteService,
    private ionNav: IonNav,
    private navParams: NavParams,
    private uiUtilityService: UiUtilityService
  ) {
    this.backUrl = this.previousRouteService.getPreviousUrl();
  }

  async ionViewWillEnter() {
    this.checkForFlow();
    this.deviceInfo = await Device.getInfo();
    if (this.deviceInfo.platform === 'android') {
      this.bleService.checkForAndroidVersion(this.deviceInfo).then((result) => {
        if (result) {
          this.checkBluetooth();
        }
      });
    } else if (this.deviceInfo.platform === 'ios') {
      this.fetchBandId();
    }
  }

  checkForFlow() {
    this.bandFlow = this.navParams.get('flow') || '';
    this.bandService.bandFlowTitle(this.bandFlow);
  }

  /**
   * Check weather device's bluetooth is enable or not
   */
  checkBluetooth(): void {
    this.ble.isEnabled().then(
      () => this.fetchBandId(),
      () => this.enableBluetooth()
    );
  }

  /**
   * Check weather device's bluetooth is enable or not
   */
  private enableBluetooth(): void {
    this.ble.enable().then(
      () => this.fetchBandId(),
      () =>
        this.bandAssociation.showAlert(
          'The user did not enable Bluetooth',
          'Not Found'
        )
    );
  }

  /**
   * Start fetching band ID from Band advertisement
   */
  fetchBandId() {
    this.resetData();
    this.scanBle();
    this.retrieveBandId();
  }

  /**
   * Start scanning band advertisement based on desired band condition for 1 min
   */
  scanBle(): void {
    this.bandAdvertisements = [];
    this.ble
      .startScanWithOptions([], { reportDuplicates: true })
      .subscribe((device) => {
        if (
          `${device.name}`.toUpperCase() === this.bandName &&
          !device.advertising.kCBAdvDataIsConnectable &&
          this.isODRFlagOn(device)
        ) {
          const isSameDevice = this.bandAdvertisements.find(
            (advertisement: any) => advertisement.id === device.id
          );
          if (!isSameDevice) {
            this.bandAdvertisements.push(device);
          }
        }
      });
    this.timeOut = setTimeout(() => {
      if (!this.bandAdvertisements.length) {
        this.bandAssociation.showAlert(
          'Could not find any band. Please try again',
          'Not Found'
        );
        this.stopBleScan();
      }
    }, this.bandScanTimeout);
  }

  /**
   * Clears timeout and intervals and start decoding bandId
   */
  retrieveBandId(): void {
    const interval = setInterval(() => {
      if (this.bandAdvertisements.length) {
        clearInterval(interval);
        clearTimeout(this.timeOut);
        this.stopBleScan();
        this.performActionBasedOnBandFlow();
      }
    }, this.bandFindingIntervalTime);
  }

  /**
   * Retrieve the band id from BLE advertisement and perform necessary action based on band flow
   * For Band Association flow - Checks on the backend for retrieved band availabilities,
   *  for associating to the wearer.
   * For Band lookup flow - Check on backend for associated user
   */
  performActionBasedOnBandFlow() {
    const bandIds = this.getBandIds();
    if (this.bandFlow !== BandPageFlowEnum.BandLookup) {
      this.getBandAvailability(bandIds);
    } else {
      if (bandIds.length > 1) {
        const header = 'Multiple Bands Found';
        const message =
          'Avoid pressing the button on other Bands nearby. Try again after 15 seconds.';
        this.bandAssociation.showAlert(message, header);
      } else {
        this.getBandStatus(bandIds[0]);
      }
    }
  }

  /**
   * Retrieve bandId from BLE Advertisements
   * @returns Array of band IDs
   */
  getBandIds() {
    const bandIds: string[] = [];
    this.bandAdvertisements.forEach((bandAdvertisement) => {
      const bandId = this.decodeBandId(bandAdvertisement);
      if (bandId && !bandIds.includes(bandId)) {
        bandIds.push(bandId);
      }
    });
    return bandIds;
  }

  resetData() {
    this.isWaiting = true;
    this.associated = { text: '', status: '', img: '/assets/images/lookup_both.png', bandIdText: 'Band/Tag ID: ?', wearerText: 'Wearer/Asset ID: ?', hint: '', findAnother: false, details: '' };
    const details = this.navParams.get('details');
    if (this.bandFlow === BandPageFlowEnum.BandLookup) {
      this.associated.wearerText = 'Wearer/Asset ID: ?';
    } else if (
      this.bandFlow === BandPageFlowEnum.AssetAssociation ||
      this.bandFlow === BandPageFlowEnum.AssetOndoTagAssociated
    ) {
      const assetName = details?.assetName ? details.assetName : '?';
      this.associated.wearerText = `Asset Name: ${assetName}`;
    } else {
      const wearerName = (details?.residentDetail.localId || details?.residentDetail.firstName || details?.residentDetail.lastName) ? (details?.residentDetail.localId || `${details?.residentDetail.firstName || ''} ${details?.residentDetail.lastName || ''}`) : '?';
      this.associated.wearerText = `Wearer ID: ${wearerName}`;
    }
  }

  associatedDetails(band: any) {
    let isNotAssociated = false;
    if (band.notFoundInGivenFacility) {
      this.associated = { text: 'Associated Elsewhere', status: 'red', img: band.isTag ? '/assets/images/lookup_tag_elsewhere.png' : '/assets/images/lookup_band_elsewhere.png', wearerText: 'Wearer/Asset ID: *****', hint: `This ${band.isTag ? 'Tag' : 'Band'} is already associated elsewhere, and cannot be used until its current association is removed or replaced with another Band or Tag.`, findAnother: true, details: '' };
    } else if (band.localId || band.firstName || band.lastName || band.assetName) {
      this.associated = { text: band.assetName ? 'Associated Asset Found' : 'Associated Wearer Found', status: 'blue', img: band.isTag ? '/assets/images/lookup_tag.png' : '/assets/images/lookup_band.png', wearerText: band.assetName ? `Asset Name: ${band.assetName}` : `Wearer ID: ${band.localId || ((band.firstName || '') + (band.lastName || ''))}`, hint: '', findAnother: true };
      this.associated.details = band.assetName ? 'Go to Asset Details' : 'Go to Wearer Details';
    } else {
      this.associated = { text: 'Not Associated', status: '', img: band.isTag ? '/assets/images/lookup_tag.png' : '/assets/images/lookup_band.png', wearerText: 'Wearer/Asset ID: N/A', hint: `This ${band.isTag ? 'Tag' : 'Band'} is not in use. You can associate with a wearer or an asset.`, findAnother: true, details: '' };
      isNotAssociated = true;
    }
    this.associated.bandIdText = band.isTag ? `Tag ID: ${band.bandId}` : `Band ID: ${band.bandId}`;
    return isNotAssociated;
  }

  getBandStatus(bandId: string) {
    this.isWaiting = true;
    this.bandAssociation
      .checkBandStatus(bandId)
      .subscribe((response: ApiResponseModel<any>) => {
        this.isWaiting = false;
        if (response.success && response.data) {
          this.bandService.bandStatus = response.data;
          if (response.data?.bandId) {
            this.associatedDetails(response.data);
          }
          if (this.bandFlow !== BandPageFlowEnum.BandLookup) {
            const params = this.navParams.data;
            this.ionNav.push(BandCompletionPage, {
              bandId,
              [BandEnum.PrevPage]: RouterEnum.bandScan,
              flow: this.bandFlow,
              bandInfo: response.data,
              ...params,
            });
          }
        } else {
          const header = 'Error';
          this.bandAssociation.showAlert(response.errorMessage!, header);
        }
      });
  }

  getBandAvailability(bandIds: string[]) {
    this.bandAssociation
      .checkBandAvailability(bandIds.toString())
      .subscribe((response: ApiResponseModel<Band[]>) => {
        if (response.success) {
          this.handleBandIds(response.data || []);
        } else {
          const header = 'Error';
          this.bandAssociation.showAlert(response.errorMessage!, header);
        }
      });
  }

  handleBandIds(bandIds: any[]) {
    if (bandIds.length === 1 && bandIds[0]?.bandId) {
      const details = this.navParams.get('details');
      const associatedId = details?.tag?.tagId || (details?.bands?.length && details.bands[0]?.bandId);
      if (bandIds[0].bandId === associatedId) {
        const header = 'Existing Association';
        const message =
          `This ${details?.tag?.tagId ? 'Tag' : 'Band'} is already associated with this ${details?.residentDetail?.id ? 'wearer' : 'asset'}. Use another Band that is not in use.`;
        const buttons = [
          {
            text: 'ok',
            handler: () => {
              this.ionNav.pop();
            },
          },
        ];
        this.uiUtilityService.showAlert(message, header, buttons);
      } else {
        const isNotAssociated = this.associatedDetails(bandIds[0]);
        this.associated.details = '';
        this.associated.findAnother = false;
        if (isNotAssociated) {
          const params = this.navParams.data;
          this.ionNav.push(BandCompletionPage, {
            bandId: bandIds[0].bandId,
            [BandEnum.PrevPage]: RouterEnum.bandScan,
            flow: this.bandFlow,
            bandInfo: bandIds[0],
            ...params
          });
        }
      }
    } else if (bandIds.length >= 2) {
      const header = 'Multiple ODRs';
      const message =
        'Currently app is scanning multiple bands with active ODR. Please try again after 30 seconds.';
      this.bandAssociation.showAlert(message, header);
    } else {
      const header = 'Band not found';
      const message =
        'This Band is already associated with a wearer. Use another Band that is not in use.';
      this.bandAssociation.showAlert(message, header);
    }
  }

  /**
   * Check for ODR advertisement from manufacturer data
   * Making ODR ON sets 11th number to 0
   * @param bandData -
   */
  isODRFlagOn(bandData: any): boolean {
    const odrFlagPos: string = '10';
    const manufacturerData: any = this.bandAssociation.fetchManufacturerData(
      bandData,
      this.deviceInfo?.platform!
    );
    let odrMask = 15; //0xF
    let odrCondition = manufacturerData[odrFlagPos] & odrMask;
    return odrCondition === 0;
  }

  /**
   * Decode bandId from advertisement manufacturer data
   * @param bandData -
   * @returns bandID
   */
  decodeBandId(bandData: any) {
    const bandIdPos: number[] = [3, 4, 5, 6];
    const manufacturerData: any = this.bandAssociation.fetchManufacturerData(
      bandData,
      this.deviceInfo?.platform!
    );
    return (
      manufacturerData[bandIdPos[3]] +
      (manufacturerData[bandIdPos[2]] << 8) +
      (manufacturerData[bandIdPos[1]] << 16) +
      (manufacturerData[bandIdPos[0]] << 24)
    );
  }

  /**
   * Stop ble scanning
   */
  stopBleScan() {
    this.isWaiting = false;
    this.ble.stopScan().then(() => console.log('Scan complete'));
  }

  goToDetails() {
    if (this.bandService.bandStatus?.assetId) {
      this.ionNav.push(AssetDetailsPage, { assetId: this.bandService.bandStatus.assetId, mode: 'detail' });
    } else if (this.bandService.bandStatus?.wearerId) {
      this.ionNav.push(WearerDetailsPage, { id: this.bandService.bandStatus.wearerId, mode: 'detail' });
    }
  }

  async removeBandScanPage() {
    for (let i = 0; i < 50; i++) {
      const activeNav = await this.ionNav.getByIndex(i);
      if (activeNav?.element?.localName === 'app-band-scan') {
        await this.ionNav.removeIndex(i);
        break;
      }
    }
  }

  ionViewWillLeave(): void {
    clearTimeout(this.timeOut);
    this.stopBleScan();
    this.removeBandScanPage();
  }
}
