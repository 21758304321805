<app-unified-header [title]="'Assign ' + title" [defaultHref]="defaultHref" (onSave)="assignItems()"
  [saveBtn]="{title: 'Done', isDisable: isSaveDisabled}"></app-unified-header>

<ion-content>
  <ion-item class="list-header" lines="none">
    <ion-label>
      <h2>Unassigned {{title}}</h2>
    </ion-label>
  </ion-item>
  <ion-searchbar *ngIf="assignedItems?.length" (ionInput)="handleChange($event)" [debounce]="100"
    class="items-searchbar" animated placeholder="Type to search...">
  </ion-searchbar>
  <div *ngIf="filteredItems?.length">
    <div class="list-note ion-padding-horizontal">
      <ion-note>{{unassignItemsNote}}</ion-note>
    </div>
    <ion-item *ngFor="let assignedItem of filteredItems">
      <ion-checkbox color="controls" labelPlacement="end" justify="start" [checked]="assignedItem.checked"
        (ionChange)="handleChecked($event, assignedItem.id)">{{assignedItem.itemName ||
        assignedItem.itemNameFallback}}</ion-checkbox>
    </ion-item>
  </div>
  <div class="no-items" *ngIf="!assignedItems?.length">
    {{noAssignItemsNote}}
  </div>
</ion-content>