<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Facility Management</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToAddFacility()" [nAccess]="'post'+EndPoints.manageFacility">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content>
  <!-- <cdk-virtual-scroll-viewport class="ion-content-scroll-host" itemSize="45" minBufferPx="900" maxBufferPx="1350"> -->
  <!-- <ion-item-divider *virtualHeader="let groupAlphabet">
      <ion-label>{{groupAlphabet}}</ion-label>
    </ion-item-divider> -->
  <ion-list>
    <ng-container *ngFor="let facility of facilityList">
      <ion-item detail (click)="navigateToDetailsPage(facility)">
        <ion-label>{{facility.facilityName}}</ion-label>
      </ion-item>
    </ng-container>
  </ion-list>

  <!-- </cdk-virtual-scroll-viewport> -->
</ion-content>