import {
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  IonicModule,
  ModalController,
  Platform,
  NavParams,
  IonNav,
  ToggleCustomEvent,
  MenuController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { InputValidator } from 'src/app/core/services/input-config.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { BridgeService } from '../../services/bridge.service';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { bridgeDetailsInputConfig } from './bridge-details-input.config';
import { UnifiedInput } from 'src/app/shared/components/unified-custom-input/unified-input.types';
import { UnifiedCustomInputComponent } from 'src/app/shared/components/unified-custom-input/unified-custom-input.component';
import { cloneDeep } from 'lodash-es';
import { UnifiedSelectComponent } from 'src/app/shared/components/unified-select/unified-select.component';
import { SettingsService } from 'src/app/features/settings/services/settings.service';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { RoomDetailsPage } from 'src/app/features/settings/room-management/room-details/room-details.page';
import { HeaderService } from 'src/app/core/services/header.service';
import {
  BridgePlacementEnum,
  BridgeStatusEnum,
} from 'src/app/shared/enums/bridge-status.enum';
import { BedDetailsPage } from 'src/app/features/bed-management/pages/bed-details/bed-details.page';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  standalone: true,
  selector: 'app-bridge-details',
  templateUrl: './bridge-details.page.html',
  styleUrls: ['./bridge-details.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedCustomInputComponent,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BridgeDetailsPage implements OnInit, OnDestroy {
  @Input('bridgeId') bridgeId: string = '';
  @Input('mode') mode: string = 'detail';
  @ViewChild('plotElement') plotElement: ElementRef | undefined;
  isRootPage: boolean = false;
  destroyRef = inject(DestroyRef);
  bridgeDetails: any = null;
  bridgeDetailsForm: FormGroup | null = null;
  isSaveDisabled = true;
  subscriptions: Subscription = new Subscription();
  validator: InputValidator | null = null;
  bridgeDetailsInputConfig: UnifiedInput[] = cloneDeep(
    bridgeDetailsInputConfig
  );
  backButtonClicked: boolean = false;
  isReadMode = true;
  rooms: any[] = [];
  subscription: any;
  facilityName: string = '';
  placementTypeValue: string = '';
  placementList: any[] = [];
  roomList: any[] = [];
  bedList: any[] = [];
  floorList: any[] = [];
  floorImage: any = '';
  currentBridges: any[] = [];
  isPlacementAllowNav: boolean = true;
  EndPoints = EndPointsEnum;
  isSummaryEnabled = true;

  constructor(
    private bridgeService: BridgeService,
    private formBuilder: FormBuilder,
    private uiUtilityService: UiUtilityService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private navParams: NavParams,
    private nav: IonNav,
    private headerService: HeaderService,
    private settingsService: SettingsService,
    private menuCtrl: MenuController,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const bridgeId = this.navParams.get('bridgeId');
    const mode = this.navParams.get('mode');
    this.didOnInit(bridgeId, mode);
    this.facilityName = this.headerService.getFacilityName();
  }

  didOnInit(bridgeId: string, mode: string) {
    this.bridgeId = bridgeId;
    this.mode = mode;
    this.isReadMode = this.mode === 'edit' ? false : true;
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribeWithPriority(
      10,
      () => {
        console.log('Hardware back clicked');
      }
    );
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  didViewWillEnter() {
    this.isSaveDisabled = true;
    if (this.bridgeId) {
      this.getBridgeDetails();
    } else {
      this.setConfig('detail', true);
      const defaultValues = this.uiUtilityService.getValuesFromUnifiedInput(
        bridgeDetailsInputConfig
      );
      this.createForm(defaultValues);
      this.subscribeForm();
    }
  }

  subscribeForm() {
    this.bridgeDetailsForm?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.bridgeDetailsForm) {
          this.isSaveDisabled =
            this.bridgeDetailsForm.invalid || !this.bridgeDetailsForm.dirty;
        }
      });
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  setBridgeDetails(data: any) {
    this.bridgeDetails = data;
    if (this.bridgeDetails?.bridgeInfo?.bridgeId) {
      this.setConfig('', '', this.bridgeDetails.bridgeInfo);
      this.setConfig('detail', !this.isReadMode);
    }
    this.floorImage = this.bridgeDetails.placementInfo?.floorImage || '';
    this.createForm(this.bridgeDetails?.bridgeInfo);
    this.subscribeForm();
    this.isSaveDisabled = false;
    if (this.f['xvalue']?.value !== null) {
      setTimeout(() => {
        this.preview(true);
      }, 500);
    }
  }

  async getBridgeDetails() {
    await this.uiUtilityService.showLoader();
    this.bridgeService
      .getBridgeDetails(this.bridgeId)
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          const res = response.data;
          if (res.bridgeInfo.hasOwnProperty('bridgeStatus')) {
            res.bridgeInfo.bridgeStatusName = this.getBridgeStatus(
              res.bridgeInfo.bridgeStatus
            );
          }
          if (res.placementInfo?.placeMentTypeId) {
            this.placementTypeValue = this.getPlacementInfo(res.placementInfo);
            const placementTypeId = res.placementInfo.placeMentTypeId;
            if (placementTypeId === BridgePlacementEnum.room) {
              this.isSummaryEnabled = this.authService.isFeatureEnabled('getRoomDetail');
            } else if (placementTypeId === BridgePlacementEnum.bed) {
              this.isSummaryEnabled = this.authService.isFeatureEnabled('getBedDetail');
            }
          }
          if (
            res.placementInfo?.placeMentTypeId === BridgePlacementEnum.other
          ) {
            this.isPlacementAllowNav = false;
          }
          this.setBridgeDetails(res);
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  setConfig(key: string, value: any, data?: any) {
    this.bridgeDetailsInputConfig.forEach((item: any) => {
      if (data) {
        item.value = data[item.name];
      } else {
        item[key] = value;
      }
    });
  }

  getBridgeStatus(bridgeStatus: any) {
    switch (bridgeStatus) {
      case 0:
        return BridgeStatusEnum.offline;
      case 1:
        return BridgeStatusEnum.online;
      case 2:
        return BridgeStatusEnum.initialising;
      default:
        return '';
    }
  }

  getPlacementInfo(placementInfo: any) {
    const placementTypeId = placementInfo?.placeMentTypeId;
    switch (placementTypeId) {
      case BridgePlacementEnum.room:
        return placementInfo?.roomName;
      case BridgePlacementEnum.bed:
        return placementInfo?.bedName;
      case BridgePlacementEnum.other:
        return placementInfo?.floorName;
      default:
        return '';
    }
  }

  createForm(bridgeDetails: any) {
    this.bridgeDetailsForm = this.formBuilder.group({
      bridgeName: [bridgeDetails?.bridgeName, [Validators.required]],
      facilityId: [this.headerService.getFacilityId()],
      bridgeStatus: [bridgeDetails?.bridgeStatus],
      bleMacId: [bridgeDetails?.bleMacId, [Validators.required]],
      wifiMacId: [bridgeDetails?.wifiMacId, [Validators.required]],
      firmwareVersion: [bridgeDetails?.firmware, [Validators.required]],
      wifiNetwork: [bridgeDetails?.wifiNetwork],
      placementId: [this.bridgeDetails?.placementInfo?.placeMentTypeId || null],
      placementName: [this.bridgeDetails?.placementInfo?.placeMentType || null],
      roomId: [this.bridgeDetails?.placementInfo?.roomId || null],
      roomName: [this.bridgeDetails?.placementInfo?.roomName || null],
      bedId: [this.bridgeDetails?.placementInfo?.id || null],
      bedName: [this.bridgeDetails?.placementInfo?.bedName || null],
      floorId: [this.bridgeDetails?.placementInfo?.floorId || null],
      floorName: [this.bridgeDetails?.placementInfo?.floorName || null],
      xvalue: [this.bridgeDetails?.placementInfo?.xValue],
      yvalue: [this.bridgeDetails?.placementInfo?.yValue],
      otherId: [null],
      useForLocation: [this.bridgeDetails?.bridgeInfo?.useForLocation || false]
    });
  }

  inputChanged(inputConfig: UnifiedInput) {
    if (this.bridgeDetailsForm && inputConfig.fieldName) {
      const found = this.bridgeDetailsInputConfig.find(
        (item: any) => item.fieldName === inputConfig.fieldName
      );
      if (found) {
        found.value = inputConfig.value;
        this.bridgeDetailsForm.markAsDirty();
        this.bridgeDetailsForm.controls[inputConfig.fieldName].patchValue(
          inputConfig.value
        );
      }
    }
  }

  async showSelectModal({
    title,
    emptyMessage,
    key,
    displayName,
    value,
    list,
    isSave,
  }: any) {
    const modal = await this.modalCtrl.create({
      component: UnifiedSelectComponent,
      componentProps: {
        data: { title, emptyMessage, key, displayName, value, list },
        isSave,
      },
    });
    modal.present();
    return await modal.onWillDismiss();
  }

  async didSelectPlacementModal() {
    const { data, role } = await this.showSelectModal({
      title: 'Select Placement',
      emptyMessage: 'No placements available right now.',
      key: 'id',
      displayName: 'name',
      value: this.f['placementId']?.value || '',
      list: this.placementList,
    });
    if (role === 'confirm' && data) {
      const placement = this.placementList.find(
        (item: any) => item.id === data
      );
      if (placement) {
        this.bridgeDetailsForm?.controls['placementName'].patchValue(
          placement.name
        );
        this.bridgeDetailsForm?.controls['placementId'].patchValue(
          placement.id
        );
        this.bridgeDetailsForm?.controls['floorId'].patchValue(null);
        this.bridgeDetailsForm?.controls['floorName'].patchValue(null);
        this.bridgeDetailsForm?.controls['roomId'].patchValue(null);
        this.bridgeDetailsForm?.controls['roomName'].patchValue(null);
        this.bridgeDetailsForm?.controls['bedId'].patchValue(null);
        this.bridgeDetailsForm?.controls['bedName'].patchValue(null);
        this.bridgeDetailsForm?.controls['xvalue'].patchValue(null);
        this.bridgeDetailsForm?.controls['yvalue'].patchValue(null);
        this.floorImage = '';
        this.bridgeDetailsForm?.markAsDirty();
        this.bridgeDetailsForm?.updateValueAndValidity();
      }
    }
  }

  async setPlacement() {
    if (this.placementList.length) {
      this.didSelectPlacementModal();
    } else {
      await this.uiUtilityService.showLoader();
      this.bridgeService.getBridgePlacementList().subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.placementList = response.data;
          this.didSelectPlacementModal();
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
    }
  }

  async getFloorDetails(floorId: string) {
    await this.uiUtilityService.showLoader();
    this.settingsService.getFloorDetails(floorId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.floorImage = response.data?.floorImage || '';
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  async didSelectRoomModal() {
    const { data, role } = await this.showSelectModal({
      title: 'Select Room',
      emptyMessage: 'No rooms available right now.',
      key: 'id',
      displayName: 'roomName',
      value: this.f['roomId']?.value || '',
      list: this.roomList,
    });
    if (role === 'cancel' || role === 'backdrop') {
      return;
    }
    if (role === 'confirm' && data) {
      const room = this.roomList.find((item: any) => item.id === data);
      if (room) {
        this.bridgeDetailsForm?.controls['roomName'].patchValue(room.roomName);
        this.bridgeDetailsForm?.controls['roomId'].patchValue(room.id);
        this.bridgeDetailsForm?.controls['floorName'].patchValue(
          room.floorName
        );
        this.bridgeDetailsForm?.controls['floorId'].patchValue(room.floorId);
        this.floorImage = '';
        this.getFloorDetails(room.floorId);
      }
    } else {
      this.bridgeDetailsForm?.controls['roomName'].patchValue(null);
      this.bridgeDetailsForm?.controls['roomId'].patchValue(null);
      this.bridgeDetailsForm?.controls['floorName'].patchValue(null);
      this.bridgeDetailsForm?.controls['floorId'].patchValue(null);
      this.floorImage = '';
    }
    this.bridgeDetailsForm?.controls['xvalue'].patchValue(null);
    this.bridgeDetailsForm?.controls['yvalue'].patchValue(null);
    this.bridgeDetailsForm?.markAsDirty();
    this.bridgeDetailsForm?.updateValueAndValidity();
  }

  async setRoom() {
    if (this.roomList.length) {
      this.didSelectRoomModal();
    } else {
      await this.uiUtilityService.showLoader();
      this.settingsService
        .getRoomListByFacilityId()
        .subscribe((response: any) => {
          if (response && response.success && response.data) {
            const none = {
              id: '',
              roomName: 'None',
            };
            this.roomList = response.data?.length
              ? [none, ...response.data]
              : [];
            this.didSelectRoomModal();
          } else {
            const header = 'Error';
            this.uiUtilityService.showAlert(response.errorMessage!, header);
          }
        });
    }
  }

  async didSelectBedModal() {
    const { data, role } = await this.showSelectModal({
      title: 'Select Bed',
      emptyMessage: 'No beds available right now.',
      key: 'id',
      displayName: 'bedName',
      value: this.f['bedId']?.value || '',
      list: this.bedList,
    });
    if (role === 'cancel' || role === 'backdrop') {
      return;
    }
    if (role === 'confirm' && data) {
      const bed = this.bedList.find((item: any) => item.id === data);
      if (bed) {
        this.bridgeDetailsForm?.controls['bedId'].patchValue(bed.id);
        this.bridgeDetailsForm?.controls['bedName'].patchValue(bed.bedName);
      }
    } else {
      this.bridgeDetailsForm?.controls['bedId'].patchValue(null);
      this.bridgeDetailsForm?.controls['bedName'].patchValue(null);
    }
    this.bridgeDetailsForm?.markAsDirty();
    this.bridgeDetailsForm?.updateValueAndValidity();
  }

  async setBed() {
    if (this.bedList.length) {
      this.didSelectBedModal();
    } else {
      await this.uiUtilityService.showLoader();
      this.bridgeService.getBedList().subscribe((response: any) => {
        if (response && response.success && response.data) {
          const none = {
            id: '',
            bedName: 'None',
          };
          this.bedList = response.data?.length ? [none, ...response.data] : [];
          this.didSelectBedModal();
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
    }
  }

  async didSelectFloorModal() {
    const { data, role } = await this.showSelectModal({
      title: 'Select Floor',
      emptyMessage: 'No floors available right now.',
      key: 'floorID',
      displayName: 'floorName',
      value: this.f['floorId']?.value || '',
      list: this.floorList,
    });
    if (role === 'cancel' || role === 'backdrop') {
      return;
    }
    if (role === 'confirm' && data) {
      const floor = this.floorList.find((item: any) => item.floorID === data);
      if (floor) {
        this.bridgeDetailsForm?.controls['floorId'].patchValue(floor.floorID);
        this.bridgeDetailsForm?.controls['floorName'].patchValue(
          floor.floorName
        );
        this.floorImage = '';
        this.getFloorDetails(floor.floorID);
      }
    } else {
      this.bridgeDetailsForm?.controls['floorId'].patchValue(null);
      this.bridgeDetailsForm?.controls['floorName'].patchValue(null);
      this.floorImage = '';
    }
    this.bridgeDetailsForm?.controls['xvalue'].patchValue(null);
    this.bridgeDetailsForm?.controls['yvalue'].patchValue(null);
    this.bridgeDetailsForm?.markAsDirty();
    this.bridgeDetailsForm?.updateValueAndValidity();
  }

  async setFloor() {
    if (this.floorList.length) {
      this.didSelectFloorModal();
    } else {
      await this.uiUtilityService.showLoader();
      this.bridgeService.getFacilityFloorsList().subscribe((response: any) => {
        if (response && response.success && response.data?.floors) {
          const none = {
            floorID: '',
            floorName: 'None',
          };
          this.floorList = response.data.floors?.length
            ? [none, ...response.data.floors]
            : [];
          this.didSelectFloorModal();
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
    }
  }

  async manageBridge() {
    if (this.bridgeId && this.isReadMode) {
      this.nav.push(BridgeDetailsPage, {
        bridgeId: this.bridgeId,
        mode: 'edit',
      });
      return;
    }
    if (this.bridgeDetailsForm?.invalid) {
      return;
    }
    const payload = this.bridgeDetailsForm?.value;
    let apiRef: any;
    if (this.bridgeId) {
      payload.bridgeId = this.bridgeId;
      apiRef = this.bridgeService.updateBridge(payload);
    } else {
      apiRef = this.bridgeService.createBridge(payload);
    }
    await this.uiUtilityService.showLoader();
    apiRef.subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.uiUtilityService.showToast(
          'The bridge details saved successfully.'
        );
        if (!this.bridgeId && response.data?.bridgeId) {
          this.bridgeDetailsForm?.markAsPristine();
          this.didOnInit(response.data.bridgeId, 'detail');
          this.didViewWillEnter();
        } else {
          if (this.bridgeId && payload.placementId) {
            this.bridgeService
              .updatePlacement(this.bridgeId, payload)
              .subscribe((res: any) => {
                if (res.success) {
                  this.navigateBack();
                } else {
                  const header = 'Error';
                  this.uiUtilityService.showAlert(res.errorMessage!, header);
                }
              });
          } else {
            this.navigateBack();
          }
        }
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateBack(isRestrict = false) {
    if (!(this.bridgeId && isRestrict && !this.bridgeDetailsForm?.pristine)) {
      this.nav.pop();
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    }
  }

  onNavBack() {
    if (this.isRootPage) {
      this.dismiss();
    } else {
      this.nav.pop();
    }
  }

  onConfirmDeleteBridge = async () => {
    await this.uiUtilityService.showLoader();
    this.bridgeService
      .deleteBridge(this.bridgeDetails.bridgeInfo)
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.onNavBack();
          this.uiUtilityService.showToast('The bridge deleted successfully.');
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  };

  deleteBridge() {
    const message =
      'You are about to delete this bridge. This action cannot be undone.';
    const header = 'Delete This Bridge';
    this.uiUtilityService.presentConfirm(
      message,
      this.onConfirmDeleteBridge,
      undefined,
      header,
      'Delete'
    );
  }

  getConfig(config: UnifiedInput) {
    return { ...config };
  }

  get f() {
    return this.bridgeDetailsForm?.controls || {};
  }

  preview(isPlotMap = false) {
    const currentBridge = {
      bridgeId: this.bridgeId,
      XValue: this.f['xvalue']?.value,
      YValue: this.f['yvalue']?.value,
    };
    this.plotBridgeLocation(null, currentBridge, isPlotMap);
  }

  plotBridgeLocation(e: any, currentBridge: any, isPlotMap = false) {
    if (this.bridgeId && this.isReadMode && !isPlotMap) {
      return;
    }
    this.clearMap();
    if (e) {
      const rect = e.target.getBoundingClientRect();
      const width = e.target.clientWidth;
      const height = e.target.clientHeight;
      const x = e.clientX - rect.left; //x position within the element.
      const y = e.clientY - rect.top; //y position within the element.
      let XValue = +x.toFixed(1);
      let YValue = +y.toFixed(1);
      if (XValue < 0) {
        XValue = 0;
      }
      if (XValue > width) {
        XValue = width;
      }
      if (YValue < 0) {
        YValue = 0;
      }
      if (YValue > height) {
        YValue = height;
      }
      currentBridge = { bridgeId: this.bridgeId, XValue, YValue };
      this.bridgeDetailsForm?.controls['xvalue'].patchValue(XValue);
      this.bridgeDetailsForm?.controls['yvalue'].patchValue(YValue);
      this.bridgeDetailsForm?.markAsDirty();
      this.bridgeDetailsForm?.updateValueAndValidity();
    }

    if (currentBridge) {
      this.currentBridges.push(currentBridge);
    }

    if (this.plotElement?.nativeElement) {
      let point = document.createElement('div');
      point.classList.add('bridge-point');
      point.classList.add('active');
      const dotHalf = 20 / 2; // 20 is dot height width set in scss.
      point.style.top = currentBridge.YValue - dotHalf + 'px';
      point.style.left = currentBridge.XValue - dotHalf + 'px';
      this.plotElement.nativeElement.appendChild(point);
    }
  }

  clearMap() {
    this.currentBridges = [];
    document
      .querySelectorAll('.bridge-point.active')
      .forEach((el) => el.remove());
  }

  onPlacementDetails(placementInfo: any) {
    if (!this.isSummaryEnabled) {
      return;
    }
    const placementTypeId = placementInfo?.placeMentTypeId;
    if (placementTypeId === BridgePlacementEnum.room && placementInfo.roomId) {
      this.nav.push(RoomDetailsPage, {
        roomId: placementInfo.roomId,
        mode: 'detail',
      });
    } else if (placementTypeId === BridgePlacementEnum.bed && placementInfo.id) {
      this.nav.push(BedDetailsPage, {
        bedId: placementInfo.id,
        mode: 'detail',
      });
    }
    // else if (placementTypeId === BridgePlacementEnum.other) {
    //   this.nav.push(BedDetailsPage, { bedId: placementInfo.floorId, mode: 'detail' });
    // }
  }

  useLocToggleChanged(ev: Event) {
    const val = (ev as ToggleCustomEvent).detail.checked;
    this.bridgeDetailsForm?.controls['useForLocation'].patchValue(val);
    this.bridgeDetailsForm?.markAsDirty();
    this.bridgeDetailsForm?.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
