import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';

import { IonicModule, IonNav } from '@ionic/angular';
import { IonBackButtonOverrideDirective } from '../../directives/back-button.directive';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { AccessControlDirective } from '../../directives/access-control.directive';

@Component({
  standalone: true,
  selector: 'app-unified-header',
  templateUrl: 'unified-header.component.html',
  styleUrls: ['./unified-header.component.scss'],
  imports: [IonicModule, CommonModule, IonBackButtonOverrideDirective, AccessControlDirective],
})
export class UnifiedHeaderComponent {
  @Input() title: string = '';
  @Input() saveBtn: any = {};
  @Input() defaultHref: string = '';
  @Input() restrictBack: any = false;
  @Input() customBack: any = false;
  @Input() isRootPage: any = false;
  @Output() onSave = new EventEmitter<any>();
  @Output() onCustomBack = new EventEmitter<any>();
  @Input() classes: any = '';

  constructor(private uiUtilityService: UiUtilityService, @Optional() private nav: IonNav) {
  }

  didSave() {
    this.onSave.emit();
  }

  onCustomBackClicked() {
    const onConfirm = () => {
      if (this.isRootPage) {
        this.dismiss();
      } else {
        this.nav.pop();
      }
    }

    if (this.restrictBack) {
      const message =
        'It looks like you have been editing something. If you leave before saving, your changes will be lost.';
      const header = 'Confirm';
      this.uiUtilityService.presentConfirm(
        message,
        onConfirm,
        undefined,
        header,
        'Ok'
      );
    } else if (this.customBack) {
      this.onCustomBack.emit(event);
    } else {
      onConfirm();
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  onBackClicked(event: any) {

    const onConfirm = () => {
      event.proceed();
    }

    if (this.restrictBack) {
      const message =
        'It looks like you have been editing something. If you leave before saving, your changes will be lost.';
      const header = 'Confirm';
      this.uiUtilityService.presentConfirm(
        message,
        undefined,
        undefined,
        header,
        'Ok'
      );
    } else {
      event.proceed();
    }
  }
}