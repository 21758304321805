export class BaseWearerModel {
  id = '';
  localId? = '';
  firstName? = '';
  lastName? = '';
  displayName? = '';
  isResident = false;
  roomName?: string;
}

export class WearerDetail extends BaseWearerModel {
  bandId = '';
  facilityId = '';
  alertSpike = 0;
  wearerGroupId = '1';
  isDeleted = false;
}

export class WearerDetailsModel {
  bands: Band[] = [];
  rooms: Room[] = [];
  beds: Bed[] = [];
  residentDetail = new WearerDetail();
  wearerId: any;
  bandId: any;
  tagId?: any;
}

export class Room {
  id = '';
  roomName = '';
}

export class Bed {
  id = '';
  bedName = '';
}

export class Band {
  bandId = '';
  tagId?: any;
  alertThreshold? = 36.5;
  assignedDate? = 0;
  gracePeriod? = 10;
  location? = false;
  locationAlert? = false;
  isTag? = false;
}

export class BandStatus extends BaseWearerModel {
  corpId = '';
  facilityId = '';
  bandId = 0;
  isTag = false;
  firmwareVersion? = '';
  notFoundInGivenFacility = false;
  assetId? = '';
  wearerId? = '';
}

export interface WearerGroup {
  id: string;
  label: string;
  wearerGroupName: string;
}

export interface AssignBandToWearer {
  alertThreshold?: number;
  roomId: string;
  roomName: string;
  wearerId?: string;
  firstName?: string;
  lastName?: string;
  bandId?: string;
  assignedDate?: string;
  location?: boolean;
  locationAlert?: boolean;
  gracePeriod?: string;
  isTag?: boolean;
}
