import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { SettingsService } from '../../services/settings.service';
import { AvailableResidentsList } from '../../../../shared/models/availableResidentsList';
import { UiUtilityService } from '../../../../core/services/ui-utility.service';

@Component({
  selector: 'app-residents-list',
  templateUrl: './residents-list.component.html',
  styleUrls: ['./residents-list.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule],
})
export class ResidentsListComponent implements OnInit {
  availableResidentsList: AvailableResidentsList = new AvailableResidentsList();
  residentsData: any;
  allResidentsData: any;
  residentsID: any[] = [];
  roomID: any;
  searchQuery = '';
  residentsLength = false;
  selectResidentsControl = new FormControl('');
  constructor(
    private settingsService: SettingsService,
    private uiUtilityService: UiUtilityService,
    private ionNav: IonNav,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.roomID = this.navParams.get('roomId');
    this.getAvailableResidentsList();
  }

  handleChange(event: any) {
    this.residentsData = this.allResidentsData;
    this.searchQuery = event.target.value;
    if (this.searchQuery && this.searchQuery.trim() !== '') {
      this.residentsData = this.residentsData.filter((term: any) => {
        return (
          term.localId
            .toLowerCase()
            .indexOf(this.searchQuery.trim().toLowerCase()) > -1
        );
      });
    } else if (this.searchQuery === '') {
      this.residentsData = this.allResidentsData;
    }
  }

  isResidentsListChecked(event: any, checkedData?: any) {
    this.residentsID = [];
    if (event.detail.value) {
      this.residentsID.push(event.detail.value);
    }
  }
  async getAvailableResidentsList() {
    await this.uiUtilityService.showLoader();
    this.settingsService
      .getAvailableResidentsListByFacilityId()
      .subscribe((res) => {
        if (res.data) {
          this.availableResidentsList = res.data;
          this.residentsData = this.availableResidentsList;
          this.allResidentsData = this.residentsData;
          if (this.residentsData.length > 0) {
            this.residentsLength = true;
          }
        }
      });
  }

  async associateResidentsToRoom() {
    await this.uiUtilityService.showLoader();
    this.settingsService
      .associatesResidentsToRoom(this.residentsID, this.roomID)
      .subscribe((response) => {
        if (response.success) {
          this.ionNav.pop();
        }
      });
  }

  navigateBack() {
    this.ionNav.pop();
  }
}
