<app-unified-header [title]="!bedId ? 'Add Bed' : isReadMode ? 'Bed Details' : 'Edit Bed'" (onSave)="manageBed()"
  defaultHref="/bed-list" [restrictBack]="bedDetailsForm && !bedDetailsForm.pristine"
  [saveBtn]="{title: bedId && isReadMode ? 'Edit' : 'Save', isDisable: bedId && isReadMode ? false : isSaveDisabled, rule: bedId ? 'putBed' : ''}"
  [isRootPage]="isRootPage"></app-unified-header>

<ion-content *ngIf="bedDetailsForm">
  <form [formGroup]="bedDetailsForm">
    <ng-container *ngFor="let config of bedDetailsInputConfig">
      <unified-input ngDefaultControl [formControlName]="config.name || null" [config]="getConfig(config)"
        (changed)="inputChanged($event)"></unified-input>
    </ng-container>
    <ion-item *ngIf="f['connexioEnabled']?.value == true">
      <ion-label>SafeLight Installed</ion-label>
      <ion-toggle mode="ios" color="controls" slot="end" formControlName="ublInstalled"
        *ngIf="!bedId || (bedId && !isReadMode && isEditable)"
        (ionChange)="onLightInstalledChange($event)"></ion-toggle>
      <div slot="end" class="custom-input" *ngIf="bedId && (isReadMode || !isEditable)">
        <ion-input [value]="f['ublInstalled']?.value ? 'Yes' : 'No'" readonly></ion-input>
      </div>
    </ion-item>
    <ng-container *ngIf="bedId">
      <ion-item [detail]="!isReadMode" [ngClass]="{'interactive': !isReadMode}" (click)="setRoom()">
        <ion-label>Room</ion-label>
        <ion-text slot="end" [class.app-link]="f['roomName']?.value && isReadMode"
          [class.no-link]="!isRoomSummaryEnabled" [class.none-value]="!f['roomName']?.value">{{f['roomName']?.value ||
          'None'}}</ion-text>
      </ion-item>
      <ion-item [detail]="!isReadMode" [ngClass]="{'interactive': !isReadMode}" (click)="setResident()">
        <ion-label>Resident</ion-label>
        <ion-text slot="end" [class.app-link]="f['residentName']?.value && isReadMode"
          [class.no-link]="!isWearerSummaryEnabled"
          [class.none-value]="!f['residentName']?.value">{{f['residentName']?.value ||
          'None'}}</ion-text>
      </ion-item>
      <ion-item *ngIf="isReadMode && f['connexioEnabled']?.value" (click)="setBridge()">
        <ion-label>Bridge</ion-label>
        <ion-text slot="end" [class.app-link]="f['bridgeName']?.value && isReadMode"
          [class.no-link]="!isBridgeSummaryEnabled"
          [class.none-value]="!f['bridgeName']?.value">{{f['bridgeName']?.value ||
          'None'}}</ion-text>
      </ion-item>
      <ion-item *ngIf="!isReadMode">
        <ion-note class="app-note">To edit other fields, sign in as an admin user.</ion-note>
      </ion-item>
    </ng-container>
  </form>
  <div class="delete-bed" *ngIf="bedId && isReadMode">
    <ion-button color="danger" (click)="deleteBed()" [nAccess]="'deleteBed'">Delete This Bed</ion-button>
  </div>
</ion-content>