import {
  Component,
  DestroyRef,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { UnifiedCustomInputComponent } from 'src/app/shared/components/unified-custom-input/unified-custom-input.component';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { SettingsService } from '../../services/settings.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { Subscription } from 'rxjs';
import { PLACEMENT_BED_ICON } from 'src/app/shared/config/app.constant';

@Component({
  standalone: true,
  selector: 'app-room-placement',
  templateUrl: './room-placement.page.html',
  styleUrls: ['./room-placement.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedCustomInputComponent,
    UnifiedHeaderComponent
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomPlacementPage implements OnInit {
  @ViewChild('plotElement') plotElement: ElementRef | undefined;
  destroyRef = inject(DestroyRef);
  roomDetails: any = null;
  placementForm: FormGroup | null = null;
  isSaveDisabled = true;
  floorImage: any = '';
  currentBridges: any[] = [];
  roomId: string = '';
  subscriptions: Subscription = new Subscription();
  title = 'Place on Floor Plan';
  isBed = false;

  constructor(
    private formBuilder: FormBuilder,
    private uiUtilityService: UiUtilityService,
    private navParams: NavParams,
    private settingsService: SettingsService,
    private nav: IonNav
  ) { }

  ngOnInit(): void {
    this.roomId = this.navParams.get('roomId');
    this.title = this.navParams.get('title');
    this.isBed = this.navParams.get('placementType') === PLACEMENT_BED_ICON;
    // this.facilityName = this.headerService.getFacilityName();
  }

  didViewWillEnter() {
    this.isSaveDisabled = true;
    this.getRoomDetails();
  }

  setRoomDetails(data: any) {
    this.roomDetails = data;
    this.createForm(data);
    this.subscribeForm();
    this.isSaveDisabled = false;
    if (data.floorId) {
      this.getFloorDetails(data.floorId);
    }
  }

  async getRoomDetails() {
    await this.uiUtilityService.showLoader();
    this.settingsService.getRoomDetails(this.roomId).subscribe((res) => {
      if (res && res.success && res.data?.roomDetail) {
        this.roomDetails = res.data.roomDetail;
        this.setRoomDetails(res.data.roomDetail);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(res.errorMessage!, header);
      }
    });
  }

  async getFloorDetails(floorId: string) {
    await this.uiUtilityService.showLoader();
    this.settingsService.getFloorDetails(floorId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.floorImage = response.data?.floorImage || '';
        this.placementForm?.updateValueAndValidity();
        if (this.f['xValue']?.value !== null) {
          setTimeout(() => {
            this.preview();
          }, 500);
        }
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  getXandY(point: any, isInverse = false) {
    const img = document.getElementById('floor-img-room');
    let x = point.x;
    let y = point.y;
    if (img?.clientWidth) {
      if (img?.clientWidth > 300 && !isNaN(point.x)) {
        x = (isInverse ? (300 / img.clientWidth) : (img.clientWidth / 300)) * point.x;
        y = (isInverse ? (300 / img.clientHeight) : (img.clientHeight / 300)) * point.y;
      }
    }
    return { x: +x.toFixed(1), y: +y.toFixed(1) };
  }

  createForm(roomDetails: any) {
    this.placementForm = this.formBuilder.group({
      xValue: [this.isBed ? roomDetails?.xyCordinate?.xValue : roomDetails?.xValue || null, [Validators.required]],
      yValue: [this.isBed ? roomDetails?.xyCordinate?.yValue : roomDetails?.yValue || null, [Validators.required]],
      floorName: [roomDetails?.floorName || null]
    });
  }

  subscribeForm() {
    this.placementForm?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.placementForm) {
          this.isSaveDisabled =
            this.placementForm.invalid || !this.placementForm.dirty;
        }
      });
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  get f() { return this.placementForm?.controls || {}; }

  preview() {
    let x = this.f['xValue']?.value;
    let y = this.f['yValue']?.value;
    if (!isNaN(x)) {
      const point = this.getXandY({ x, y });
      x = +point.x.toFixed(1);
      y = +point.y.toFixed(1);
      // this.placementForm?.controls['xValue'].patchValue(x);
      // this.placementForm?.controls['yValue'].patchValue(y);
    }
    const currentBridge = { roomId: this.roomId, XValue: !isNaN(x) ? x : 0, YValue: !isNaN(y) ? y : 0 };
    this.plotBridgeLocation(null, currentBridge);
  }

  plotBridgeLocation(e: any, currentBridge: any) {
    this.clearMap();
    if (e) {
      const rect = e.target.getBoundingClientRect();
      const width = e.target.clientWidth;
      const height = e.target.clientHeight;
      const x = e.clientX - rect.left; //x position within the element.
      const y = e.clientY - rect.top;  //y position within the element.
      let XValue = +x.toFixed(1);
      let YValue = +y.toFixed(1);
      if (XValue < 0) {
        XValue = 0;
      }
      if (XValue > width) {
        XValue = width;
      }
      if (YValue < 0) {
        YValue = 0;
      }
      if (YValue > height) {
        YValue = height;
      }
      const point = this.getXandY({ x: XValue, y: YValue }, true);
      currentBridge = { roomId: this.roomId, XValue, YValue };
      this.placementForm?.controls['xValue'].patchValue(point.x);
      this.placementForm?.controls['yValue'].patchValue(point.y);
      this.placementForm?.markAsDirty();
      this.placementForm?.updateValueAndValidity();
    }

    if (currentBridge) {
      this.currentBridges.push(currentBridge);
    }

    if (this.plotElement?.nativeElement) {
      let point = document.createElement('div');
      point.classList.add('bridge-point');
      point.classList.add('active');
      if (this.isBed) {
        point.classList.add('bed');
      }
      const dotHalf = this.isBed ? (14 / 2) : (20 / 2);// 20 is dot height width set in scss.
      point.style.top = (currentBridge.YValue - dotHalf) + "px";
      point.style.left = (currentBridge.XValue - dotHalf) + "px";
      this.plotElement.nativeElement.appendChild(point);
    }
  }

  clearMap() {
    this.currentBridges = [];
    document.querySelectorAll(".bridge-point.active").forEach(el => el.remove());
  }


  navigateBack() {
    this.nav.pop();
  }

  managePlacement() {
    const payload = this.placementForm?.value;
    let x = payload.xValue;
    let y = payload.yValue;
    // const point = this.getXandY({ x, y }, true);
    if (this.isBed) {
      payload.xValue = this.roomDetails?.xValue;
      payload.yValue = this.roomDetails?.yValue
      payload.bed = {
        xValue: x,
        yValue: y
      };
    } else {
      payload.xValue = x;
      payload.yValue = y;
      payload.bed = this.roomDetails?.xyCordinate;
    }
    delete payload.floorName;
    this.settingsService.updateRoomPlacement(this.roomId, payload).subscribe((res: any) => {
      if (res.success) {
        this.navigateBack();
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(res.errorMessage!, header);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
