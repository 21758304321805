import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/core/services/header.service';
import { IonNav, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { SETTINGS_ITEMS } from '../../config/settings-route.config';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';

@Component({
  standalone: true,
  selector: 'app-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  imports: [IonicModule, CommonModule]
})
export class BaseModalComponent implements OnInit {
  @ViewChild(IonNav) ionNav: IonNav | undefined;
  rootPage: any;
  rootParam: any;
  @Input() data?: any;

  constructor(private header: HeaderService, private uiUtilityService: UiUtilityService) { }

  ngOnInit() {
    this.rootPage = this.data?.rootPage;
    this.rootParam = this.data?.rootParam;
    this.header.pageChange.subscribe((data) => {
      this.rootPage = data.rootPage;
      this.rootParam = data.rootParam;
    });
  }

  async onIonNavDidChange(ev: any) {
    const activeView = await this.ionNav?.getActive();
    let menuId: any;
    if (activeView?.element?.localName) {
      for (const item of SETTINGS_ITEMS) {
        if (item.components.indexOf(activeView.element.localName) !== -1) {
          menuId = item.menuId
          break;
        }
      }
    }
    if (menuId) {
      this.uiUtilityService.menuId.next(menuId);
    }
  }
}
