export enum notableStateMessage {
    warning = 'The latest temperature reading met the alert criteria for this wearer.',
    caution = 'The latest temperature reading is getting close to the alert criteria for this wearer.',
    lowBattery = 'The Band’s battery is low. Please associate a new Band as soon as possible.',
    offline = 'The Band could not communicate to any Bridges for 1 hour or more. The wearer might be away from the area or the Band has been misplaced.',
    assetOffline = 'The Tag could not communicate to any Bridges for 1 hour or more. The asset might be away from the area or the Tag has been misplaced.',
    unworn = 'The Band does not appear to be on the wearer or the Band is fitted too loose on the wrist.',
    newWearer = 'This wearer is new to the system and does not have sufficient trend history. This is not an alarming state but the user should be aware the deviation based alert will not work (or will not work well)',
    indeterminate = 'The wearer is in an environment where the Band is unable to reliably determine the temperature.',
    noStatus = 'The wearer or asset is not associated with a Band or Tag, and therefore has no status available.'
}

export enum notableEventsStringParts {
    locationLastSeenAt = 'Location Alert: Wearer was last seen ',
    locationLastSeenAtAsset = 'Location Alert: Asset was last seen ',
    near = 'near ',
    at = 'at',
    tempAt = 'Persistent high temperature observed '
}
