<app-unified-header [title]="title"
  (onSave)="floorId && !editEnabled ? editFloor() : manageFloors(floorId ? 'put': 'post')"
  defaultHref="/facility-management" [customBack]="this.editEnabled || !this.floorId" (onCustomBack)="backClick()"
  [saveBtn]="{title: this.floorId && !this.editEnabled ? 'Edit' : 'Save', isDisable: !(this.floorId && !this.editEnabled) && this.floorsForm.pristine, rule: floorId ? 'put'+EndPoints.managefloors : ''}"></app-unified-header>
<ion-content>

  <ion-content>
    <form [formGroup]="floorsForm">
      <div class="floor-item floor-name" (click)="addFloorName()"
        [ngClass]="(!editEnabled && this.floorId)? 'no-click' : 'yes-click' ">
        <ion-item lines="full" [detail]="!(this.floorId && !this.editEnabled)" class="first-item-border">
          <ion-label class="title-l">Floor Name</ion-label>
          <div slot="end" class="custom-input">
            <ion-input formControlName="floorName" readonly></ion-input>
          </div>
        </ion-item>
      </div>
      <div *ngIf="floorsForm?.get('floorName')?.value">
        <div class="floor-item band">
          <ion-item lines="full">
          </ion-item>
        </div>
        <div class="floor-item plan-image" (click)="actions()"
          [ngClass]="(!editEnabled && this.floorId)? 'no-click' : 'yes-click' ">
          <ion-item lines="full" [detail]="!(this.floorId && !this.editEnabled)">
            <ion-label class="title-l">{{ floorsForm?.get('floorImage')?.value ? 'Change' : 'Add' }} Floor Plan
              Image</ion-label>
            <div slot="end" class="custom-input" style="display: none;">
              <ion-input formControlName="floorImage" readonly></ion-input>
            </div>
          </ion-item>
        </div>
        <p *ngIf="!floorsForm?.get('floorImage')?.value" class="notification-info ">Optional. The map may be a floor
          plan or a vague outline. Once added, you will be
          able to mark the location of any bridges through the Bridge Details screen.</p>
        <div class="plan-image-container" *ngIf="floorsForm?.get('floorImage')?.value">
          <img [src]="floorsForm?.get('floorImage')?.value" [alt]="'Plan Image'" />
        </div>
      </div>
    </form>
    <div class="delete-floor ion-text-center" *ngIf="this.floorId && !editEnabled">
      <ion-button (click)="deleteFloor()" color="danger" [nAccess]="'delete'+EndPoints.managefloors">Delete This
        Floor</ion-button>
    </div>
  </ion-content>