import * as barColor from 'src/app/shared/config/bar-color.constant';
export class FloorMap {
  floor: number | string = 0;
  wearerId: number = 499;
  wearerState: number | string = 1;
  location: FLocation = new FLocation();
}

export class FLocation {
  XValue: number = 144;
  YValue: number = 152;
}
