export enum BridgeStatusEnum {
    offline = 'Offline',
    online = 'Online',
    initialising = 'Initializing...'
}

export enum BridgePlacementEnum {
    room = '1',
    bed = '2',
    other = '3'
}