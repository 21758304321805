import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { DashboardData } from 'src/app/shared/models/dashboard.model';
import { SortingTypeModel } from 'src/app/shared/models/sorting-type.model';
import { WearerGroupModel } from 'src/app/shared/models/wearer-group.model';
import { ApiResponseModel } from './api/api-response.model';
import { FILTER_SORT_TYPE } from 'src/app/shared/config/app.constant';
import { cloneDeep } from 'lodash-es';
import { UiUtilityService } from './ui-utility.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private readonly sortingTypeSubject = new Subject<SortingTypeModel>();
  private readonly filterWearerSubject = new Subject<WearerGroupModel[]>();

  constructor(private uiUtilityService: UiUtilityService) { }

  setSortingType(sortingType: SortingTypeModel): void {
    this.sortingTypeSubject.next(sortingType);
  }

  getSortingType(): Observable<SortingTypeModel> {
    return this.sortingTypeSubject.asObservable();
  }

  setFilteredWearerGroup(filteredGroup: WearerGroupModel[]): void {
    this.filterWearerSubject.next(filteredGroup);
  }

  getFilteredWearerGroup(): Observable<WearerGroupModel[]> {
    return this.filterWearerSubject.asObservable();
  }

  checkForNotableWearer(
    dashboardData: DashboardData[],
    wearerGroup: WearerGroupModel[]
  ) {
    wearerGroup.forEach((wGroup) => {
      wGroup.hasNotableWearer = false;
    });
    const healthyWearerId = 7;
    dashboardData.forEach((wearer: DashboardData) => {
      wearerGroup.forEach((group) => {
        if (
          +wearer.wearerGroup === group.value &&
          (+wearer.wearerState !== healthyWearerId ||
            +wearer.bandState !== healthyWearerId)
        ) {
          group.hasNotableWearer = true;
        }
      });
    });
  }

  getFilterData(groups: any[] = []) {
    const filters: any[] = [];
    for (const group of groups) {
      if (group.fields?.length) {
        const field: any = {};
        field.type = group.type;
        let selected = {};
        if (Array.isArray(group.value)) {
          selected = { value: group.value, property: group.fields[0]?.property };
        } else {
          selected = group.fields.find((item: any) => item.value === group.value);
        }
        filters.push({ ...field, ...selected, filterApplied: group.filterApplied });
      }
    }
    return filters;
  }

  setSortFilter(data: any) {
    const result: any = { sortingType: new SortingTypeModel() };
    for (const selected of data) {
      if (selected.type === FILTER_SORT_TYPE) {
        result.sortingType = {
          value: selected.property, order: selected.order,
          name: ''
        };
        this.setSortingType(result.sortingType);
      } else {
        result[selected.property] = selected.value;
      }
    }
    return result;
  }

  filteredData(data: any, filters: any) {
    let result = cloneDeep(data);
    const { sortingType, ...otherFilters } = filters;
    for (const [key, value] of Object.entries(otherFilters)) {
      result = result.filter((item: any) => (value as any).indexOf(item[key]) !== -1);
    }
    if (sortingType) {
      const sortingKey = sortingType.value;
      const notNullList = result.filter((item: any) => (item[sortingKey] && item.sortingKey !== null));
      const nullList = result.filter((item: any) => !item[sortingKey] || item.sortingKey === null);
      const sortedNullResult = this.uiUtilityService.sortList(nullList, sortingType.fallbackKey, sortingType.order);
      const sortedResult = this.uiUtilityService.sortList(notNullList, sortingKey, sortingType.order);
      result = [...sortedResult, ...sortedNullResult];
    }
    return result;
  }

  // // Fetch and map filter groups
  // getFilterGroups(facilityId: number): Observable<WearerGroupModel[]> {
  //   return this.dbService.getWearerGroups(facilityId).pipe(
  //     map((response: ApiResponseModel<any>) => {
  //       if(response?.success) {
  //         const wearerGroups = [];
  //         response.data.forEach((group) => {
  //           const wsGroup = { ...new WearerGroupModel(), ...group };
  //           wsGroup.name = group.value;
  //           wsGroup.value = +group.key;
  //           wearerGroups.push(wsGroup);
  //         });
  //         return wearerGroups;
  //       }
  //       return null;
  //     }));
  // }
}
