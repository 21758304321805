import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IonNav, IonicModule, NavController, NavParams } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { SettingsService } from 'src/app/features/settings/services/settings.service';
import { WearerService } from 'src/app/features/wearer-management/services/wearer.service';
import { WearerDetailsModel } from 'src/app/shared/models/wearer.model';

export class BedListModel {
  id = '';
  bedName = '';
  startDate = '';
  endDate = '';
  createdBy = '';
  createDate = '';
  updateDate = '';
  updatedBy = '';
  facilityId = '';
  bedId = '';
  connexioEnabled = '';
}

export interface RouteState {
  roomId: string;
  wearerId: string;
}

@Component({
  selector: 'app-select-bed',
  templateUrl: './select-bed.component.html',
  styleUrls: ['./select-bed.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class SelectBedComponent implements OnInit {
  @Input('roomId') roomId: string = '';
  @Input('wearerId') wearerId: string = '';
  wearerDetails: WearerDetailsModel | null = null;

  isSaveEnabled = false;
  bedList: BedListModel[] = [];
  searchBedListData: BedListModel[] = [];
  state: RouteState = { roomId: '', wearerId: '' };
  selectBedControl = new FormControl('');
  queryParams: RouteState = { roomId: '', wearerId: '' };
  constructor(
    private settingService: SettingsService,
    private router: Router,
    private uiUtilityService: UiUtilityService,
    private navController: NavController,
    public activatedRoute: ActivatedRoute,
    private wearerService: WearerService,
    private ionNav: IonNav,
    private navParams: NavParams
  ) {
    //   this.state = this.router.getCurrentNavigation()?.extras.state as RouteState;
    //   this.activatedRoute.queryParams.subscribe((res)=>{
    //     this.queryParams.roomId = res['id'];
    //     // this.queryParams = res as RouteState;
    // });
  }

  ngOnInit() {
    this.queryParams = this.navParams.data as RouteState;
    this.selectBedControl.patchValue(this.roomId || '');
    this.uiUtilityService.showLoader();
    const noneBed = new BedListModel();
    noneBed.bedName = 'None';
    noneBed.id = '';
    this.settingService.getBedList(this.roomId).subscribe((response) => {
      if (response.success && response.data) {
        this.searchBedListData = response.data;
        this.searchBedListData.unshift(noneBed);
        this.bedList = [...this.searchBedListData];
      }
    });
    this.getWearerDetails();
  }

  getWearerDetails() {
    this.wearerService.getWearerDetails(this.wearerId).subscribe((res) => {
      if (res.success) {
        if (res.data) this.wearerDetails = res.data;
      }
    });
  }

  handleChange(event: any) {
    const searchQuery = event.target.value;
    if (searchQuery && searchQuery.trim() !== '') {
      this.bedList = this.searchBedListData.filter((term: any) => {
        return (
          term.bedName.toLowerCase().indexOf(
            searchQuery.trim().toLowerCase()
          ) > -1
        );
      });
    } else if (searchQuery === '') {
      this.bedList = this.searchBedListData;
    }
  }

  async associateBedToRoom() {
    if (this.selectBedControl.value) {
      await this.uiUtilityService.showLoader();
      if (this.wearerDetails?.beds[0]?.id) {
        this.settingService
          .removeBedFromResident(this.wearerDetails?.beds[0].id)
          .subscribe((response: any) => {
            if (response.success) {
              console.log('success');
            }
          });
      }
      this.settingService
        .postBedToResident(this.selectBedControl.value, this.wearerId)
        .subscribe((response: any) => {
          if (response.success) {
            this.uiUtilityService.showToast('Bed associated successfully');
            this.ionNav.pop();
          }
        });
    } else {
      await this.uiUtilityService.showLoader();
      this.settingService
        .removeBedFromResident(this.wearerDetails?.beds[0]?.id || null)
        .subscribe((response: any) => {
          if (response.success) {
            this.uiUtilityService.showToast('Bed un-associated successfully');
            this.ionNav.pop();
          }
        });
    }
  }

  goBack() {
    this.ionNav.pop();
  }
}
