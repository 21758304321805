import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { DataService } from "src/app/core/services/api/data.service";
import { UiUtilityService } from "src/app/core/services/ui-utility.service";
import { UnifiedHeaderComponent } from "src/app/shared/components/unified-header/unified-header.component";

@Component({
  standalone: true,
  selector: 'app-downloads',
  templateUrl: './downloads.page.html',
  styleUrls: ['./downloads.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UnifiedHeaderComponent
  ],
})
export class DownloadsPage {
  pdfContents = [{
    title: 'Connexio Reference Guide',
    url: 'help/v1/downloadRefGuide'
  }, {
    title: 'Connexio Product User Manual',
    url: 'help/v1/downloadUserManual'
  }];
  
  constructor(private dataService: DataService<any>, private uiUtilityService: UiUtilityService) {

  }

  downloadPDF(pdf: any) {
    this.dataService.getBlob(pdf.url).subscribe((response: any) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${pdf.title}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      this.uiUtilityService.showToast('Download successful');
    }, error => {
      console.error('Download error:', error);
    });
  }
}