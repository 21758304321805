import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/api/data.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { FACILITY_ID_URL, RECOVER_PASSWORD } from 'src/app/shared/config/app.constant';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private authService: AuthService, private dataService: DataService<any>, private headerService: HeaderService) { }

  getUserList() {
    const { corpId, userRole } = this.authService.getLoginData;
    const url = `${environment.apiUrl.corporate}${FACILITY_ID_URL}/${EndPointsEnum.getUserList}?roleId=${userRole}&corpId=${corpId}`;
    return this.dataService.getData(url);
  }

  getUserDetails(id: string) {
    const url = `${environment.apiUrl.user}${id}`;
    return this.dataService.getData(url);
  }

  getUserRoles() {
    const url = `${environment.apiUrl.roles}${EndPointsEnum.rolesList}`;
    return this.dataService.getData(url);
  }

  createUser(payload: any): Observable<any> {
    const url = `${environment.apiUrl.corporate}${EndPointsEnum.manageUser}`;
    return this.dataService.post(url, payload);
  }

  updateUser(payload: any): Observable<any> {
    const url = `${environment.apiUrl.corporate}${EndPointsEnum.manageUser}`;
    return this.dataService.put(url, payload);
  }

  deleteUser(payload: any): Observable<any> {
    const url = `${environment.apiUrl.corporate}${EndPointsEnum.manageUser}`;
    return this.dataService.delete(url, payload);
  }

  changePassword(payload: any): Observable<any> {
    const url = `${environment.apiUrl.user}${EndPointsEnum.changePassword}`;
    return this.dataService.post(url, payload);
  }

  resetPassword(payload: any): Observable<any> {
    const url = `${environment.apiUrl.user}${EndPointsEnum.recoverPassword}`;
    return this.dataService.post(url, payload);
  }

  recoverCredentials(payload: any): Observable<any> {
    const url = `${environment.apiUrl.user}${payload.recoveryType === RECOVER_PASSWORD ? EndPointsEnum.recoverPassword : EndPointsEnum.recoverUserId}`;
    return this.dataService.post(url, payload);
  }
}
