export class AddRoomDetailsModel {
    room = '';
    floor = ''
    type = ''
}

export class RoomTypeName {
    roomTypeName = '';
}

export class AddFloorDetailsModel {
    "floorID" : '';
    "floorName" : '';
}

export class AddRoomDetails {
    roomName = "";
    roomTypeName = "";
    subTypeName = "";
    facilityID = "";
    "id" : string | null;
}