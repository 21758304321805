export enum LocationStateText {
    warning = 'LOCATION WARNING',
    caution = 'LOCATION CAUTION'
}

export enum LocationStateDescription {
    warning = 'Wearer’s location is currently unknown. Tap on the card to see where and when the wearer was last seen.',
    caution = 'Wearer cannot be found, but one or more bridges are offline. Tap on the card to see where and when the wearer was last seen. Make sure all Bridges are plugged into wall power. If the problem persists, contact your administrator or Connexio support.',
    assetWarning = 'Asset location is currently unknown. Tap on the card to see where and when the asset was last seen.',
    assetCaution = 'Asset cannot be found, but one or more bridges are offline. Tap on the card to see where and when the asset was last seen. Make sure all Bridges are plugged into wall power. If the problem persists, contact your administrator or Connexio support.'
}
// need to be updated
export enum LocationFieldInfo {
    location = 'Optional. Turn on to see the approximate location of this wearer/asset.',
    locationAlert = 'Optional. Turn on to see a notable event when this wearer/asset has moved out of range.',
}
