import { Component } from '@angular/core';
import { AlertController, IonicModule, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SideMenuComponent } from './shared/components/side-menu/side-menu.component';
import { Network } from '@capacitor/network';
import { NetworkService } from 'src/app/core/errors/network.service';
import { errorMessage } from 'src/app/core/errors/error-message';
import { UiUtilityService } from './core/services/ui-utility.service';
import { CapacitorJailbreakRootDetection } from '@swernimont/capacitor-jailbreak-root-detection';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, SideMenuComponent],
})
export class AppComponent {
  constructor(private translate: TranslateService, private platform: Platform, private networkService: NetworkService, private uiUtilityService: UiUtilityService, private alertController: AlertController) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    const currentPlatforms = this.platform.platforms();
    const headerHeight = currentPlatforms.includes('ios') ? '44px' : '56px';
    document.documentElement.setAttribute(
      'style',
      `--ion-header-height: ${headerHeight}`
    );
    this.platform.ready().then(() => {
      this.setupSubscriptions();
      this.logCurrentNetworkStatus();
      this.checkForRootedDevice();
    });
    this.alertController.create({ animated: false }).then((t: any) => { t.present(); t.dismiss(); });
  }

  async checkForRootedDevice() {
    const isRooted = await CapacitorJailbreakRootDetection.isJailbrokenOrRooted();
    if (isRooted?.result) {
      CapacitorJailbreakRootDetection.exitApp();
    }
  }

  setupSubscriptions() {
    this.networkService.getStatusChangeMsg().subscribe((res) => {
      if (res?.connected) {
        this.uiUtilityService.dismissAlert();
      } else if (res && !res.connected) {
        this.uiUtilityService.showAlert(errorMessage.noNetwork, 'Alert');
      }
    });
  }

  logCurrentNetworkStatus = async () => {
    const status = await Network.getStatus();
    if (!status.connected) {
      this.uiUtilityService.showAlert(errorMessage.noNetwork, 'Alert');
    }
  };
}
