import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  selector: 'app-unified-select',
  templateUrl: 'unified-select.component.html',
  styleUrls: ['./unified-select.component.scss'],
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule],
})
export class UnifiedSelectComponent {
  @Input() data: any = {};
  @Input() isSave = false;
  items: any[] = [];
  selectControl = new FormControl('');
  isChanged = false;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.selectControl.patchValue(this.data.value);
    this.items = this.data.list;
  }

  onSave() {
    return this.modalCtrl.dismiss(this.selectControl.value, 'confirm');
  }

  handleChange(event: any) {
    const keyword = event.target.value?.toLowerCase()?.trim();
    if (keyword && keyword !== '') {
      this.items = this.data.list.filter((item: any) => item[this.data.displayName]?.toLowerCase()?.includes(keyword));
    } else {
      this.items = this.data.list;
    }
  }

  onSelect(event: any) {
    if (this.isSave) {
      this.isChanged = true;
      this.selectControl.patchValue(event.target.value);
    } else {
      return this.modalCtrl.dismiss(event.target.value, 'confirm');
    }
    return;
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}