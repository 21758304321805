export enum EndPointsEnum {
  getLoginResponse = 'getLoginResponse',
  recoverPassword = 'resetPsswd',
  recoverUserId = 'recoverUserId',
  getCorpAccount = 'getcorporateaccount',
  editCorpAccount = 'editcorporateaccount',
  addCorpAccount = 'createCorporateAccount',
  changePassword = 'changePassword',
  logout = 'logout',
  accessToken = 'accessToken',
  login = 'login',
  dashboard = 'getDashboard',
  getWearerNotableEvents = 'getWearerNotableEvents',
  getAssetNotableEvents = 'getAssetNotableEvents',
  getPdfReport = 'getPdfReport',
  getCsvReport = 'getCsvReport',
  getReport = 'getReport',
  getNotableEvents = 'getNotableEvents',
  getReportPeriodOptions = 'getReportTypes',
  getWearerGroupList = 'getWearerGroupList',
  generatePdfReport = 'generatePdfReport',
  listCorporateAccounts = 'getCorporateAccountsList',
  switchCorporate = 'switchCorporate',
  loginResponse = 'getLoginResponse',
  getFacilityFloorsList = 'getFacilityFloorsList',
  anavahDashboard = 'getAnavahDashboardResponse',
  getUserList = 'getUserList',
  manageUser = 'manageuser',
  facilityList = 'getFacilityList',
  manageFacility = 'managefacility',
  manageFloors = 'manageFloors',
  userDeviceRegistration = 'userDeviceRegistration',
  userDeviceDeRegistration = 'userDeviceDeRegistration',
  monthlyDetection = 'monthlyDetection',
  ps30DaysDetection = 'getNotableEvent',
  monthlyUsage = 'monthlyUsage',
  wsNotableEvents = 'getPatientNotableEvents',
  ps30DaysLocationEvents = 'getLocationNotableEvent',
  unassignFloor = 'unassignFloor',
  validateFloorName = 'validateFloorName',
  getUserRoles = 'list',
  wearerList = 'getwearerlist',
  detailedWearerList = 'getDetailedWearerList',
  getWearerDetails = 'getwearerDetails',
  manage = 'manage',
  getBridgeList = 'getBridgeList',
  bandsAvailability = 'bandsavailability',
  bandLookUp = 'bandLookup',
  bridgeAvailability = 'bridgeavailability',
  ps30DaysUsage = 'getWearerUsage',
  getWearerLocation = 'getWearerLocation',
  getBridgeFloorsList = 'getFloorsList',
  manageFloorBridges = 'manageFloorBridges',
  simulate = 'sendBandToUnifiedQA',
  bridgeKeepAlive = 'sendOndoBridgeKeepAliveQA',
  simulateDusun = 'sendBandToUnifiedDusunQA',
  bridgeKeepAliveDusun = 'sendOndoBridgeKeepAliveDusunQA',
}
