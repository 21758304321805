<app-unified-header [title]="isAddFacility ? 'Add Facility' : editEnable ? 'Edit Facility Details' : 'Facility Details'"
  (onSave)="!editEnable && !isAddFacility ? enableEditMode() : manageFacility(isAddFacility ? 'post': 'put')"
  defaultHref="/facility-management" [customBack]="this.editEnable || this.isAddFacility" (onCustomBack)="backClick()"
  [saveBtn]="{title: !editEnable && !isAddFacility ? 'Edit' : 'Save', isDisable: !editEnable && !isAddFacility ? false : isSaveDisabled, rule: !isAddFacility ? 'put'+EndPoints.manageFacility : ''}"
  [isRootPage]="isRootPage && !editEnable"></app-unified-header>

<ion-content>
  <div *ngIf="facilityDetailsForm">
    <form [formGroup]="facilityDetailsForm">

      <!-- read mode -->
      <div class="coporate-details-edit" *ngIf="editEnable || isAddFacility">
        <ion-item button (click)="presentAlert(1,'Name', 'Name', facilityDetailsForm?.get('facilityName')?.value)">
          <ion-label>Facility Name</ion-label>{{facilityDetailsForm?.get('facilityName')?.value}}
        </ion-item>
        <ion-item button (click)="presentAlert(2,'Phone', 'Phone', facilityDetailsForm?.get('facilityPhone')?.value)">
          <ion-label>Facility Phone</ion-label>{{facilityDetailsForm?.get('facilityPhone')?.value}}
        </ion-item>
        <ion-item button (click)="presentAlert(3,'Email', 'Email', facilityDetailsForm?.get('facilityEmail')?.value)">
          <ion-label>Facility Email</ion-label>{{facilityDetailsForm?.get('facilityEmail')?.value}}
        </ion-item>
        <ion-item button
          (click)="presentAlert(4,'Representative Name', 'Name', facilityDetailsForm?.get('facilityRepresentativeName')?.value)">
          <ion-label>Facility Representative
            Name</ion-label>{{facilityDetailsForm?.get('facilityRepresentativeName')?.value}}
        </ion-item>
        <ion-item button
          (click)="presentAlert(5,'Representative Title', 'Representative Title', facilityDetailsForm?.get('facilityRepresentativeTitle')?.value)">
          <ion-label>Facility Representative Title
          </ion-label>{{facilityDetailsForm?.get('facilityRepresentativeTitle')?.value}}
        </ion-item>
        <ion-item>
          <ion-label slot="start">Facility Time Zone</ion-label>
          <ion-select slot="end" style="border: none;" interface="popover"
            [interfaceOptions]="{cssClass: 'timezone-dropdown'}" placeholder="Select" formControlName="timeZone">
            <ion-select-option *ngFor="let timeZone of timeZones" [value]="timeZone">
              {{timeZone}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>

      <!-- edit mode -->
      <div class="coporate-details-edit" *ngIf="!editEnable && !isAddFacility">
        <ion-item>
          <ion-label>Facility Name</ion-label>{{facilityDetailsForm?.get('facilityName')?.value}}
        </ion-item>
        <ion-item>
          <ion-label>Facility Phone</ion-label>{{facilityDetailsForm?.get('facilityPhone')?.value}}
        </ion-item>
        <ion-item>
          <ion-label>Facility Email</ion-label>{{facilityDetailsForm?.get('facilityEmail')?.value}}
        </ion-item>
        <ion-item>
          <ion-label>Facility Representative
            Name</ion-label>{{facilityDetailsForm?.get('facilityRepresentativeName')?.value}}
        </ion-item>
        <ion-item>
          <ion-label>Facility Representative Title
          </ion-label>{{facilityDetailsForm?.get('facilityRepresentativeTitle')?.value}}
        </ion-item>
        <ion-item class="disable-click">
          <ion-label slot="start">Facility Time Zone</ion-label>
          <ion-select slot="end" style="border: none;" interface="popover"
            [interfaceOptions]="{cssClass: 'timezone-dropdown'}" placeholder="Select" formControlName="timeZone">
            <ion-select-option *ngFor="let timeZone of timeZones" [value]="timeZone">
              {{timeZone}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </div>

      <div class="wearer-item" *ngIf="!isAddFacility && !editEnable" [nAccess]="EndPoints.getFacilityFloorsList">
        <div>
          <ion-item detail class="first-item-border" (click)="viewFloorList()">
            <ion-label>Facility Floors</ion-label>
          </ion-item>
          <ion-row>
            <p class="notification-info corporatepanel">Optional. Add facility floors and floor plans here to use with
              features such as Location.</p>
          </ion-row>
        </div>
      </div>

      <!-- <div class="delete-wearer" *ngIf="!isAddFacility && !editEnable">
        <ion-button (click)="deleteFacility()" color="danger" [disabled]="true">Delete This Facility</ion-button>
      </div> -->
    </form>
  </div>
</ion-content>