<ion-header>
  <ion-toolbar mode="ios" [ngClass]="classes">
    <ion-buttons class="custom-btns">
      <!-- <ion-back-button [defaultHref]="defaultHref" (backClicked)="onBackClicked($event)"></ion-back-button> -->
      <ion-button *ngIf="isRootPage" (click)="onCustomBackClicked()" class="custom-close-btn">Close</ion-button>
      <ion-button *ngIf="!isRootPage" (click)="onCustomBackClicked()" class="custom-back-btn">
        <ion-icon slot="start" name="chevron-back-outline" class="custom-back-icon"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>
    <ion-title mode="ios">{{ title }}</ion-title>
    <ion-buttons slot="end" *ngIf="saveBtn">
      <ion-button [disabled]="saveBtn.isDisable" (click)="didSave()" [nAccess]="saveBtn.rule"> {{saveBtn.title}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>