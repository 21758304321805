export enum UserRolesEnum {
    Corporate_Admin = 'Corporate Admin',
    Facility_Admin = 'Facility Admin',
    Facility_Staff = 'Facility Staff'
}

export const userRoles: { [key: number]: string } = {
    1: UserRolesEnum.Corporate_Admin,
    2: UserRolesEnum.Facility_Admin,
    3: UserRolesEnum.Facility_Staff
};

export const facilityRoles = ["2", "3"];