<app-unified-header [title]="title" (onSave)="managePlacement()" defaultHref="/room-list"
  [restrictBack]="placementForm && !placementForm.pristine"
  [saveBtn]="{title: 'Save', isDisable: isSaveDisabled}"></app-unified-header>


<ion-content *ngIf="placementForm">
  <form [formGroup]="placementForm">
    <ng-container *ngIf="floorImage">
      <br>
      <div class="coordinates">
        <ion-label>Coordinates</ion-label>
        <div class="coordinate-actions">
          <ion-input fill="outline" placeholder="X Value" formControlName="xValue" type="number" min="0"></ion-input>
          <ion-input fill="outline" placeholder="Y Value" formControlName="yValue" type="number" min="0"></ion-input>
          <ion-button (click)="preview()">Preview</ion-button>
        </div>
      </div>
      <div class="floor-image">
        <ion-label class="placement-info">Or tap on the map below to place the {{isBed ? 'bed' :
          'bridge'}}<ion-icon
            [src]="isBed ? 'assets/icon/bed.svg' : 'assets/icon/icons_placement.svg'"></ion-icon></ion-label>
        <div class="floor-image-container">
          <div id="plot" class="place-room-container" #plotElement>
            <img [src]="floorImage" id="floor-img-room" (click)="plotBridgeLocation($event, null)"
              [alt]="'Plan Image'" />
          </div>
        </div>
        <div class="ion-text-center floor-name">
          {{f['floorName']?.value}}
        </div>
      </div>
    </ng-container>
    <div class="no-floor" *ngIf="!floorImage">
      This room is not assigned to any floor yet.
    </div>
  </form>
</ion-content>