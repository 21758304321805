<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">About Joerns Healthcare</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="section">
    <ion-label>
      <h2>The Power to Simplify Care Anywhere</h2>
      <p>A vertically integrated manufacturer since 1889, Joerns tenure and experience are unmatched in the delivery of
        long-term care equipment sales and support services. It is our mission to make affordable, accessible solutions
        available to restorative care providers and their patients that are effective, reliable, and safe. Visit
        <a href="https://www.joerns.com" class="app-link">https://joerns.com</a> for more information.
      </p>
      <p class="m-t-10">Contact Us: <a href="tel:1-800-528-3051" class="app-link">800.528.3051</a> / <a
          href="mailto:ConnectedCare@Joerns.com" target="_blank" class="app-link">ConnectedCare@Joerns.com</a></p>
      <p class="m-t-10"><a href="https://www.joerns.com" target="_blank" class="app-link">Joerns.com</a></p>
    </ion-label>
  </div>

  <div class="section">
    <ion-label>
      <h2>App version: {{appVersion}}</h2>
      <div>
        <img width="150" src="assets/svg/powered_by.svg" />
      </div>
      <p>ONDO Systems, Inc.</p>
      <p>California</p>
      <p class="m-t-10"><a href="https://www.ondosystems.com/" target="_blank" class="app-link">ondosystems.com</a></p>
    </ion-label>
  </div>

  <div class="section">
    <ion-label>
      <h2>Legal</h2>
      <a href="https://www.ondosystems.com/terms" target="_blank" class="app-link">Terms of Use</a>
      <span> | </span>
      <a href="https://www.ondosystems.com/privacy" target="_blank" class="app-link">Privacy</a>
    </ion-label>
  </div>

  <!-- <div class="section">


    </ion-label>
    <div class="horizontal-line"></div>
    <ion-item class="temp-toggle" lines="none">
      <ion-label> Adjusted Temperature Mode </ion-label>
      <ion-toggle [(ngModel)]="isCompTempOn" color="ok"></ion-toggle>
    </ion-item>
    <div class="horizontal-line"></div>
  </div>

  <p class="instruction">When enabled, an algorithmic adjustment is applied to represent the body temperature.
    The ability to sense higher temperature is not affected.
  </p> -->

</ion-content>