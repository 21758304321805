import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonNav, IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SettingsService } from '../../services/settings.service';
import { RoomListModel } from 'src/app/shared/models/roomListModel';
import { roomsFilterData } from 'src/app/shared/data/filter.data';
import { cloneDeep } from 'lodash-es';
import { FilterService } from 'src/app/core/services/filter.service';
import { SubHeaderComponent } from 'src/app/shared/components/sub-header/sub-header.component';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { CreateRoomComponent } from '../create-room/create-room.component';
import { RoomDetailsPage } from '../room-details/room-details.page';
import { AuthService } from 'src/app/core/services/auth.service';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.page.html',
  styleUrls: ['./room-list.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ScrollingModule,
    SubHeaderComponent,
    AccessControlDirective
  ],
})
export class RoomListPage {
  roomListDataCopy: any[] = [];
  roomListData: RoomListModel[] = [];
  searchRoomListData: RoomListModel[] = [];
  groups = cloneDeep(roomsFilterData);
  filters: any = {};
  searchQuery = '';

  constructor(
    private settingsService: SettingsService,
    private filterService: FilterService,
    private uiUtilityService: UiUtilityService,
    private ionNav: IonNav,
    private authService: AuthService
  ) { }

  ionViewWillEnter() {
    this.getRoomList();
    this.sortRoomList();
  }

  sortRoomList() {
    const result = this.filterService.getFilterData(this.groups);
    this.filters = this.filterService.setSortFilter(result);
  }

  didSortRoomList() {
    const result = this.filterService.filteredData(
      this.roomListDataCopy,
      this.filters
    );
    this.roomListData = result;
    this.searchRoomListData = result;
  }

  getRoomList() {
    this.settingsService.getRoomListByFacilityId().subscribe((response) => {
      if (response.success) {
        const data: any = response.data;
        this.roomListDataCopy = data;
        this.didSortRoomList();
      }
    });
  }

  navigateToAddRoom() {
    this.ionNav.push(CreateRoomComponent);
  }

  navigateToRoomDetails(roomId: string) {
    if (this.authService.isFeatureEnabled('getRoomDetail')) {
      this.ionNav.push(RoomDetailsPage, { roomId });
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  onFilterApply(data: any) {
    this.filters = this.filterService.setSortFilter(data);
    this.didSortRoomList();
  }

  onFilterCancel(data: any) {
    this.groups = data;
  }

  handleChange(event: any) {
    this.roomListData = this.searchRoomListData;
    this.searchQuery = event.target.value;
    if (this.searchQuery && this.searchQuery.trim() !== '') {
      this.roomListData = this.roomListData.filter((term: any) => {
        if (term.roomName) {
          return (
            term.roomName
              .toLowerCase()
              .indexOf(this.searchQuery.trim().toLowerCase()) > -1
          );
        }
      });
    } else if (this.searchQuery === '') {
      this.roomListData = this.searchRoomListData;
    }
  }
}
