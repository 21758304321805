export class Floor {
    floorID = null;
    floorName =  '';
    floorImage? = '';
    height? = 300;
    width? = 300;
}

export class FloorList {
    floorID = null;
    floorName =  '';
}
