import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ENTITY_ASSET, ENTITY_BED } from 'src/app/shared/config/app.constant';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { SettingsService } from '../../services/settings.service';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';

@Component({
  standalone: true,
  selector: 'app-unassign-list',
  templateUrl: './unassign-list.page.html',
  styleUrls: ['./unassign-list.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, RouterModule, UnifiedHeaderComponent],
})
export class UnassignListPage {
  title: string = '';
  defaultHref: string = '/room-list';
  entityType: string = '';
  entityId: string = '';
  assignedItems: any[] = [];
  filteredItems: any[] = [];
  isSaveDisabled: boolean = true;
  unassignItemsNote: string = 'Select which items you want to assign to this room.';
  noAssignItemsNote: string = 'No items available to assign.';

  constructor(
    private navParams: NavParams,
    private uiUtilityService: UiUtilityService,
    private settingsService: SettingsService,
    private nav: IonNav
  ) { }

  ngOnInit(): void {
    this.entityType = this.navParams.get('entityType');
    this.entityId = this.navParams.get('entityId');
    if (this.entityType === ENTITY_ASSET) {
      this.title = 'Assets';
      this.unassignItemsNote = 'Select which assets you want to assign to this room.';
      this.noAssignItemsNote = 'No assets available to assign.';
    } else if (this.entityType === ENTITY_BED) {
      this.title = 'Beds';
      this.unassignItemsNote = 'Select which beds you want to assign to this room.';
      this.noAssignItemsNote = 'No beds available to assign.';
    }
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  didViewWillEnter() {
    this.getItemDetails();
  }

  async getItemDetails() {
    let endpoint: any;
    if (this.entityType === ENTITY_ASSET) {
      endpoint = this.settingsService.getAvailableAssets();
    } else if (this.entityType === ENTITY_BED) {
      endpoint = this.settingsService.getAvailableBeds();
    }
    if (!endpoint) {
      return;
    }
    await this.uiUtilityService.showLoader();
    endpoint.subscribe((response: any) => {
      if (response && response.success && response.data) {
        let result: any[] = [];
        let nameKey = 'name';
        let nameKeyFallback = 'localId';
        if (this.entityType === ENTITY_ASSET) {
          result = response.data;
          nameKey = 'assetName';
          nameKeyFallback = 'assetLocalId';
        } else if (this.entityType === ENTITY_BED) {
          result = response.data
          nameKey = 'bedName';
        }
        this.assignedItems = result.map((item: any) => ({ ...item, itemName: item[nameKey], itemNameFallback: item[nameKeyFallback], checked: false }));
        this.filteredItems = [...this.assignedItems];
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateBack() {
    this.nav.pop();
  }

  async assignItems() {
    let endpoint: any;
    let successMsg = 'The items assigned successfully.';
    const payload = this.assignedItems.filter((item: any) => item.checked).map((item: any) => item.id);
    if (this.entityType === ENTITY_ASSET) {
      endpoint = this.settingsService.addAssetToRoom(this.entityId, payload);
      successMsg = 'The assets assigned successfully.'
    } else if (this.entityType === ENTITY_BED) {
      endpoint = this.settingsService.addBedToRoom(this.entityId, payload);
      successMsg = 'The beds assigned successfully.'
    }
    if (!endpoint) {
      return;
    }
    await this.uiUtilityService.showLoader();
    endpoint.subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.navigateBack();
        this.uiUtilityService.showToast(successMsg);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  handleChange(event: any) {
    const keyword = event.target.value?.toLowerCase()?.trim();
    if (keyword && keyword !== '') {
      this.filteredItems = this.assignedItems.filter((item: any) => item.itemName?.toLowerCase()?.includes(keyword));
    } else {
      this.filteredItems = [...this.assignedItems];
    }
  }

  handleChecked(event: any, id: string) {
    this.isSaveDisabled = false;
    const checked = event.target.checked;
    const assignedItem = this.assignedItems.find((item: any) => item.id === id);
    if (assignedItem) {
      assignedItem.checked = checked;
    }
    const filteredItem = this.assignedItems.find((item: any) => item.id === id);
    if (filteredItem) {
      filteredItem.checked = checked;
    }
  }
}
