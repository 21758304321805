import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { FacilityModel } from 'src/app/shared/models/login-response.model';
import { BaseWearerModel } from 'src/app/shared/models/wearer.model';
import { environment } from 'src/environments/environment';
import { IonNav, IonicModule, NavController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SubHeaderComponent } from 'src/app/shared/components/sub-header/sub-header.component';
import { cloneDeep } from 'lodash-es';
import { wearerFilterData } from 'src/app/shared/data/filter.data';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { WearerDetailsPage } from '../wearer-details/wearer-details.page';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  standalone: true,
  selector: 'app-wearer-list',
  templateUrl: './wearer-list.page.html',
  styleUrls: ['./wearer-list.page.scss'],
  imports: [
    IonicModule,
    CommonModule,
    ScrollingModule,
    RouterModule,
    SubHeaderComponent,
    AccessControlDirective
  ],
})
export class WearerListPage {
  wearerListCopy: BaseWearerModel[] = [];
  wearerList: BaseWearerModel[] = [];
  searchWearerList: BaseWearerModel[] = [];
  subscriptions: Subscription[] = [];
  selectedFacility = '';
  groups = cloneDeep(wearerFilterData);
  filters: any = {};
  searchQuery = '';

  constructor(
    private dataService: DataService<BaseWearerModel[]>,
    private filterService: FilterService,
    private headerService: HeaderService,
    private uiUtilityService: UiUtilityService,
    private nav: IonNav,
    private authService: AuthService
  ) { }

  ionViewWillEnter() {
    this.subscriptions.push(
      this.headerService
        .getFacility()
        .subscribe((facility: FacilityModel) => this.onFacilityChange(facility))
    );
    this.getWearerList();
    this.sortWearerList();
  }

  sortWearerList() {
    const result = this.filterService.getFilterData(this.groups);
    this.filters = this.filterService.setSortFilter(result);
  }

  didSortWearerList() {
    const result = this.filterService.filteredData(
      this.wearerListCopy,
      this.filters
    );
    this.wearerList = result;
    this.searchWearerList = result;
  }

  async getWearerList() {
    await this.uiUtilityService.showLoader();
    const url = `${environment.apiUrl.residentManagement}${FACILITY_ID_URL}/${EndPointsEnum.getList}`;
    this.dataService
      .getData(url)
      .subscribe((response: ApiResponseModel<BaseWearerModel[]>) => {
        if (response && response.success && response.data) {
          const loginData: any = this.authService.getLoginData;
          const facilityId = this.headerService.getFacilityId();
          const groups = loginData.facility.find((item: any) => item.facilityId === facilityId)?.wearerGroupDefinition || [];
          const residentGroupId = groups.find((item: any) => item.wearerGroupName.toLowerCase() === 'resident' || item.wearerGroupName.toLowerCase() === 'residents')?.id || '';
          this.wearerListCopy = response.data?.map((item: any) => {
            if (!item.displayName) {
              item.displayName = (item.firstName || item.lastName) ? `${item.firstName} ${item.lastName}` : '';
            }
            if (item.displayName) {
              item.displayName = item.displayName?.trim();
            }
            item.isResident = false;
            if (residentGroupId === item.wearerGroupId) {
              item.isResident = true;
            }
            return item;
          });
          this.didSortWearerList();
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  groupByAlphabet(
    wearer: BaseWearerModel,
    wearerIndex: number,
    wearerList: BaseWearerModel[]
  ): string {
    if (wearerIndex === 0) {
      return (wearer.firstName || '')[0]?.toUpperCase();
    }
    const prevWearerLetter = (wearerList[wearerIndex - 1]?.firstName ||
      '')[0]?.toUpperCase();
    const currentWearerLetter = (wearer.firstName || '')[0]?.toUpperCase();
    if (prevWearerLetter !== currentWearerLetter) {
      return currentWearerLetter;
    }
    return '';
  }

  navigateToDetailsPage(wearer: BaseWearerModel) {
    if (this.authService.isFeatureEnabled('getWearerDetail')) {
      this.nav.push(WearerDetailsPage, { id: wearer.id, mode: 'detail' });
    }
  }

  navigateToAddWearer() {
    this.nav.push(WearerDetailsPage);
  }

  onFacilityChange(facility: FacilityModel) {
    if (facility.facilityId !== this.selectedFacility) {
      this.getWearerList();
    }
  }

  onFilterApply(data: any) {
    this.filters = this.filterService.setSortFilter(data);
    this.didSortWearerList();
  }

  onFilterCancel(data: any) {
    this.groups = data;
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  handleChange(event: any) {
    this.wearerList = this.searchWearerList;
    this.searchQuery = event.target.value;
    if (this.searchQuery && this.searchQuery.trim() !== '') {
      this.wearerList = this.wearerList.filter((term: any) => {
        if (term.localId && term.isResident) {
          return (
            term.localId
              .toLowerCase()
              .indexOf(this.searchQuery.trim().toLowerCase()) > -1
          );
        } else {
          return (
            term.displayName
              .toLowerCase()
              .indexOf(this.searchQuery.trim().toLowerCase()) > -1
          );
        }
      });
    } else if (this.searchQuery === '') {
      this.wearerList = this.searchWearerList;
    }
  }
}
