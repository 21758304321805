import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ENTITY_ASSET, ENTITY_BED, ENTITY_BRIDGE } from 'src/app/shared/config/app.constant';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { SettingsService } from '../../services/settings.service';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { RemoveListPage } from '../remove-list/remove-list.page';
import { UnassignListPage } from '../unassign-list/unassign-list.page';
import { BedDetailsPage } from 'src/app/features/bed-management/pages/bed-details/bed-details.page';
import { AssetDetailsPage } from 'src/app/features/asset-management/pages/asset-details/asset-details.page';
import { BridgeDetailsPage } from 'src/app/features/bridge-management/pages/bridge-details/bridge-details.page';
import { BridgeStatusEnum } from 'src/app/shared/enums/bridge-status.enum';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  standalone: true,
  selector: 'app-assign-list',
  templateUrl: './assign-list.page.html',
  styleUrls: ['./assign-list.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, RouterModule, UnifiedHeaderComponent],
})
export class AssignListPage {
  defaultHref: string = '/room-list';
  entityType: string = '';
  entityId: string = '';
  assignedItems: any[] = [];
  entityConfig: any = {
    title: 'Items',
    noAssignItemsNote: 'No items assigned yet.',
    actions: true,
    helpText: ''
  };
  config: any = {
    [ENTITY_ASSET]: {
      title: 'Assets',
      noAssignItemsNote: 'No assets assigned to this room yet.',
      actions: true,
      helpText: ''
    },
    [ENTITY_BED]: {
      title: 'Beds',
      noAssignItemsNote: 'No beds assigned to this room yet.',
      actions: true,
      helpText: ''
    },
    [ENTITY_BRIDGE]: {
      title: 'Bridges',
      noAssignItemsNote: 'No bridges assigned to this room yet.',
      actions: false,
      helpText: 'Assign or remove a bridge from this room by going to its Bridge Details page, either through Bridge Management or via the link above.',
      rightSlot: 'bridgeStatusName'
    }
  };
  isSummaryEnabled = true;

  constructor(
    private navParams: NavParams,
    private uiUtilityService: UiUtilityService,
    private settingsService: SettingsService,
    private nav: IonNav,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.entityType = this.navParams.get('entityType');
    this.entityId = this.navParams.get('entityId');
    this.entityConfig = this.config[this.entityType];
    if (this.entityType === ENTITY_ASSET) {
      this.isSummaryEnabled = this.authService.isFeatureEnabled('getAssetDetail');
    } else if (this.entityType === ENTITY_BED) {
      this.isSummaryEnabled = this.authService.isFeatureEnabled('getBedDetail');
    } else if (this.entityType === ENTITY_BRIDGE) {
      this.isSummaryEnabled = this.authService.isFeatureEnabled('getBridgeDetail');
    }
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  didViewWillEnter() {
    this.getItemDetails();
  }

  async getItemDetails() {
    await this.uiUtilityService.showLoader();
    this.settingsService.getRoomDetails(this.entityId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        let result: any[] = [];
        let nameKey = 'name';
        let nameKeyFallback = 'localId';
        if (this.entityType === ENTITY_ASSET && response.data?.assets) {
          result = response.data.assets;
          nameKey = 'assetName';
          nameKeyFallback = 'assetLocalId';
        } else if (this.entityType === ENTITY_BED && response.data?.beds) {
          result = response.data.beds
          nameKey = 'bedName';
        } else if (this.entityType === ENTITY_BRIDGE && response.data?.bridges) {
          result = response.data.bridges.map((item: any) => ({ ...item, bridgeStatusName: this.getBridgeStatus(item.bridgeStatus) }));
          nameKey = 'bridgeName';
        }
        this.assignedItems = result.map((item: any) => ({ ...item, itemName: item[nameKey], itemNameFallback: item[nameKeyFallback] }));
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateToItemDetailsPage(item: any) {
    if (!this.isSummaryEnabled) {
      return;
    }
    if (this.entityType === ENTITY_ASSET && item.id) {
      this.nav.push(AssetDetailsPage, { assetId: item.id, mode: 'detail' });
    } else if (this.entityType === ENTITY_BED && item.id) {
      this.nav.push(BedDetailsPage, { bedId: item.id, mode: 'detail' });
    } else if (this.entityType === ENTITY_BRIDGE && item.bridgeId) {
      this.nav.push(BridgeDetailsPage, { bridgeId: item.bridgeId, mode: 'detail' });
    }
  }

  removeItems() {
    this.nav.push(RemoveListPage, { entityId: this.entityId, entityType: this.entityType });
  }

  assignItems() {
    this.nav.push(UnassignListPage, { entityId: this.entityId, entityType: this.entityType });
  }


  getBridgeStatus(bridgeStatus: any) {
    switch (bridgeStatus) {
      case 0: return BridgeStatusEnum.offline;
      case 1: return BridgeStatusEnum.online;
      case 2: return BridgeStatusEnum.initialising;
      default: return '';
    }
  }
}
