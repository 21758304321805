<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [text]="'Back'"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">{{title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-no-padding">
    <ion-row class="upper-row">
      <ion-col size="12">
        <div class="band-card-status">
          <div class="associated" [ngClass]="associated.status" *ngIf="!isWaiting && bandService.bandStatus?.bandId">
            {{associated.text}}
          </div>
          <div class="progress-text" *ngIf="isWaiting">Waiting... </div>
          <div class="ion-text-center">
            <ion-spinner name="lines" class="spinner" *ngIf="isWaiting"></ion-spinner>
          </div>
          <img class="band-state-img" [src]="associated.img" />
          <div class="wearer-name">
            <div>{{associated.bandIdText}}</div>
            <div>{{associated.wearerText}}</div>
            <!-- <div>Firmware version: {{bandService.bandStatus?.firmwareVersion ?
              bandService.bandStatus?.firmwareVersion : '?'}}</div> -->
          </div>
        </div>
        <div class="band-card-hint" *ngIf="associated.hint !== ''">
          <ion-icon [src]="'assets/icon/icons_Hint.svg'"></ion-icon>
          <span class="note">{{associated.hint}}</span>
        </div>
        <div class="band-btns" *ngIf="associated.details !== ''">
          <ion-button class="button" (click)="goToDetails()">{{associated.details}}</ion-button>
        </div>
        <div class="band-btns" *ngIf="associated.findAnother">
          <ion-button class="button" (click)="fetchBandId()">Find Another</ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>