import {
  UnifiedInput,
  ControlType,
} from 'src/app/shared/components/unified-custom-input/unified-input.types';

export const bridgeDetailsInputConfig: UnifiedInput[] = [
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Name',
    label: 'Name',
    value: '',
    detail: false,
    name: 'bridgeName',
    showNone: true,
    fieldName: 'bridgeName'
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Status',
    label: 'Status',
    value: '',
    detail: false,
    name: 'bridgeStatusName',
    showNone: true,
    fieldName: 'bridgeStatus',
    readOnly: true,
    colorCodeClass: 'bridge-status'
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Wi-Fi MAC ID',
    label: 'Wi-Fi MAC ID',
    value: '',
    detail: false,
    name: 'wifiMacId',
    showNone: true,
    fieldName: 'wifiMacId'
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'BLE MAC ID',
    label: 'BLE MAC ID',
    value: '',
    detail: false,
    name: 'bleMacId',
    showNone: true,
    fieldName: 'bleMacId'
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Firmware Version',
    label: 'Firmware Version',
    value: '',
    detail: false,
    name: 'firmware',
    showNone: true,
    fieldName: 'firmwareVersion'
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Facility Wi-Fi',
    label: 'Facility Wi-Fi',
    value: '',
    detail: false,
    name: 'wifiNetwork',
    showNone: true,
    fieldName: 'wifiNetwork'
  }
];