import { Injectable, OnDestroy } from "@angular/core";
import { Network } from '@capacitor/network';
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root',
})
export class NetworkService implements OnDestroy {
  private handler: any;
  private status = new BehaviorSubject<any>(null);

  constructor() {
    this.handler = Network.addListener("networkStatusChange", (status) => {
      this.sendStatusChangeMsg(status);
    });
  }

  public getStatusChangeMsg(): Observable<any> {
    return this.status.asObservable();
  }

  private sendStatusChangeMsg(status: any): void {
    this.status.next(status);
  }

  ngOnDestroy() {
  }
}