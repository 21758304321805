import {
  UnifiedInput,
  ControlType,
} from 'src/app/shared/components/unified-custom-input/unified-input.types';

export const wearerDetailsInputConfig: UnifiedInput[] = [
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'ID',
    label: 'ID',
    value: '',
    detail: true,
    name: 'localId',
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'First Name',
    label: 'First Name',
    value: '',
    detail: true,
    name: 'firstName',
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Last Name',
    label: 'Last Name',
    value: '',
    detail: true,
    name: 'lastName',
  },
  //   {
  //     controlType: ControlType.SELECT,
  //     label: 'Wearer Group',
  //     value: '',
  //     detail: true,
  //     name: 'wearerGroup',
  //   },
];
