export enum InputEnum {
    USERID = 'userId',
    FLOORNAME = 'floorName',
    FACREPRESENTATIVENAME = 'facRepresentativeName',
    USERPHONE = 'userPhone',
    OWNERNAME = 'ownerName',
    EMAIL = 'email',
    CORPPHONE = 'corpPhone',
    ABSTRACTNAME = 'abstractName',
    HUMANNAME = 'humanName',
    BRIDGENAME = 'bridgeName',
    LOCALID = 'localId',
}