<app-unified-header [title]="'Remove ' + title" [defaultHref]="defaultHref" (onSave)="removeItems()"
  [saveBtn]="{title: 'Done', isDisable: isSaveDisabled}"></app-unified-header>

<ion-content>
  <ion-item class="list-header" lines="none">
    <ion-label>
      <h2>Assigned {{title}}</h2>
      <p class="ion-text-wrap">{{removeItemNote}}</p>
    </ion-label>
  </ion-item>
  <div *ngIf="assignedItems?.length">
    <ion-item *ngFor="let assignedItem of assignedItems">
      <ion-label>
        <h3 class="p-b-1 no-link">{{assignedItem.itemName || assignedItem.itemNameFallback}}</h3>
      </ion-label>
      <ion-button fill="clear" slot="end" class="remove-item" (click)="removeItem(assignedItem)">
        <ion-icon slot="icon-only" src="assets/icon/icon-close.svg" color="secondary"></ion-icon>
      </ion-button>
    </ion-item>
  </div>
</ion-content>