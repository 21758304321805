import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { FacilityModel } from 'src/app/shared/models/facility.model';
import { FacilityService } from './services/facility.service';
import { IonNav, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AddFacilityPage } from './add-facility/add-facility.page';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
@Component({
  standalone: true,
  selector: 'app-facility-management',
  templateUrl: './facility-management.page.html',
  styleUrls: ['./facility-management.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, RouterModule, AccessControlDirective],
})
export class FacilityManagementPage {
  facilityList: FacilityModel[] = [];
  EndPoints = EndPointsEnum;

  constructor(
    private facilityService: FacilityService,
    private storageService: StorageService,
    private uiUtilityService: UiUtilityService,
    private ionNav: IonNav
  ) { }
  ionViewWillEnter() {
    let facilityData: FacilityModel[] = [];
    this.facilityService
      .getFacilityList()
      .subscribe((response: ApiResponseModel<FacilityModel[]>) => {
        if (response && response.success) {
          facilityData = response.data!;
          this.storageService.setSessionStorage(
            StorageEnum.facilityList,
            response.data,
            true
          );
          this.facilityList = [
            ...this.facilityService.filterFacilityList(
              facilityData,
              'facilityName',
              1
            ),
          ];
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }
  groupByAlphabet(
    facility: FacilityModel,
    facilityIndex: number,
    facilityList: FacilityModel[]
  ): string {
    if (facilityIndex === 0) {
      return (facility.facilityName || '')[0]?.toUpperCase();
    }
    const prevFacilityLetter = (facilityList[facilityIndex - 1]?.facilityName ||
      '')[0]?.toUpperCase();
    const currentFacilityLetter = (facility.facilityName ||
      '')[0]?.toUpperCase();
    if (prevFacilityLetter !== currentFacilityLetter) {
      return currentFacilityLetter;
    }
    return '';
  }

  ionViewWillLeave(): void { }

  navigateToAddFacility() {
    this.ionNav.push(AddFacilityPage);
  }

  navigateToDetailsPage(facility: FacilityModel) {
    this.ionNav.push(AddFacilityPage, { facilityId: facility.facilityId });
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }
}
