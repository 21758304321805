import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IonicModule,
  ModalController,
  Platform,
  NavParams,
  IonNav,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { InputValidator } from 'src/app/core/services/input-config.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { addAccountDetailsInputConfig } from './add-account-input.config';
import { UnifiedInput } from 'src/app/shared/components/unified-custom-input/unified-input.types';
import { UnifiedCustomInputComponent } from 'src/app/shared/components/unified-custom-input/unified-custom-input.component';
import { cloneDeep } from 'lodash-es';
import { UnifiedSelectComponent } from 'src/app/shared/components/unified-select/unified-select.component';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { UserService } from '../services/user.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { facilityRoles } from 'src/app/shared/enums/user-roles.enum';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';

@Component({
  standalone: true,
  selector: 'app-add-account',
  templateUrl: './add-account.page.html',
  styleUrls: ['./add-account.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedCustomInputComponent,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
})
export class AddAccountPage implements OnInit, OnDestroy {
  userId: string = '';
  mode: string = 'detail';
  destroyRef = inject(DestroyRef);
  userDetails: any = null;
  userDetailsForm: FormGroup | null = null;
  isSaveDisabled = true;
  subscriptions: Subscription = new Subscription();
  validator: InputValidator | null = null;
  addAccountDetailsInputConfig: UnifiedInput[] = cloneDeep(
    addAccountDetailsInputConfig
  );
  isReadMode = true;
  subscription: any;
  roles: any[] = [];
  isFacilityRoleSelected = false;
  isCurrentUser = false;

  constructor(
    private formBuilder: FormBuilder,
    private uiUtilityService: UiUtilityService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private userService: UserService,
    private authService: AuthService,
    private navParams: NavParams,
    private ionNav: IonNav
  ) { }

  ngOnInit(): void {
    const userId = this.navParams.get('userId');
    const mode = this.navParams.get('mode');
    this.didOnInit(userId, mode);
  }

  didOnInit(userId: string, mode: string) {
    this.userId = userId;
    this.mode = mode;
    this.isReadMode = this.mode === 'edit' ? false : true;
    this.isCurrentUser = this.userId === this.authService.getLoginData.userId;
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribeWithPriority(
      10,
      () => {
        console.log('Hardware back clicked');
      }
    );
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  didViewWillEnter() {
    this.isSaveDisabled = true;
    if (this.userId) {
      this.getUserDetails();
    } else {
      this.setConfig('detail', true);
      const defaultValues = this.uiUtilityService.getValuesFromUnifiedInput(
        addAccountDetailsInputConfig
      );
      this.createForm(defaultValues);
      this.subscribeForm();
    }
    this.getUserRoles();
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  subscribeForm() {
    this.userDetailsForm?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.userDetailsForm) {
          this.isSaveDisabled =
            this.userDetailsForm.invalid || !this.userDetailsForm.dirty;
        }
      });
  }

  setUserDetails(data: any) {
    this.userDetails = data;
    if (this.userDetails?.userId) {
      this.setConfig('', '', this.userDetails);
      this.setConfig('detail', !this.isReadMode);
    }
    this.createForm(data);
    this.subscribeForm();
    this.isSaveDisabled = false;
    this.setFormData();
  }

  async getUserDetails() {
    await this.uiUtilityService.showLoader();
    this.userService.getUserDetails(this.userId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.setUserDetails(response.data);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  setFormData() {
    if (!this.roles.length || !this.userDetails?.userId) {
      return;
    }
    if (this.userDetails?.userRoleId) {
      const role = this.roles.find(
        (item: any) => item.id === this.userDetails.userRoleId
      );
      this.userDetailsForm?.controls['userRoleName'].setValue(role?.roleName);
    }
    if (this.userDetails?.userFacilityId) {
      const { facility } = this.authService.getLoginData;
      const found = facility.find(
        (item: any) => item.facilityId === this.userDetails.userFacilityId
      );
      this.userDetailsForm?.controls['userFacilityName'].patchValue(
        found?.facilityName
      );
    }
  }

  getUserRoles() {
    this.userService.getUserRoles().subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.roles = response.data;
        this.setFormData();
      }
    });
  }

  setConfig(key: string, value: any, data?: any) {
    this.addAccountDetailsInputConfig.forEach((item: any) => {
      if (data) {
        item.value = data[item.name];
      } else {
        item[key] = value;
      }
    });
  }

  createForm(userDetails: any) {
    const { corpId } = this.authService.getLoginData;
    this.userDetailsForm = this.formBuilder.group({
      userName: [userDetails?.userName, [Validators.required]],
      userPhone: [userDetails?.userPhone, [Validators.required]],
      userEmail: [userDetails?.userEmail, [Validators.required]],
      userTitle: [userDetails?.userTitle, [Validators.required]],
      userFacilityId: [userDetails?.userFacilityId],
      userCorporateId: [userDetails?.userCorporateId || corpId],
      userRoleId: [userDetails?.userRoleId, [Validators.required]],
      userFacilityName: [null],
      userRoleName: [null],
    });
    if (userDetails?.userRoleId) {
      this.setFacilityChecks(userDetails.userRoleId);
    }
  }

  inputChanged(inputConfig: UnifiedInput) {
    if (this.userDetailsForm && inputConfig.name) {
      const found = this.addAccountDetailsInputConfig.find(
        (item: any) => item.name === inputConfig.name
      );
      if (found) {
        found.value = inputConfig.value;
        this.userDetailsForm.controls[inputConfig.name].patchValue(
          inputConfig.value
        );
        this.userDetailsForm?.markAsDirty();
        this.userDetailsForm?.updateValueAndValidity();
      }
    }
  }

  async showSelectModal({
    title,
    emptyMessage,
    key,
    displayName,
    value,
    list,
    isSave,
  }: any) {
    const modal = await this.modalCtrl.create({
      component: UnifiedSelectComponent,
      componentProps: {
        data: { title, emptyMessage, key, displayName, value, list },
        isSave,
      },
    });
    modal.present();
    return await modal.onWillDismiss();
  }

  setFacilityChecks(roleId: any) {
    if (facilityRoles.indexOf(roleId) === -1) {
      this.isFacilityRoleSelected = false;
      this.userDetailsForm?.controls['userFacilityName'].patchValue(null);
      this.userDetailsForm?.controls['userFacilityId'].patchValue(null);
      this.f['userFacilityId'].clearValidators();
    } else {
      this.isFacilityRoleSelected = true;
      this.f['userFacilityId'].setValidators([Validators.required]);
    }
    this.f['userFacilityId'].updateValueAndValidity();
    this.userDetailsForm?.updateValueAndValidity();
  }

  async didSelectUserRoleModal() {
    const { data, role } = await this.showSelectModal({
      title: 'Select User Role',
      emptyMessage: 'No user roles available right now.',
      key: 'id',
      displayName: 'roleName',
      value: this.userDetails?.userRoleId,
      list: this.roles,
    });
    if (role === 'confirm') {
      const user: any = this.roles.find((item: any) => item.id === data);
      if (user) {
        this.setFacilityChecks(user.id);
        this.userDetailsForm?.controls['userRoleName'].patchValue(
          user.roleName
        );
        this.userDetailsForm?.controls['userRoleId'].patchValue(user.id);
      }
      this.userDetailsForm?.markAsDirty();
      this.userDetailsForm?.updateValueAndValidity();
    }
  }

  async setUserRole() {
    if (this.userId && this.isReadMode) {
      return;
    }
    if (this.roles.length) {
      this.didSelectUserRoleModal();
    } else {
      await this.uiUtilityService.showLoader();
      this.userService.getUserRoles().subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.roles = response.data;
          this.didSelectUserRoleModal();
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
    }
  }

  async setFacility() {
    if (this.userId && this.isReadMode) {
      return;
    }
    const { facility } = this.authService.getLoginData;
    const { data, role } = await this.showSelectModal({
      title: 'Select Facility',
      emptyMessage: 'No facilities available right now.',
      key: 'facilityId',
      displayName: 'facilityName',
      value: this.userDetails?.userFacilityId,
      list: facility,
    });
    if (role === 'confirm') {
      const fct: any = facility.find((item: any) => item.facilityId === data);
      if (fct) {
        this.userDetailsForm?.controls['userFacilityName'].patchValue(
          fct.facilityName
        );
        this.userDetailsForm?.controls['userFacilityId'].patchValue(
          fct.facilityId
        );
      }
      this.userDetailsForm?.markAsDirty();
      this.userDetailsForm?.updateValueAndValidity();
    }
  }

  async manageUser() {
    if (this.userId && this.isReadMode) {
      this.ionNav.push(AddAccountPage, { userId: this.userId, mode: 'edit' });
      return;
    }
    if (this.userDetailsForm?.invalid) {
      return;
    }
    const payload = this.userDetailsForm?.value;
    let apiRef: any;
    if (this.userId) {
      payload.userId = this.userId;
      apiRef = this.userService.updateUser(payload);
    } else {
      apiRef = this.userService.createUser(payload);
    }
    await this.uiUtilityService.showLoader();
    apiRef.subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.uiUtilityService.showToast('The user details saved successfully.');
        if (!this.userId && response.data?.userId) {
          this.userDetailsForm?.markAsPristine();
          this.didOnInit(response.data.userId, 'detail');
          this.didViewWillEnter();
        } else {
          this.navigateBack();
        }
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateBack(isRestrict = false) {
    if (!(this.userId && isRestrict && !this.userDetailsForm?.pristine)) {
      this.ionNav.pop();
    }
  }

  onConfirmDeleteUser = async () => {
    await this.uiUtilityService.showLoader();
    this.userService.deleteUser(this.userDetails).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.ionNav.pop();
        this.uiUtilityService.showToast('The user deleted successfully.');
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  };

  deleteUser() {
    const message =
      'You are about to delete this user. This action cannot be undone.';
    const header = 'Delete This User';
    this.uiUtilityService.presentConfirm(
      message,
      this.onConfirmDeleteUser,
      undefined,
      header,
      'Delete'
    );
  }

  async resetPassword() {
    await this.uiUtilityService.showLoader();
    this.userService
      .resetPassword({
        userId: this.userDetails.userId,
        emailId: this.userDetails.userEmail,
      })
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.uiUtilityService.showAlert(response.data, 'Success');
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  getConfig(config: UnifiedInput) {
    return { ...config };
  }

  get f() {
    return this.userDetailsForm?.controls || {};
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
