<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Help</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToDownloadsPage()">Download</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <iframe src="https://www.ondosystems.com/in-app-help" (load)="onLoad()"></iframe>
</ion-content>