import {
  Component,
  DestroyRef,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  IonicModule,
  ModalController,
  Platform,
  IonNav,
  NavParams,
  MenuController,
} from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { InputConfigService, InputValidator } from 'src/app/core/services/input-config.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { AssetService } from '../../services/asset.service';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { assetDetailsInputConfig } from './asset-details-input.config';
import { UnifiedInput } from 'src/app/shared/components/unified-custom-input/unified-input.types';
import { UnifiedCustomInputComponent } from 'src/app/shared/components/unified-custom-input/unified-custom-input.component';
import { cloneDeep } from 'lodash-es';
import { AssetModel, AssetTypeModel } from 'src/app/shared/models/asset-model';
import { UnifiedSelectComponent } from 'src/app/shared/components/unified-select/unified-select.component';
import { SettingsService } from 'src/app/features/settings/services/settings.service';
import { BandPageFlowEnum } from 'src/app/shared/enums/band.enum';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { RoomDetailsPage } from 'src/app/features/settings/room-management/room-details/room-details.page';
import { BandCompletionPage } from 'src/app/features/band/pages/band-completion/band-completion.page';
import { AssignDevicePage } from 'src/app/features/band/pages/assign-device/assign-device.page';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { Capacitor } from '@capacitor/core';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  standalone: true,
  selector: 'app-asset-details',
  templateUrl: './asset-details.page.html',
  styleUrls: ['./asset-details.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedCustomInputComponent,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
})
export class AssetDetailsPage implements OnInit, OnDestroy {
  assetId: string = '';
  isRootPage: boolean = false;
  mode: string = 'detail';
  destroyRef = inject(DestroyRef);
  assetDetails: AssetModel | null = null;
  assetDetailsForm: FormGroup | null = null;
  isSaveDisabled = true;
  subscriptions: Subscription = new Subscription();
  validator: InputValidator | null = null;
  assetDetailsInputConfig: UnifiedInput[] = cloneDeep(assetDetailsInputConfig);
  backButtonClicked: boolean = false;
  isReadMode = true;
  assetTypes: AssetTypeModel[] = [];
  rooms: any[] = [];
  subscription: any;
  isNative = Capacitor.isNativePlatform();
  isRoomSummaryEnabled = true;

  constructor(
    private assetService: AssetService,
    private formBuilder: FormBuilder,
    private uiUtilityService: UiUtilityService,
    private modalCtrl: ModalController,
    private settingsService: SettingsService,
    private platform: Platform,
    private navParams: NavParams,
    private nav: IonNav,
    private menuCtrl: MenuController,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private inputConfigService: InputConfigService
  ) { }

  ngOnInit(): void {
    const assetId = this.navParams.get('assetId');
    const mode = this.navParams.get('mode');
    this.didOnInit(assetId, mode);
    this.isRoomSummaryEnabled = this.authService.isFeatureEnabled('getRoomDetail');
  }

  didOnInit(assetId: string, mode: string) {
    this.assetId = assetId;
    this.mode = mode;
    this.isReadMode = this.mode === 'edit' ? false : true;
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribeWithPriority(
      10,
      () => {
        console.log('Hardware back clicked');
      }
    );
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  didViewWillEnter() {
    this.isSaveDisabled = true;
    if (this.assetId) {
      this.getAssetDetails();
    } else {
      this.setConfig('detail', true);
      const defaultValues = this.uiUtilityService.getValuesFromUnifiedInput(
        assetDetailsInputConfig
      );
      this.createForm(defaultValues);
      this.subscribeForm();
    }
  }

  subscribeForm() {
    this.assetDetailsForm?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.assetDetailsForm) {
          this.isSaveDisabled =
            this.assetDetailsForm.invalid || !this.assetDetailsForm.dirty;
        }
      });
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  setAssetDetails(data: any) {
    this.assetDetails = data;
    this.assetService.activeAssetDetails = data;
    if (this.assetDetails?.assetId) {
      this.setConfig('', '', this.assetDetails);
      this.setConfig('detail', !this.isReadMode);
    }
    this.createForm(data);
    this.subscribeForm();
    this.isSaveDisabled = false;
  }

  async getAssetDetails() {
    await this.uiUtilityService.showLoader();
    this.assetService
      .getAssetDetails(this.assetId)
      .subscribe((response: ApiResponseModel<AssetModel>) => {
        if (response && response.success && response.data) {
          this.setAssetDetails(response.data);
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  setConfig(key: string, value: any, data?: any) {
    this.assetDetailsInputConfig.forEach((item: any) => {
      if (data) {
        item.value = data[item.name];
      } else {
        item[key] = value;
      }
    });
  }

  createForm(assetDetails: any) {
    this.assetDetailsForm = this.formBuilder.group({
      assetName: [assetDetails?.assetName || ''],
      assetLocalId: [assetDetails?.assetLocalId || ''],
      assetTypeName: [
        assetDetails?.assetType?.assetTypeName,
        [Validators.required],
      ],
      assetTypeId: [assetDetails?.assetType?.assetTypeId],
      roomName: [assetDetails?.room?.roomName],
    }, { validators: this.inputConfigService.atLeastOneValidator(['assetName', 'assetLocalId']) } as AbstractControlOptions);
  }

  inputChanged(inputConfig: UnifiedInput) {
    if (this.assetDetailsForm && inputConfig.name) {
      const found = this.assetDetailsInputConfig.find(
        (item: any) => item.name === inputConfig.name
      );
      if (found) {
        found.value = inputConfig.value;
        this.assetDetailsForm.markAsDirty();
        this.assetDetailsForm.controls[inputConfig.name].patchValue(
          inputConfig.value
        );
      }
    }
  }

  async showSelectModal({
    title,
    emptyMessage,
    key,
    displayName,
    value,
    list,
    isSave,
  }: any) {
    const modal = await this.modalCtrl.create({
      component: UnifiedSelectComponent,
      componentProps: {
        data: { title, emptyMessage, key, displayName, value, list },
        isSave,
      },
    });
    modal.present();
    return await modal.onWillDismiss();
  }

  async didSelectAssetModal() {
    const { data, role } = await this.showSelectModal({
      title: 'Select Type',
      emptyMessage: 'No asset types available right now.',
      key: 'assetTypeId',
      displayName: 'assetTypeName',
      value: this.assetDetails?.assetType?.assetTypeId,
      list: this.assetTypes,
    });
    if (role === 'confirm') {
      this.assetDetailsForm?.markAsDirty();
      const asset = this.assetTypes.find(
        (item: any) => item.assetTypeId === data
      );
      if (asset) {
        // this.selectedAssetTypeId = data;
        this.assetDetailsForm?.controls['assetTypeName'].patchValue(
          asset.assetTypeName
        );
        this.assetDetailsForm?.controls['assetTypeId'].patchValue(
          asset.assetTypeId
        );
      }
    }
  }

  async findBand(device?: any): Promise<void> {
    if (!this.isNative && !device) {
      return;
    }
    let returnBackIndex = 0;
    for (let i = 0; i < 100; i++) {
      const activeNav = await this.nav.getByIndex(i);
      if (activeNav?.element?.localName === 'app-asset-details') {
        console.debug(activeNav?.element?.localName, i);
        returnBackIndex = i;
        break;
      }
    }
    const bandId = device?.bandId || device?.tagId;
    if (bandId) {
      this.nav.push(BandCompletionPage, {
        bandId,
        flow: BandPageFlowEnum.AssetOndoTagAssociated,
        assetId: this.assetDetails?.assetId,
        details: this.assetDetails,
        bandInfo: device,
        returnBackIndex
      });
    } else {
      this.nav.push(AssignDevicePage, {
        assetId: this.assetDetails?.assetId,
        flow: BandPageFlowEnum.AssetAssociation,
        details: this.assetDetails,
        returnBackIndex,
        isReplaceRemoveEnabled: false
      });
    }
  }

  async setAssetType() {
    if (this.assetId && this.isReadMode) {
      return;
    }
    if (this.assetTypes.length) {
      this.didSelectAssetModal();
    } else {
      await this.uiUtilityService.showLoader();
      this.assetService
        .getAssetTypes()
        .subscribe((response: ApiResponseModel<AssetTypeModel[]>) => {
          if (response && response.success && response.data) {
            this.assetTypes = response.data;
            this.didSelectAssetModal();
          } else {
            const header = 'Error';
            this.uiUtilityService.showAlert(response.errorMessage!, header);
          }
        });
    }
  }

  async assignRoomToAssets(roomId: string) {
    if (
      roomId &&
      this.assetDetails?.room?.roomId &&
      roomId === this.assetDetails.room.roomId
    ) {
      return;
    }
    await this.uiUtilityService.showLoader();
    this.assetService
      .assignRoomToAsset(this.assetId, roomId)
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.uiUtilityService.showToast(
            'The asset details saved successfully.'
          );
          this.navigateBack(true);
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  async removeRoomToAssets() {
    await this.uiUtilityService.showLoader();
    this.assetService
      .removeRoomToAsset(this.assetId)
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.uiUtilityService.showToast(
            'The asset details saved successfully.'
          );
          this.navigateBack(true);
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  async didSelectRoomModal() {
    const { data, role } = await this.showSelectModal({
      title: 'Select Room',
      emptyMessage: 'No rooms available right now.',
      key: 'id',
      displayName: 'roomName',
      value: this.assetDetails?.room?.roomId || '',
      list: this.rooms,
      isSave: true,
    });
    if (role === 'confirm') {
      this.assetDetailsForm?.markAsDirty();
      if (data !== '') {
        const room = this.rooms.find((item: any) => item.id === data);
        if (room) {
          this.assetDetailsForm?.controls['roomName'].patchValue(room.roomName);
          this.assignRoomToAssets(data);
        }
      } else {
        this.assetDetailsForm?.controls['roomName'].patchValue(null);
        this.removeRoomToAssets();
      }
    }
  }

  async setRoom() {
    if (this.assetId && this.isReadMode) {
      if (this.f['roomName']?.value && this.assetDetails?.room?.roomId && this.isRoomSummaryEnabled) {
        this.nav.push(RoomDetailsPage, { id: this.assetDetails?.room?.roomId });
      }
      return;
    }
    if (this.rooms.length) {
      this.didSelectRoomModal();
    } else {
      await this.uiUtilityService.showLoader();
      this.settingsService
        .getRoomListByFacilityId()
        .subscribe((response: any) => {
          if (response && response.success && response.data) {
            const none = {
              id: '',
              roomName: 'None',
            };
            this.rooms = response.data?.length ? [none, ...response.data] : [];
            this.didSelectRoomModal();
          } else {
            const header = 'Error';
            this.uiUtilityService.showAlert(response.errorMessage!, header);
          }
        });
    }
  }

  async manageAsset() {
    if (this.assetId && this.isReadMode) {
      this.nav.push(AssetDetailsPage, { assetId: this.assetId, mode: 'edit' });
      return;
    }
    if (this.assetDetailsForm?.invalid) {
      return;
    }
    const payload = this.assetDetailsForm?.value;
    let apiRef: any;
    if (this.assetId) {
      payload.assetId = this.assetId;
      apiRef = this.assetService.updateAsset(payload);
    } else {
      apiRef = this.assetService.createAsset(payload);
    }
    await this.uiUtilityService.showLoader();
    apiRef.subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.uiUtilityService.showToast(
          'The asset details saved successfully.'
        );
        if (!this.assetId && response.data?.assetId) {
          this.assetDetailsForm?.markAsPristine();
          this.didOnInit(response.data.assetId, 'detail');
          this.didViewWillEnter();
        } else {
          this.navigateBack();
        }
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateBack(isRestrict = false) {
    if (!(this.assetId && isRestrict && !this.assetDetailsForm?.pristine)) {
      this.nav.pop();
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.dashboardService.refreshDashboardWithData.next(true);
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    }
  }

  onNavBack() {
    if (this.isRootPage) {
      this.dismiss();
    } else {
      this.nav.pop();
    }
  }

  onConfirmDeleteAsset = async () => {
    await this.uiUtilityService.showLoader();
    this.assetService.deleteAsset(this.assetId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.onNavBack();
        this.uiUtilityService.showToast('The asset deleted successfully.');
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  };

  deleteAsset() {
    const message =
      'You are about to delete this asset. This action cannot be undone.';
    const header = 'Delete This Asset';
    this.uiUtilityService.presentConfirm(
      message,
      this.onConfirmDeleteAsset,
      undefined,
      header,
      'Delete'
    );
  }

  getConfig(config: UnifiedInput) {
    return { ...config };
  }

  get f() {
    return this.assetDetailsForm?.controls || {};
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
