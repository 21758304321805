import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonNav, IonicModule } from '@ionic/angular';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { DownloadsPage } from '../downloads/downloads.page';

@Component({
  standalone: true,
  selector: 'app-help',
  templateUrl: './help.page.html',
  styleUrls: ['./help.page.scss'],
  imports: [CommonModule, IonicModule],
})
export class HelpPage implements OnInit {
  constructor(private uiUtilityService: UiUtilityService, private nav: IonNav) { }

  ngOnInit() {
    this.uiUtilityService.showLoader();
  }

  onLoad() {
    this.uiUtilityService.closeLoader();
  }

  navigateToDownloadsPage() {
    this.nav.push(DownloadsPage);
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }
}
