export class RoomListModel {
  id = '';
  floorId = '';
  floorName = '';
  roomTypeName = '';
  subTypeName = '';
  roomName = '';
  startDate = '';
  endDate = '';
  createdBy = '';
  createDate = '';
  updateDate = '';
  updatedBy = '';
}
