<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button (click)="close()"><ion-icon slot="icon-only" name="close"></ion-icon></ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="save()">Save</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container *ngFor="let group of groups">
    <ng-container [ngSwitch]="group.controlType">
      <ion-list-header class="group-header">
        <ion-label class="group-header-label">
          <h5 class="font-bold">{{group.label}}</h5>
        </ion-label>
      </ion-list-header>
      <ng-container *ngSwitchCase="controlType.RADIO">
        <ion-radio-group [value]="group.value" (ionChange)="onChange($event, group)">
          <ion-item *ngFor="let field of group.fields">
            <ion-radio color="controls" justify="start" labelPlacement="end" mode="md"
              [value]="field.value">{{field.label}}</ion-radio>
          </ion-item>
        </ion-radio-group>
      </ng-container>
      <ng-container *ngSwitchCase="controlType.TOGGLE">
        <ion-list>
          <ion-item *ngFor="let field of group.fields">
            <ion-toggle mode="ios" color="controls" [checked]="group.value.indexOf(field.value) !== -1"
              (ionChange)="onChange($event, group, field)">{{field.label}}</ion-toggle>
          </ion-item>
          <ion-item *ngIf="!group.fields.length && loadingCounter">
            <ion-spinner></ion-spinner>
          </ion-item>
        </ion-list>
      </ng-container>
    </ng-container>
  </ng-container>
</ion-content>