import { AnimationController } from '@ionic/angular';

export function enterAnimation(baseEl: HTMLElement) {
  const root = baseEl.shadowRoot!;

  const animationCtrl = new AnimationController();

  const backdropAnimation = animationCtrl
    .create()
    .addElement(root.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  const wrapperAnimation = animationCtrl
    .create()
    .addElement(root.querySelector('.modal-wrapper')!)
    .fromTo('transform', 'translateX(-100%)', 'translateX(0)');

  return animationCtrl
    .create()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(150)
    .addAnimation([backdropAnimation, wrapperAnimation]);
}

export function leaveAnimation(baseEl: HTMLElement) {
  return enterAnimation(baseEl).direction('reverse');
}
