<ion-header>
  <ion-toolbar class="non-modal-toolbar">
    <ion-title class="ion-text-center logo-header">
      <img height="35" src="assets/icon/title_{{tenant}}.svg">
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="login-container">
    <form [formGroup]="loginForm" (ngSubmit)="onLogIn()" (keyup.enter)="handleKeyEnter($event)" novalidate>

      <div class="login-title">Sign In</div>
      <div class="form-field">
        <div class="input-label">User ID</div>
        <ion-input id="userid" formControlName="userid" autocomplete="userid" placeholder="Enter your user ID"
          class="border-input" [ngClass]="{ 'is-invalid': submitted && f['userid'].errors }">
        </ion-input>
      </div>

      <ion-text class="form-field" color="danger">
        <div [hidden]="!(f['userid'].dirty && !f['userid'].valid)" class="error-msg">
          User ID is required
        </div>
      </ion-text>

      <div class="form-field">
        <div class="input-label">Password</div>
        <ion-input id="password" [type]="passwordType" formControlName="password" placeholder="Enter your password"
          class="border-input" (ionBlur)="onPasswordBlur()"
          [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
        </ion-input>
        <ion-icon class="passwordIcon" (click)="togglePasswordVisibility()" [name]="passwordIcon"></ion-icon>
      </div>

      <ion-text class="form-field" color="danger">
        <div [hidden]="!(f['password'].dirty && !f['password'].valid)" class="error-msg">
          Password is required
        </div>
      </ion-text>

      <div class="forgot-pwd app-link" routerLink="/forgot-password">
        Forgot ID or password?
      </div>
      <ion-row>
        <ion-col class="ion-no-padding">
          <ion-button class="enter-btn" type="submit" expand="block" [disabled]="loginForm.invalid"> Enter
          </ion-button>
        </ion-col>
      </ion-row>
    </form>

    <div class="login-instruction ion-text-center">
      {{contactUs}}
    </div>
    <div class="app-version">
      v{{appVersion}}
    </div>
  </div>
</ion-content>