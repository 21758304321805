import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { BandEnum, BandPageFlowEnum } from 'src/app/shared/enums/band.enum';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { Band, BandStatus } from 'src/app/shared/models/wearer.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BandAssociationService {
  bandFlow = '';
  bandRssi = -1000;
  bandStatus: BandStatus | undefined;

  constructor(
    private router: Router,
    private uiUtilityService: UiUtilityService,
    private dataService: DataService<any>,
    private headerService: HeaderService
  ) { }

  resetBandStatus() {
    this.bandStatus = new BandStatus();
  }

  /**
   * parse hexa decimal data to decimal no
   * @param buffers -
   */
  parseAdvertisingData(buffer: any) {
    let length,
      type,
      data,
      i = 0,
      advertisementData = {};
    const bytes = new Uint8Array(buffer);

    while (length !== 0) {
      // tslint:disable-next-line: no-bitwise
      length = bytes[i] & 0xff;
      i++;
      // decode type constants
      // tslint:disable-next-line: no-bitwise
      type = bytes[i] & 0xff;
      i++;
      data = bytes.slice(i, i + length - 1).buffer; // length includes type byte, but not length byte
      i += length - 2; // move to end of data
      i++;
      (advertisementData as any)[this.asHexString(type)] = data;
    }

    return advertisementData;
  }

  /**
   * Convert data to hex string
   * @param i -
   */
  asHexString(i: any) {
    let hex;
    hex = i.toString(16);
    // zero padding
    if (hex.length === 1) {
      hex = '0' + hex;
    }
    return '0x' + hex;
  }

  /**
   * Show alert message
   * @param message -
   * @param header -
   */
  async showAlert(message: string, header: string) {
    const buttons = [
      {
        text: 'ok',
        handler: () => {
          this.router.navigate([RouterEnum.bandIntro], {
            queryParams: { flow: this.bandFlow },
          });
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  /**
   * Fetch manufacturer data from ble advertisement
   * @param wearerBandData -
   */
  fetchManufacturerData(wearerBandData: any, platform: string): any {
    let manufacturerData: any = null;
    if (platform === 'ios') {
      const mfgData =
        wearerBandData.advertising &&
        wearerBandData.advertising.kCBAdvDataManufacturerData;
      if (mfgData) {
        manufacturerData = new Uint8Array(mfgData);
      }
    } else {
      const SERVICE_DATA_KEY = '0xff';
      const advertisingData = this.parseAdvertisingData(
        wearerBandData.advertising
      );
      const serviceData: any = (advertisingData as any)[SERVICE_DATA_KEY];
      if (serviceData) {
        manufacturerData = new Uint8Array(serviceData);
      }
    }
    return manufacturerData;
  }

  get isBandAssociationFlow() {
    return this.bandFlow === BandPageFlowEnum.WearerAssociation;
  }

  bandFlowTitle(bandFlow: string) {
    switch (bandFlow) {
      case BandPageFlowEnum.WearerAssociation:
        return BandEnum.BandAssociation;
      case BandPageFlowEnum.AssetAssociation:
        return BandEnum.TagAssociation;
      case BandPageFlowEnum.AssetOndoTagAssociated:
        return BandEnum.OndoTag;
      default:
        return BandEnum.BandLookup;
    }
  }

  checkBandAvailability(bandIds: string): Observable<ApiResponseModel<Band[]>> {
    const url = `${environment.apiUrl.band}${FACILITY_ID_URL}/${EndPointsEnum.checkAvailability}/${bandIds}`;
    return this.dataService.getData(url);
  }

  checkBandStatus(bandId: string): Observable<ApiResponseModel<BandStatus>> {
    const url = `${environment.apiUrl.band}${FACILITY_ID_URL}/${EndPointsEnum.bandLookUp}/${bandId}`;
    return this.dataService.getData(url);
  }
}
