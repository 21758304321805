import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource,  } from '@capacitor/camera';
import { Platform } from '@ionic/angular';
import { IFloorImage } from './../interfaces/plan-image.interface';
@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  public floorImages: IFloorImage[] = [];
  constructor(private platform: Platform) { }
  public async getPhotoFromNative(isCamera: any) {
    const isAndroid = this.platform.is('android');
    const image = await Camera.getPhoto({
      quality: isAndroid ? 100 : 85,
      allowEditing: !isAndroid,
      resultType: CameraResultType.DataUrl,
      width: 300,
      height: 300,
      // preserveAspectRatio: true,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos,
      correctOrientation: true,
    });
    return image;
  }
}
