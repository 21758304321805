import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { environment } from 'src/environments/environment';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';

@Injectable({
  providedIn: 'root',
})
export class BridgeService {

  constructor(
    private dataService: DataService<any>
  ) { }

  getBridgeList(): Observable<ApiResponseModel<any[]>> {
    const url = `${environment.apiUrl.bridgeManagement}${FACILITY_ID_URL}/getBridgeList`;
    return this.dataService.getData(url);
  }

  getBridgeDetails(
    id: string
  ): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.bridgeManagement}${id}/getBridgeDetail`;
    return this.dataService.getData(url);
  }

  createBridge(payload: any): Observable<any> {
    const url = `${environment.apiUrl.bridgeManagement}manage`;
    return this.dataService.post(url, payload);
  }

  updateBridge(payload: any): Observable<any> {
    const url = `${environment.apiUrl.bridgeManagement}manage`;
    return this.dataService.put(url, payload);
  }

  deleteBridge(payload: any): Observable<any> {
    const url = `${environment.apiUrl.bridgeManagement}manage`;
    return this.dataService.delete(url, payload);
  }

  getBridgePlacementList(): Observable<any> {
    const url = `${environment.apiUrl.bridgeManagement}${FACILITY_ID_URL}/getBridgePlacementList`;
    return this.dataService.getData(url);
  }

  getBedList(): Observable<ApiResponseModel<any[]>> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/getAvailableBedsForBridge`;
    return this.dataService.getData(url);
  }

  getFacilityFloorsList(): Observable<ApiResponseModel<any[]>> {
    const url = `${environment.apiUrl.managefloors}${FACILITY_ID_URL}/getFacilityFloorsList`;
    return this.dataService.getData(url);
  }

  updatePlacement(bridgeId: string, payload: any): Observable<any> {
    const url = `${environment.apiUrl.bridgeManagement}${bridgeId}/managePlacement`;
    return this.dataService.post(url, payload);
  }
}
