<app-unified-header [title]="addRoomLabel" (onSave)="editRoomEnable === true ? updateRoomData() : addRoomData()"
  defaultHref="/room-list" [restrictBack]="addRoomForm && !addRoomForm.pristine"
  [saveBtn]="{title: 'Save', isDisable: isSaveDisabled}"></app-unified-header>

<ion-content [fullscreen]="true">
  <form [formGroup]="addRoomForm">
    <!-- <ion-header collapse="condense">
      <ion-toolbar>
        <ion-title size="large">Room Details</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <ion-item button (click)="presentAlert('roomName')">
      <ion-label>Name</ion-label>
      {{editRoomEnable === true ? roomDetails.roomDetail?.roomName : room}}
    </ion-item>
    <ion-item button (click)="openSelectionList()">
      <ion-label>Floor</ion-label>
      {{editRoomEnable === true ? roomDetails.roomDetail?.floorName : floor}}
    </ion-item>
    <ion-item button (click)="openRoomSelectionList()" [disabled]="editRoomEnable">
      <ion-label>Room Type</ion-label>
      {{editRoomEnable === true ? roomDetails.roomDetail?.roomTypeName : type}}
    </ion-item>
    <ion-item button (click)="openSubtype()" *ngIf="enableSubtype">
      <ion-label>Subtype</ion-label>
      {{editRoomEnable === true ? roomDetails.roomDetail?.subTypeName : subTypeRoom}}
    </ion-item>
    <ion-select #floorList (ionChange)='setFloor($event)' value="{{floorIDValue}}" label="Floor" header="Floor"
      [multiple]="false" [hidden]='showList'>
      <ion-select-option *ngFor="let floor of addFloorFormData" [value]="floor.floorID">{{ floor.floorName
        }}</ion-select-option>
    </ion-select>
    <ion-select #roomType (ionChange)='setRoomType($event)' label="Room Type" header="Room Type" [multiple]="false"
      [hidden]='showList'>
      <ion-select-option *ngFor="let rooms of roomOptions" [value]="rooms">{{ rooms.name }}</ion-select-option>
    </ion-select>
    <ion-select *ngIf="enableSubtype" label="Sub Type" #subRoomType (ionChange)='setSubRoomType($event)'
      header="Subtype" value="{{roomDetails?.roomDetail?.subTypeName}}" [multiple]="false" [hidden]='showList'>
      <ion-select-option *ngFor="let subType of subType" [value]="subType.subTypeName">{{ subType.subTypeName
        }}</ion-select-option>
    </ion-select>

    <!-- <ion-item [routerLink]="'floor-plan-locator'">
    <label>Place on floor plan</label>
    
  </ion-item> -->
  </form>
</ion-content>