<ion-toolbar class="sub-beader">
  <ion-icon class="all-floor" slot="start" src="assets/svg/all-floor.svg"></ion-icon>
  <ion-label>{{allFloorSelected ? floorTitle : selectedFloors}}</ion-label>
  <ion-buttons slot="end">
    <ion-button class="filter-btn" (click)="openFilter()">
      <ion-icon slot="icon-only"
        [src]="isFilterApplied ? 'assets/icon/icons_Sort-Filter_Filtered.svg' : 'assets/icon/icons_Sort-Filter.svg'"
        color="secondary"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>