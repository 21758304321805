import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { AuthRequestModel } from 'src/app/shared/models/auth-request.model';
import { LoginModel } from 'src/app/shared/models/login.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private dataService: DataService<any>) {}

  authenticate(
    authRequestModel: AuthRequestModel
  ): Observable<ApiResponseModel<LoginModel>> {
    const authUrl = `${environment.apiUrl.auth}${EndPointsEnum.login}`;
    const authenticateUser: Observable<any> = this.dataService.post(
      authUrl,
      authRequestModel
    );
    return authenticateUser;
  }

  login(username: string) {
    const authUrl = `${environment.apiUrl.user}${EndPointsEnum.loginResponse}?userId=${username}`;
    return this.dataService.getData(authUrl);
  }
}
