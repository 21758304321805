export enum BandEnum {
  Association = 'association',
  BandAssociation = 'Band/Tag Association',
  BandLookup = 'Band/Tag Lookup',
  TagAssociation = 'Band/Tag Association',
  AssociationComplete = 'Association Complete',
  AssociatedBand = 'Associated Band',
  AssociatedWearerFound = 'Associated Wearer Found',
  AssociatedElsewhere = 'Associated Elsewhere',
  NotAssociated = 'Not Associated',
  PrevPage = 'prevPage',
  OndoTag = 'ONDO Tag',
  OndoBand = 'ONDO Band',
  OndoBandTag = 'ONDO Band/Tag',
}

export enum BandPageFlowEnum {
  AssetAssociation = 'asset_management',
  WearerAssociation = 'wearer_association',
  BandLookup = 'band_lookup',
  AssetOndoTagAssociated = 'asset_tag_associated',
}
