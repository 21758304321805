import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IonNav, IonicModule, MenuController, NavParams } from '@ionic/angular';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { gracePeriodOptions } from 'src/app/shared/config/grace-period.constants';
import {
  defaultTempUnit,
  tempUnit,
  tempUnitIds,
} from 'src/app/shared/config/temp-unit.constant';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { HttpRequestTypeEnum } from 'src/app/shared/enums/http-request-type.enum';
import { AlertButtonModel } from 'src/app/shared/models/alertButtonModel';

import {
  WearerDetailsModel,
  WearerGroup,
} from 'src/app/shared/models/wearer.model';
import { environment } from 'src/environments/environment';
import { WearerService } from '../../services/wearer.service';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { wearerDetailsInputConfig } from './wearer-details-input.config';
import { UnifiedInput } from 'src/app/shared/components/unified-custom-input/unified-input.types';
import { UnifiedCustomInputComponent } from 'src/app/shared/components/unified-custom-input/unified-custom-input.component';
import { cloneDeep } from 'lodash-es';
import { BandAssociationService } from 'src/app/features/band/services/band-association.service';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { BandEnum, BandPageFlowEnum } from 'src/app/shared/enums/band.enum';
import { SelectBedComponent } from 'src/app/features/bed-management/pages/select-bed/select-bed.component';
import { SelectRoomPage } from '../select-room/select-room.page';
import { BedDetailsPage } from 'src/app/features/bed-management/pages/bed-details/bed-details.page';
import { RoomDetailsPage } from 'src/app/features/settings/room-management/room-details/room-details.page';
import { AssignDevicePage } from 'src/app/features/band/pages/assign-device/assign-device.page';
import { BandCompletionPage } from 'src/app/features/band/pages/band-completion/band-completion.page';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { Capacitor } from '@capacitor/core';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
@Component({
  standalone: true,
  selector: 'app-wearer-details',
  templateUrl: './wearer-details.page.html',
  styleUrls: ['./wearer-details.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedCustomInputComponent,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WearerDetailsPage implements OnInit, OnDestroy {
  wearerId: string = '';
  isRootPage: boolean = false;
  destroyRef = inject(DestroyRef);
  wearerDetails: WearerDetailsModel | null = null;
  wearerDetailsForm: FormGroup | null = null;
  isSaveDisabled = true;
  isEditEnable = false;
  gracePeriodOptions = gracePeriodOptions;
  alertThresholdOption: Map<number, number> | null = null;
  subscriptions: Subscription = new Subscription();

  tempUnitId = defaultTempUnit;
  tempUnit = tempUnit;
  tempUnitIds = tempUnitIds;
  isAsset: boolean = false;
  storedGroups: WearerGroup[] = [];
  roomSwitchTriggered: boolean = false;
  wearerDetailsInputConfig: UnifiedInput[] = cloneDeep(
    wearerDetailsInputConfig
  );
  roomDisabled = false;
  enableShowAlert = false;
  wearerStatus = [
    { name: 'Active', value: false },
    { name: 'Inactive', value: true },
  ];
  showRoom: boolean = false;
  backButtonClicked: boolean = false;
  isNative = Capacitor.isNativePlatform();
  isRoomSummaryEnabled = true;
  isBedSummaryEnabled = true;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private wearerService: WearerService,
    private formBuilder: FormBuilder,
    private dataService: DataService<WearerDetailsModel>,
    private uiUtilityService: UiUtilityService,
    private bandService: BandAssociationService,
    private authService: AuthService,
    private headerService: HeaderService,
    private dashboardService: DashboardService,
    private cdRef: ChangeDetectorRef,
    private navParams: NavParams,
    private nav: IonNav,
    private menuCtrl: MenuController
  ) {
  }

  getSingularFormWearerGroup(groupName: string) {
    return this.dashboardService.getSingularFormWearerGroup(groupName);
  }

  checkIfAsset() {
    this.isAsset = this.wearerDetailsForm?.get('wearerGroupId')?.value === '3';
  }

  ngOnInit(): void {
    this.wearerId = this.navParams.get('id') || this.wearerId;
    this.isSaveDisabled = true;
    this.isEditEnable = this.navParams.get('mode') === 'edit' ? true : false;
    this.isRoomSummaryEnabled = this.authService.isFeatureEnabled('getRoomDetail');
    this.isBedSummaryEnabled = this.authService.isFeatureEnabled('getBedDetail');
  }

  ionViewWillEnter() {
    const loginData = this.authService.getLoginData;
    if (loginData.tempUnit) {
      this.tempUnitId = loginData.tempUnit;
    }
    this.checkForAddWearer().then((wearer) => {
      this.wearerDetails = wearer;
      if (this.wearerDetails.residentDetail.isDeleted == false) {
        this.roomDisabled = false;
      } else {
        this.roomDisabled = true;
      }
      this.createForm();
      this.checkIfAsset();
      this.wearerDetailsForm?.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.checkIfAsset();
          if (this.wearerDetailsForm) {
            this.isSaveDisabled =
              this.wearerDetailsForm.invalid || !this.wearerDetailsForm.dirty;
            if (
              this.wearerDetailsForm.value.firstName ||
              this.wearerDetailsForm.value.lastName ||
              this.wearerDetailsForm.value.localId
            ) {
              this.isSaveDisabled = false;
            } else {
              this.isSaveDisabled = true;
            }
          }
        });
    });
  }

  async checkForAddWearer(): Promise<WearerDetailsModel> {
    const facilityId = this.headerService.getFacilityId();
    const promise: Promise<WearerDetailsModel> = new Promise((resolve) => {
      if (!this.wearerId) {
        this.wearerService.getWearerGroups(facilityId).subscribe((response) => {
          if (response.success && response.data) {
            this.storedGroups = response.data;
            this.wearerDetailsInputConfig.map((config) => {
              config.detail = true;
            });
          }
          resolve(new WearerDetailsModel());
        });
      } else {
        forkJoin({
          wearer: this.wearerService.getWearerDetails(this.wearerId),
          wearerGroups: this.wearerService.getWearerGroups(facilityId),
        }).subscribe(
          (response: {
            wearer: ApiResponseModel<WearerDetailsModel>;
            wearerGroups: any;
          }) => {
            this.storedGroups = response.wearerGroups.data as any[];
            if (response.wearer.success) {
              this.wearerDetailsInputConfig.map((config) => {
                config.detail = this.isEditEnable;
                if (response.wearer.data && config.name) {
                  if (response.wearer.data.rooms) {
                    this.showRoom = true;
                  } else {
                    this.showRoom = false;
                  }
                  config.value = (response.wearer.data.residentDetail as any)[
                    config.name
                  ];
                }
              });
              resolve(response.wearer.data || new WearerDetailsModel());
            } else {
              resolve(new WearerDetailsModel());
            }
          }
        );
      }
    });
    return promise;
  }

  createForm() {
    this.wearerDetailsForm = this.formBuilder.group({
      firstName: [
        this.wearerDetails?.residentDetail.firstName,
        [Validators.required],
      ],
      lastName: [
        this.wearerDetails?.residentDetail.lastName,
        [Validators.required],
      ],
      localId: [this.wearerDetails?.residentDetail.localId],
      wearerGroupId: [
        {
          value: this.wearerId
            ? this.wearerDetails?.residentDetail.wearerGroupId
            : this.storedGroups[0]?.id,
          disabled: this.wearerId,
        },
        [Validators.required],
      ],
      wearerStatus: [
        {
          value: this.wearerId
            ? this.wearerDetails?.residentDetail.isDeleted
            : false,
          disabled: this.isReadMode,
        },
        [Validators.required],
      ],
    });
    this.cdRef.detectChanges();
  }

  inputChanged(inputConfig: UnifiedInput) {
    if (this.wearerDetailsForm && inputConfig.name) {
      this.wearerDetailsInputConfig.map((config) => {
        if (config.name === inputConfig.name) {
          config.value = inputConfig.value;
        }
      });
      this.wearerDetailsForm.markAsDirty();
      this.wearerDetailsForm.controls[inputConfig.name].patchValue(
        inputConfig.value
      );
      this.cdRef.detectChanges();
    }
  }

  getControl(controlName: string): AbstractControl | null {
    return this.wearerDetailsForm && this.wearerDetailsForm.get(controlName);
  }

  changeStatus(event: any) {
    if (this.wearerDetails?.residentDetail.isDeleted == true) {
      this.enableShowAlert = true;
    } else {
      this.enableShowAlert = false;
    }
    if (event.detail.value == true) {
      this.roomDisabled = true;
    } else {
      this.roomDisabled = false;
      this.enableShowAlert = true;
    }
  }

  get isBandIdVisible() {
    return (
      this.getControl('firstName')?.value && this.getControl('lastName')?.value
    );
  }

  async findBand(device?: any): Promise<void> {
    if (!this.isNative && !device) {
      return;
    }
    const bandId = device?.bandId || device?.tagId;
    this.wearerService.activeWearerDetails.residentDetail.firstName =
      this.getControl('firstName')?.value;
    this.wearerService.activeWearerDetails.residentDetail.lastName =
      this.getControl('lastName')?.value;
    this.wearerService.activeWearerDetails.residentDetail.id = this
      .wearerId as string;
    this.wearerService.activeWearerDetails.rooms =
      this.wearerDetails?.rooms! || [];
    this.wearerService.activeWearerDetails.bands =
      this.wearerDetails?.bands! || [];
    this.bandService.bandFlow = BandEnum.Association;
    let returnBackIndex = 0;
    for (let i = 0; i < 100; i++) {
      const activeNav = await this.nav.getByIndex(i);
      if (activeNav?.element?.localName === 'app-wearer-details') {
        returnBackIndex = i;
        break;
      }
    }
    if (bandId) {
      this.nav.push(BandCompletionPage, { bandId, details: this.wearerDetails, bandInfo: device, returnBackIndex });
    } else {
      this.nav.push(AssignDevicePage, {
        flow: BandPageFlowEnum.WearerAssociation,
        details: this.wearerDetails,
        returnBackIndex,
        isReplaceRemoveEnabled: false
      });
    }
  }

  toggleChanged() {
    if (!this.getControl('location')?.value) {
      this.getControl('locationAlert')?.setValue(false);
      this.getControl('gracePeriod')?.setValue(10);
    }
    if (!this.getControl('locationAlert')?.value) {
      this.getControl('gracePeriod')?.setValue(10);
    }
  }

  async showAlert(header: string, message: string) {
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
        handler: () => {
          this.stopGoingBack();
        },
      },
      {
        text: 'ok',
        handler: () => {
          if (
            !this.isReadMode &&
            !this.roomDisabled &&
            !this.backButtonClicked
          ) {
            this.roomSwitchTriggered = true;
            this.manageWearer('put');
          } else {
            this.navigateBack();
          }
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  roomSwitch() {
    if (
      this.wearerDetails?.rooms?.[0]?.id == undefined &&
      this.enableShowAlert
    ) {
      this.showAlert(
        'Important',
        'This action will save your previous changes'
      );
    } else {
      this.goToSelectRoomPage();
    }
  }

  navigateToRoomDetails() {
    if (this.wearerDetails?.rooms[0] && this.isRoomSummaryEnabled) {
      this.nav.push(RoomDetailsPage, {
        roomId: this.wearerDetails.rooms[0].id,
      });
    }
  }

  selectBed() {
    if (
      this.wearerDetails?.rooms?.[0]?.id == undefined &&
      this.enableShowAlert
    ) {
      this.showAlert(
        'Important',
        'This action will save your previous changes'
      );
    } else {
      const queryObject = {
        roomId: this.wearerDetails?.rooms?.[0]?.id,
        wearerId: this.wearerId,
      };
      this.nav.push(SelectBedComponent, queryObject);
      this.roomSwitchTriggered = false;
    }
  }

  goToSelectRoomPage() {
    let queryObject: any;
    queryObject = {
      roomId: this.wearerDetails?.rooms?.[0]?.id,
      wearerId: this.wearerId,
    };
    this.nav.push(SelectRoomPage, queryObject);
    this.roomSwitchTriggered = false;
  }

  navigateToBedDetails() {
    if (!this.isBedSummaryEnabled) {
      return;
    }
    let bedID = this.wearerDetails?.beds?.[0]?.id;
    if (bedID) {
      this.nav.push(BedDetailsPage, { bedId: bedID, mode: 'detail' });
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.dashboardService.refreshDashboardWithData.next(true);
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    }
  }

  onNavBack() {
    if (this.isRootPage) {
      this.dismiss();
    } else {
      this.nav.pop();
    }
  }

  async manageWearer(operationType: string) {
    const facilityId = this.headerService.getFacilityId();
    const manageWearerUrl = `${environment.apiUrl.residentManagement}${FACILITY_ID_URL}/${EndPointsEnum.manage}`;
    const wearerData = this.wearerService.getManageWearerRequestData(
      this.wearerDetailsForm,
      this.wearerId,
      !this.wearerId
        ? (this.wearerDetails?.residentDetail.facilityId as string)
        : facilityId
    );
    let manageApiObservable: Observable<any> = new Observable();
    switch (operationType) {
      case HttpRequestTypeEnum.post:
        manageApiObservable = this.dataService.post(
          manageWearerUrl,
          wearerData
        );
        break;
      case HttpRequestTypeEnum.put:
        manageApiObservable = this.dataService.put(manageWearerUrl, wearerData);
        break;
      case HttpRequestTypeEnum.delete:
        manageApiObservable = this.dataService.delete(
          manageWearerUrl,
          wearerData
        );
        break;
    }
    await this.uiUtilityService.showLoader();
    manageApiObservable.subscribe((response: ApiResponseModel<any>) => {
      if (response && response.success) {
        if (operationType === HttpRequestTypeEnum.delete) {
          this.onNavBack();
          return;
        }
        if (this.roomSwitchTriggered) {
          this.goToSelectRoomPage();
        }
        if (!this.wearerId && response.data?.id) {
          this.wearerDetailsForm?.markAsPristine();
          this.wearerId = response.data.id;
          // this.isEditEnable = true;
          this.ionViewWillEnter();
        } else {
          this.navigateBack();
        }

      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(
          response?.errorMessage as string,
          header
        );
        this.wearerService.activeWearerDetails = new WearerDetailsModel();
      }
      this.cdRef.detectChanges();
    });
  }

  navigateBack() {
    this.wearerService.activeWearerDetails = new WearerDetailsModel();
    this.nav.pop();
  }

  stopGoingBack() {
    if (this.wearerDetailsForm && !this.wearerDetailsForm.pristine) {
    } else {
      this.nav.pop();
    }
  }

  backClick() {
    if (this.wearerDetailsForm && !this.wearerDetailsForm.pristine) {
      this.backButtonClicked = true;
      const header = 'Confirm';
      const message =
        'It looks like you have been editing something. ' +
        'If you leave before saving, your changes will be lost.';
      this.showAlert(header, message);
    } else {
      this.navigateBack();
    }
  }

  deleteWearer() {
    const header = 'Delete This Wearer';
    const message =
      'You are about to delete this wearer. This action cannot be undone.';
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
      },
      {
        text: 'Delete',
        handler: () => {
          this.manageWearer(HttpRequestTypeEnum.delete);
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  assetDefaultLocationOn() {
    if (this.isAsset) {
      this.wearerDetailsForm?.get('location')?.setValue(true);
    }
    //  else {
    //   this.wearerDetailsForm.get('location').setValue(this.wearerDetails.location);
    // }
  }

  setEditMode(mode: boolean) {
    // this.isEditEnable = mode;
    // this.wearerDetailsInputConfig.map(
    //   (config) => (config.detail = this.isEditEnable)
    // );
    this.nav.push(WearerDetailsPage, { id: this.wearerId, mode: 'edit' });
  }

  getConfig(config: UnifiedInput) {
    return { ...config };
  }

  get isReadMode() {
    return !!this.wearerId && !this.isEditEnable;
  }

  get isResident() {
    const selected = this.getControl('wearerGroupId')?.value;
    const wearerGroupName = this.storedGroups.find((item: any) => item.id === selected)?.wearerGroupName;
    return wearerGroupName?.toLowerCase()?.indexOf('resident') !== -1;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.alertThresholdOption = null;
  }
}
