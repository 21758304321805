<app-unified-header [title]="!userId ? 'Add User Account' : isReadMode ? 'User Account Details' : 'Edit User Account'"
  (onSave)="manageUser()" defaultHref="/user-accounts" [restrictBack]="userDetailsForm && !userDetailsForm.pristine"
  [saveBtn]="{title: userId && isReadMode ? 'Edit' : 'Save', isDisable: userId && isReadMode ? false : isSaveDisabled, rule: userId ? 'putUser' : ''}"></app-unified-header>
<ion-content *ngIf="userDetailsForm">
  <form [formGroup]="userDetailsForm">
    <ng-container *ngFor="let config of addAccountDetailsInputConfig">
      <unified-input ngDefaultControl [formControlName]="config.name || null" [config]="getConfig(config)"
        (changed)="inputChanged($event)"></unified-input>
    </ng-container>
    <br>
    <ion-list-header>
      <ion-label>User Settings</ion-label>
    </ion-list-header>
    <ion-item [detail]="!isReadMode || !userId" [ngClass]="{'interactive': !isReadMode || !userId}"
      (click)="setUserRole()">
      <ion-label>User Role</ion-label>
      <ion-text slot="end" [class.none-value]="!f['userRoleName']?.value">{{f['userRoleName']?.value ||
        'None'}}</ion-text>
    </ion-item>
    <ion-item [detail]="!isReadMode || !userId" [ngClass]="{'interactive': !isReadMode || !userId}"
      (click)="setFacility()" *ngIf="isFacilityRoleSelected">
      <ion-label>Facility</ion-label>
      <ion-text slot="end" [class.none-value]="!f['userFacilityName']?.value">{{f['userFacilityName']?.value ||
        'None'}}</ion-text>
    </ion-item>
  </form>
  <div class="delete-account" *ngIf="userId && isReadMode">
    <ion-button color="tertiary" (click)="resetPassword()">Reset Password</ion-button>
  </div>
  <div class="delete-account" *ngIf="userId && isReadMode">
    <ion-button color="danger" (click)="deleteUser()" [disabled]="isCurrentUser" [nAccess]="'deleteUser'">Delete This
      User</ion-button>
  </div>
</ion-content>