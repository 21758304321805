<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button *ngIf="canGoBack && !isBandLookUpFlow" (click)="navigateBack()"></ion-back-button>
      <ion-button *ngIf="isBandLookUpFlow" class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button *ngIf="isBandLookUpFlow" class="header-modal-back-btn" defaultHref="/dashboard"
        (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">{{title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="row">
    <ol>
      <li>Press the hidden button behind the "O" of ONDO on the Band or Tag.</li>
    </ol>
  </div>
  <div class="img-row">
    <ion-img class="band-img" [src]='"/assets/images/ODR_Tag-Band.png"' slot="center"> </ion-img>
  </div>
  <div class="row">
    <ol start="2">
      <li>Once you see the LED blinking once, tap [Start] below.</li>
    </ol>
  </div>
  <div class="row">
    <div class="col start-btn">
      <div class="instruction-text" *ngIf="!bluetoothEnabled">
        <span>Please turn on Bluetooth on your device.</span>
      </div>
      <ion-button (click)="onStart()" queryParamsHandling="preserve" [disabled]="!bluetoothEnabled">Start</ion-button>
    </div>

    <div class="instruction-text">
      <ion-icon src="assets/icon/icons_Hint.svg"></ion-icon>
      Do not press the button on other Bands or Tags you have nearby.
    </div>
  </div>
</ion-content>