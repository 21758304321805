import { warning } from '../config/bar-color.constant';

export enum colorClasses {
    ok = 'ok',
    offline = 'offline',
    warning = 'warning',
    caution = 'caution',
    lbalck = 'header-black'
}

export enum notableColors {
    offline = '#BBBBBB',
    warning = '#D34626',
    caution = '#FFAA00',
    greenOk = '#00A980',
    blueOk = '#009DDC',
    darkGrey = '#4D4D4D',
    stateless = '#6D88B4'
}