import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { WearerService } from 'src/app/features/wearer-management/services/wearer.service';
import { BandEnum, BandPageFlowEnum } from 'src/app/shared/enums/band.enum';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { UserRolesEnum } from 'src/app/shared/enums/user-roles.enum';
import { LoginResponseModel } from 'src/app/shared/models/login-response.model';
import {
  AssignBandToWearer,
  BandStatus,
} from 'src/app/shared/models/wearer.model';
import { BandAssociationService } from '../../services/band-association.service';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { TemperatureThreshold } from 'src/app/shared/enums/temp-alert-threshold';
import { StorageService } from 'src/app/core/services/storage.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { tempUnit } from 'src/app/shared/config/temp-unit.constant';
import { temperature } from 'src/app/features/wearer-management/constants/wearer.constant';
import { environment } from 'src/environments/environment';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AssetService } from 'src/app/features/asset-management/services/asset.service';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { BandIntroPage } from '../band-intro/band-intro.page';
import { gracePeriodOptions } from 'src/app/shared/config/grace-period.constants';
import { Subject, startWith, takeUntil } from 'rxjs';
import { LocationFieldInfo } from 'src/app/shared/enums/location-message.enum';
import { Capacitor } from '@capacitor/core';
import { AlertButtonModel } from 'src/app/shared/models/alertButtonModel';

@Component({
  standalone: true,
  selector: 'app-band-completion',
  templateUrl: './band-completion.page.html',
  styleUrls: ['./band-completion.page.scss'],
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BandCompletionPage {
  bandIcon = '/assets/images/band_association_good.svg';
  wearerName: string =
    this.wearerService.activeWearerDetails.residentDetail.firstName +
    ' ' +
    this.wearerService.activeWearerDetails.residentDetail.lastName;
  bandId = '';
  pageTitle = BandEnum.OndoBandTag;
  bandTitle = '';
  wearerId = '';
  bandStatus: BandStatus | undefined;
  replaceButtonName = 'Replace Band';
  isAssociatedBand = false;
  prevPage = '';
  bandFlow = '';
  BandPageFlow = BandPageFlowEnum;

  tempUnitId = 0;
  alertThresholdOption!: any;
  tempUnit = tempUnit;
  gracePeriodOptions = gracePeriodOptions;
  bandDetailsForm!: FormGroup;
  storeFormData: any;
  showGracePeriod = true;
  private destroy = new Subject<void>();
  isAsset = false;
  isTag = false;
  isNative = Capacitor.isNativePlatform();
  isReplaceRemoveEnabled = true;

  constructor(
    public wearerService: WearerService,
    public bandService: BandAssociationService,
    private authService: AuthService,
    private headerService: HeaderService,
    private uiUtilityService: UiUtilityService,
    private storageService: StorageService,
    private assetService: AssetService,
    private cdRef: ChangeDetectorRef,
    private ionNav: IonNav,
    private navParams: NavParams,
    private fb: FormBuilder
  ) { }

  ionViewWillEnter() {
    let tempObj = JSON.parse(localStorage['LoginDataunified']);
    this.tempUnitId = tempObj.tempUnit;
    const compTempMode =
      this.storageService.getLocalStorage(StorageEnum.compTemperatureMode) ||
      'true';
    const optionKey = `alertThresholdOptionIn${tempUnit[this.tempUnitId]}${
      // compTempMode === true || compTempMode === 'true' ? 'On' : 'Off'
      'On'
      }`;
    this.alertThresholdOption = temperature[optionKey];
    const [defaultThreshold] = [36.5]; //this.alertThresholdOption.values();
    this.bandDetailsForm = this.fb.group({
      bandId: [],
      assignedDate: [],
      alertThreshold: [defaultThreshold],
      location: [false],
      locationAlert: [false],
      gracePeriod: [5],
    });
    this.isAssociatedBand = false;
    this.wearerId = this.wearerService.activeWearerDetails.residentDetail.id;
    this.bandId = this.navParams.get('bandId');
    const isReplaceRemoveEnabled = this.navParams.get('isReplaceRemoveEnabled');
    if (isReplaceRemoveEnabled === false) {
      this.isReplaceRemoveEnabled = false;
    }
    if (this.wearerService.activeWearerDetails.bands[0]) {
      this.wearerService.activeWearerDetails.bands[0].bandId = this.bandId;
    } else {
      this.wearerService.activeWearerDetails.bands.push({
        bandId: this.bandId,
      });
    }
    this.checkBandFlow();
    const bandFlow = this.navParams.get('flow') || '';
    this.isAsset =
      bandFlow === BandPageFlowEnum.AssetAssociation ||
      bandFlow === BandPageFlowEnum.AssetOndoTagAssociated;
    this.isTag = this.navParams.get('bandInfo')?.isTag || false;
    this.pageTitle = this.isTag ? BandEnum.OndoTag : BandEnum.OndoBand;
    this.storeFormData = this.isAsset
      ? this.assetService.activeAssetDetails?.tag
      : this.wearerService.activeWearerDetails?.bands[0];
    this.bandDetailsForm.valueChanges
      .pipe(takeUntil(this.destroy), startWith(this.bandDetailsForm.value))
      .subscribe();
    this.bandDetailsForm.patchValue(this.storeFormData);
    this.cdRef.detectChanges();
  }

  checkBandFlow() {
    this.bandFlow = this.navParams.get('flow') || '';
    this.prevPage = this.navParams.data[BandEnum.PrevPage];
    // this.pageTitle = this.bandService.bandFlowTitle(this.bandFlow);
    if (this.bandService.isBandAssociationFlow) {
      if (this.prevPage === RouterEnum.bandScan) {
        this.bandTitle = BandEnum.AssociationComplete;
      } else {
        this.bandTitle = BandEnum.AssociatedBand;
      }
    } else {
      this.replaceButtonName = 'Find Another';
      this.applyBandLookUpFlow();
    }
    // if (this.bandFlow != this.BandPageFlow.AssetOndoTagAssociated) {
    //   this.showGracePeriod = true;
    // } else {
    //   this.showGracePeriod = false;
    // }
  }

  onLatestLocation() {
    this.bandDetailsForm?.get('locationAlert')?.setValue(false);
    this.bandDetailsForm
      ?.get('gracePeriod')
      ?.setValue(this.assetService.activeAssetDetails?.tag?.gracePeriod || 5);
  }

  onLocationWarning() {
    this.bandDetailsForm
      ?.get('gracePeriod')
      ?.setValue(this.assetService.activeAssetDetails?.tag?.gracePeriod || 5);
  }

  onToggleChange() {
    this.showGracePeriod = !this.showGracePeriod;
  }

  applyBandLookUpFlow() {
    this.bandStatus = this.bandService.bandStatus;
    if (!this.bandStatus?.id) {
      this.bandTitle = BandEnum.NotAssociated;
      this.wearerName = 'N/A';
    } else {
      this.parseBandStatus();
    }
  }

  parseBandStatus() {
    const authData: LoginResponseModel = this.authService.getLoginData;
    const userRole = this.authService.getUserRole;
    switch (userRole) {
      case UserRolesEnum.Corporate_Admin:
        this.updateView(this.bandStatus?.corpId === authData.corpId);
        break;
      case UserRolesEnum.Facility_Admin:
      case UserRolesEnum.Facility_Staff:
        this.updateView(
          this.bandStatus?.facilityId === this.headerService.getFacilityId()
        );
        break;
    }
  }

  updateView(isAssociatedBand: boolean) {
    this.isAssociatedBand = isAssociatedBand;
    if (isAssociatedBand) {
      this.bandTitle = BandEnum.AssociatedWearerFound;
      this.wearerName =
        this.bandStatus?.firstName + ' ' + this.bandStatus?.lastName;
    } else {
      this.bandIcon = '/assets/images/band_association_not_available.svg';
      this.bandTitle = BandEnum.AssociatedElsewhere;
      this.wearerName = '*****';
    }
  }

  navigateToWearerDetails() {
    if (this.prevPage === RouterEnum.bandScan) {
      const returnBackIndex = this.navParams.get('returnBackIndex');
      // this.ionNav.push(WearerDetailsPage, { id: this.wearerId });
      this.ionNav.popTo(returnBackIndex || 0);
    } else {
      this.ionNav.pop();
    }
  }

  navigateToAssetDetails(assetId: string) {
    if (this.prevPage === RouterEnum.bandScan) {
      const returnBackIndex = this.navParams.get('returnBackIndex');
      // this.ionNav.push(AssetDetailsPage, { assetId });
      this.ionNav.popTo(returnBackIndex || 0);
    } else {
      this.ionNav.pop();
    }
  }

  navigateBack() {
    this.ionNav.pop();
  }

  replaceBand() {
    if (!this.isNative) {
      return;
    }
    const details = this.navParams.get('details');
    this.ionNav.push(BandIntroPage, {
      assetId: details?.assetId,
      flow: this.bandFlow,
      details: details,
      returnBackIndex: this.navParams.get('returnBackIndex'),
      isReplaceRemoveEnabled: false
    });
  }

  saveData() {
    const bandInfo = this.navParams.get('bandInfo');
    if (
      this.bandFlow === BandPageFlowEnum.AssetAssociation ||
      this.bandFlow === BandPageFlowEnum.AssetOndoTagAssociated
    ) {
      this.assignBandTagToAsset(bandInfo);
    } else {
      this.assignBandTagToWearer(bandInfo);
    }
  }

  async showLocationInfo(type: string, event: any) {
    event.stopPropagation();
    if (type === 'alert') {
      this.uiUtilityService.showAlert(TemperatureThreshold[type], '');
    } else if (type === 'location') {
      this.uiUtilityService.showAlert(LocationFieldInfo.location, '');
    } else {
      this.uiUtilityService.showAlert(LocationFieldInfo.locationAlert, '');
    }
  }

  assignBandTagToWearer(bandInfo: any) {
    const associateBandUrl = `${environment.apiUrl.residentManagement}${FACILITY_ID_URL}/${EndPointsEnum.assignBandTagToWearer}/${this.wearerId}/${this.bandId}`;

    const payload: AssignBandToWearer = {
      alertThreshold: this.bandDetailsForm.get('alertThreshold')?.value,
      roomId: this.wearerService.activeWearerDetails.rooms[0]?.id,
      roomName: this.wearerService.activeWearerDetails.rooms[0]?.roomName,
      firstName:
        this.wearerService.activeWearerDetails.residentDetail.firstName,
      lastName: this.wearerService.activeWearerDetails.residentDetail.lastName,
      wearerId: this.wearerService.activeWearerDetails.residentDetail.id,
      bandId: this.bandDetailsForm.get('bandId')?.value,
      assignedDate: this.bandDetailsForm.get('assignedDate')?.value,
      location: this.bandDetailsForm.get('location')?.value,
      locationAlert: this.bandDetailsForm.get('locationAlert')?.value,
      gracePeriod: this.bandDetailsForm.get('gracePeriod')?.value,
      isTag: bandInfo?.isTag
    };
    this.wearerService
      .associateBandToResident(associateBandUrl, payload)
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.navigateToWearerDetails()
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  assignBandTagToAsset(bandInfo: any) {
    const assetId = this.navParams.get('assetId') || '';
    const assignTagUrl = `${environment.apiUrl.assetManagement}${FACILITY_ID_URL}/${EndPointsEnum.assignBandTagToAsset}/${assetId}/${this.bandId}`;
    const params = {
      location: this.bandDetailsForm.get('location')?.value,
      locationAlert: this.bandDetailsForm.get('locationAlert')?.value,
      gracePeriod: this.bandDetailsForm.get('gracePeriod')?.value,
      isTag: bandInfo?.isTag
    };
    this.assetService.assignTagToAsset(assignTagUrl, params).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.navigateToAssetDetails(assetId);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  removeDevice(device: string) {
    const header = 'Remove this ' + device;
    const message =
      `Are you sure you want to remove this ${device}? This action cannot be undone. All device settings will be discarded.`;
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
      },
      {
        text: 'Remove',
        handler: () => {
          // this.manageWearer(HttpRequestTypeEnum.delete);
          this.removeBandTagFromAssetorWearer();
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  async removeBandTagFromAsset(facilityId: string) {
    const assetId = this.navParams.get('assetId') || '';
    this.assetService
      .removeBandTagFromAsset(facilityId, assetId)
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.navigateToAssetDetails(assetId);
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  removeTagBandFromWearer(facilityId: string) {
    this.wearerService
      .removeBandTagFromWearer(facilityId, this.wearerId)
      .subscribe((response: any) => {
        if (response && response.success && response.data) {
          this.navigateToWearerDetails();
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  removeBandTagFromAssetorWearer() {
    const facilityId = this.headerService.getFacilityId();
    if (
      this.bandFlow === BandPageFlowEnum.AssetAssociation ||
      this.bandFlow === BandPageFlowEnum.AssetOndoTagAssociated
    ) {
      this.removeBandTagFromAsset(facilityId);
    } else {
      this.removeTagBandFromWearer(facilityId);
    }
  }
}
