import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { HeaderService } from 'src/app/core/services/header.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { FacilityModel } from 'src/app/shared/models/login-response.model';
import { BridgeService } from '../../services/bridge.service';
import { IonNav, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BridgeDetailsPage } from '../bridge-details/bridge-details.page';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';
import { BridgeStatusEnum } from 'src/app/shared/enums/bridge-status.enum';
// import { BedDetailsPage } from '../bed-details/bed-details.page';

@Component({
  standalone: true,
  selector: 'app-bridge-list',
  templateUrl: './bridge-list.page.html',
  styleUrls: ['./bridge-list.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, RouterModule, AccessControlDirective],
})
export class BridgeListPage {
  bridgeList: any[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private bridgeService: BridgeService,
    private headerService: HeaderService,
    private uiUtilityService: UiUtilityService,
    private nav: IonNav,
    private authService: AuthService
  ) { }

  ionViewWillEnter() {
    this.subscriptions.push(
      this.headerService
        .getFacility()
        .subscribe((facility: FacilityModel) => this.onFacilityChange(facility))
    );
    this.getBridgeList();
  }

  async getBridgeList() {
    await this.uiUtilityService.showLoader();
    this.bridgeService.getBridgeList().subscribe((response: ApiResponseModel<any[]>) => {
      if (response && response.success && response.data) {
        const list = this.uiUtilityService.sortList(response.data, 'bridgeName', 1);
        this.bridgeList = list.map((item: any) => ({ ...item, appBridgeStatus: this.getBridgeStatus(item.bridgeStatus) }))
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateToDetailsPage(bridge: any) {
    if (this.authService.isFeatureEnabled('getBridgeDetail')) {
      this.nav.push(BridgeDetailsPage, { bridgeId: bridge.bridgeId, mode: 'detail' });
    }
  }

  onFacilityChange(facility: FacilityModel) {
    const facilityId = this.headerService.getFacilityId();
    if (facility.facilityId !== facilityId) {
      this.getBridgeList();
    }
  }

  navigateToAddBridge() {
    this.nav.push(BridgeDetailsPage);
  }

  getBridgeStatus(bridgeStatus: any) {
    switch (bridgeStatus) {
      case 0:
        return BridgeStatusEnum.offline;
      case 1:
        return BridgeStatusEnum.online;
      case 2:
        return BridgeStatusEnum.initialising;
      default:
        return '';
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
