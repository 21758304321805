import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import {
  WearerDetailsModel,
  WearerGroup,
  BaseWearerModel,
  WearerDetail,
} from 'src/app/shared/models/wearer.model';
import { environment } from 'src/environments/environment';
import { RoomListModel } from '../pages/select-room/select-room.page';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';

@Injectable({
  providedIn: 'root',
})
export class WearerService {
  activeWearerDetails: WearerDetailsModel = new WearerDetailsModel();

  constructor(
    private dataService: DataService<any>,
    private headerService: HeaderService,
    private storageService: StorageService
  ) {}

  sortWearerList(
    wearerList: any[],
    sortingType: string,
    order: number
  ): BaseWearerModel[] {
    return wearerList.sort((a, b) => {
      const aVal = isNaN(+a[sortingType])
        ? a[sortingType].toUpperCase()
        : +a[sortingType];
      const bVal = isNaN(+b[sortingType])
        ? b[sortingType].toUpperCase()
        : +b[sortingType];
      if (aVal < bVal) {
        return -order;
      }
      if (aVal > bVal) {
        return order;
      }
      return 0;
    });
  }

  getWearerDetails(
    id: string
  ): Observable<ApiResponseModel<WearerDetailsModel>> {
    const url = `${environment.apiUrl.residentManagement}${id}/${EndPointsEnum.getResidentDetail}`;
    return this.dataService.getData(url);
  }

  getWearerGroups(
    facilityId: string
  ): Observable<ApiResponseModel<WearerGroup[]>> {
    const url = `${environment.apiUrl.residentManagement}${facilityId}/${EndPointsEnum.getWearerGroupList}`;
    return this.dataService.getData(url);
  }

  removeBandTagFromWearer(facilityId: string, wearerId: any): Observable<any> {
    const url = `${environment.apiUrl.residentManagement}${facilityId}/${EndPointsEnum.removeBandTagFromWearer}/${wearerId}`;
    return this.dataService.getData(url);
  }
  // getRoomList(
  //   facilityId: string
  // ): Observable<ApiResponseModel<RoomListModel[]>> {
  //   const url = `${environment.apiUrl.roomManagement}${facilityId}/${EndPointsEnum.getRoomList}`;
  //   return this.dataService.getData(url);
  // }

  // associateRoomToResident(url: string, wearerIds: string[]) {
  //   return this.dataService.post(url, wearerIds);
  // }

  associateBandToResident(url: string, payload: any) {
    return this.dataService.post(url, payload);
  }

  get(controlName: string, form: FormGroup | null): AbstractControl | null {
    return form && form.get(controlName);
  }

  getManageWearerRequestData(
    form: FormGroup | null,
    id: string,
    facilityId: string
  ): any {
    return {
      id: id,
      firstName: this.get('firstName', form)?.value as string,
      lastName: this.get('lastName', form)?.value as string,
      subWearerGroupId: 'FS1',
      localId: this.get('localId', form)?.value as string,

      // facilityId: facilityId,
      // wearerId: id,
      // bandId: this.get('bandId', form)?.value as string,
      wearerGroupId: this.get('wearerGroupId', form)?.value as string,
      isDeleted: this.get('wearerStatus', form)?.value as boolean,
      //   alertThresholdId: this.get('alertThreshold', form)?.value,
      //   location: this.get('location', form)?.value ? true : false,
      //   locationAlert: this.get('locationAlert', form)?.value ? true : false,
      //   gracePeriod: +this.get('gracePeriod', form)?.value,
    };
  }

  /**
   * Convert temperature from Fahrenheit to Celsius
   * @param temp - Alert threshold temperature
   */
  alertThresholdInCelsius(temp: number) {
    if (temp) {
      temp = ((temp - 32) * 5) / 9;
      return Math.round((temp + Number.EPSILON) * 100) / 100;
    }
    return temp;
  }

  /**
   * Convert temperature from Celsius to Fahrenheit
   * @param temp - Alert threshold temperature
   */
  alertThresholdInFahrenheit(temp: number) {
    if (temp) {
      temp = (temp * 9) / 5 + 32;
      return Math.round((temp + Number.EPSILON) * 10) / 10;
    }
    return temp;
  }

  /**
   * Calculate compensation temperature
   * @param skinTemperature -
   */
  calculateCompTemp(skinTemperature: number) {
    const compTempMode =
      this.storageService.getLocalStorage(StorageEnum.compTemperatureMode) ||
      'true';
    let compTemp = 0;
    const lowerThreshold = 30;
    const higherThreshold = 34.5;
    const maximumCompensation = 6.5;
    const minimumCompensation = 2.5;
    if (compTempMode === 'false') {
      return compTemp;
    }
    if (skinTemperature === lowerThreshold) {
      compTemp = maximumCompensation;
    } else if (
      skinTemperature > lowerThreshold &&
      skinTemperature < higherThreshold
    ) {
      compTemp =
        maximumCompensation -
        ((maximumCompensation - minimumCompensation) /
          (higherThreshold - lowerThreshold)) *
          (skinTemperature - lowerThreshold);
    } else if (skinTemperature >= higherThreshold) {
      compTemp = minimumCompensation;
    }
    return compTemp <= 0
      ? 0
      : Math.round((compTemp + Number.EPSILON) * 100) / 100;
  }

  /**
   * Calculate compensation temperature - Phase2
   * @param skinTemperature -
   */
  calculateCompTempV2(skinTemperature: number) {
    const compTempMode =
      this.storageService.getLocalStorage(StorageEnum.compTemperatureMode) ||
      'true';
    let compTemp = 0;
    const lowerThreshold = 30;
    const middleThreshold = 34.5;
    const higherThreshold = 36.5;

    const minimumCompensation = 1.5;
    const middleCompensation = 2.5;
    const maximumCompensation = 6.5;

    if (compTempMode === 'false') {
      return compTemp;
    }
    if (skinTemperature === lowerThreshold) {
      compTemp = maximumCompensation;
    } else if (
      skinTemperature > lowerThreshold &&
      skinTemperature <= middleThreshold
    ) {
      compTemp =
        maximumCompensation -
        ((maximumCompensation - middleCompensation) /
          (middleThreshold - lowerThreshold)) *
          (skinTemperature - lowerThreshold);
    } else if (
      skinTemperature > middleThreshold &&
      skinTemperature <= higherThreshold
    ) {
      compTemp =
        middleCompensation -
        ((middleCompensation - minimumCompensation) /
          (higherThreshold - middleThreshold)) *
          (skinTemperature - middleThreshold);
    } else if (skinTemperature > higherThreshold) {
      compTemp = minimumCompensation;
    }
    return compTemp <= 0
      ? 0
      : Math.round((compTemp + Number.EPSILON) * 100) / 100;
  }
}
