<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button (click)="goBack()"></ion-back-button>
    </ion-buttons>
    <ion-title>Assign Device</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="wearer-item" (click)="findBand()">
    <div class="section-label">Available Devices</div>
    <ion-item detail class="first-item-border">
      <ion-label>ONDO Band/Tag</ion-label>
    </ion-item>
  </div>
</ion-content>
