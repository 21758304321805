<app-unified-header [title]="entityConfig?.title" [defaultHref]="defaultHref"></app-unified-header>

<ion-content>
  <ion-item class="list-header" lines="none">
    <ion-label>
      <h2>Assigned {{entityConfig?.title}}</h2>
    </ion-label>
  </ion-item>
  <div *ngIf="assignedItems?.length">
    <ion-item class="assigned-item" *ngFor="let assignedItem of assignedItems"
      (click)="navigateToItemDetailsPage(assignedItem)">
      <ion-label class="list-item">
        <h3 class="app-link p-b-1" [ngClass]="{ 'no-link': !isSummaryEnabled }">{{assignedItem.itemName ||
          assignedItem.itemNameFallback}}</h3>
        <p *ngIf="entityConfig.rightSlot" [ngClass]="assignedItem[entityConfig.rightSlot]?.toLowerCase()">
          {{assignedItem[entityConfig.rightSlot]}}</p>
      </ion-label>
    </ion-item>
  </div>
  <div class="no-items" *ngIf="!assignedItems?.length">
    {{entityConfig?.noAssignItemsNote}}
  </div>
  <ion-note *ngIf="entityConfig?.helpText" class="ion-padding-horizontal helpText">{{entityConfig?.helpText}}</ion-note>
  <div class="action-btns ion-padding" *ngIf="entityConfig?.actions">
    <ion-button color="danger" [disabled]="!assignedItems?.length" (click)="removeItems()">Remove
      {{entityConfig?.title}}</ion-button>
    <ion-button (click)="assignItems()">Assign {{entityConfig?.title}}</ion-button>
  </div>
</ion-content>