import { ValidatorFn } from '@angular/forms';
import { AlertInput } from '@ionic/angular';

export enum ControlType {
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  TEXT_AREA = 'textarea',
  LINK = 'link',
  TOGGLE = 'toggle',
  SELECT = 'select',
}

export interface FormError {
  required?: string;
  minlength?: string;
  maxlength?: string; // Native form control name is same so have keep all in lowercase instead of camelCase
  pattern?: string;
  custom?: string;
}
export interface UnifiedInput extends AlertInput {
  controlType: ControlType;
  // Option to populate For radio, checkbox and select control
  detail?: boolean; // To add arrow button for every field
  nav?: string;
  title?: string; //Custom title of Alert header
  navParams?: unknown;
  helpText?: string;
  formControlName?: string;
  validators?: ValidatorFn[]; // Input validators (For eg. maxlength)
  customErrorMessage?: FormError; // Custom error message for input validation
  showNone?: boolean;
  fieldName?: string;
  readOnly?: boolean;
  colorCodeClass?: string;
}
