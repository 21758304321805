<app-unified-header [title]="!assetId ? 'Add Asset' : isReadMode ? 'Asset Details' : 'Edit Asset'"
  (onSave)="manageAsset()" defaultHref="/asset-list" [restrictBack]="assetDetailsForm && !assetDetailsForm.pristine"
  [saveBtn]="{title: assetId && isReadMode ? 'Edit' : 'Save', isDisable: assetId && isReadMode ? false : isSaveDisabled, rule: assetId ? 'putAsset' : ''}"
  [isRootPage]="isRootPage"></app-unified-header>

<ion-content *ngIf="assetDetailsForm">
  <form [formGroup]="assetDetailsForm">
    <ng-container *ngFor="let config of assetDetailsInputConfig">
      <unified-input ngDefaultControl [formControlName]="config.name || null" [config]="getConfig(config)"
        (changed)="inputChanged($event)"></unified-input>
    </ng-container>
    <ion-item [detail]="!isReadMode || !assetId" [ngClass]="{'interactive': !isReadMode || !assetId}"
      (click)="setAssetType()">
      <ion-label>Type</ion-label>
      <ion-text slot="end">{{f['assetTypeName']?.value}}</ion-text>
    </ion-item>
    <ng-container *ngIf="assetId">
      <ion-item [detail]="!isReadMode" [ngClass]="{'interactive': !isReadMode}" (click)="setRoom()">
        <ion-label>Room</ion-label>
        <ion-text slot="end" [class.app-link]="f['roomName']?.value && isReadMode"
          [class.no-link]="!isRoomSummaryEnabled" [class.none-value]="!f['roomName']?.value">{{f['roomName']?.value ||
          'None'}}</ion-text>
      </ion-item>
    </ng-container>
    <div class="asset-item band" *ngIf="assetId && isReadMode">
      <div class="section-label">Devices</div>
      <ion-item *ngIf="assetDetails?.tag" [detail]="isReadMode" class="first-item-border"
        (click)="findBand(assetDetails?.tag)">
        <ion-label>{{assetDetails?.tag?.isTag ? 'ONDO Tag' : 'ONDO Band'}}</ion-label>
        <div slot="end" class="band-id">
          <ion-text> {{assetDetails?.tag?.bandId || assetDetails?.tag?.tagId}}</ion-text>
        </div>
      </ion-item>
      <ion-item (click)="findBand()" detail class="first-item-border" *ngIf="!assetDetails?.tag">
        <ion-label class="app-link-action">Assign Devices...</ion-label>
      </ion-item>
    </div>
  </form>
  <div class="delete-asset" *ngIf="assetId && isReadMode">
    <ion-button color="danger" (click)="deleteAsset()" [nAccess]="'deleteAsset'">Delete This Asset</ion-button>
  </div>
</ion-content>