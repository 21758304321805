import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { IonicModule, MenuController } from "@ionic/angular";
import { UiUtilityService } from "src/app/core/services/ui-utility.service";
import { UnifiedHeaderComponent } from "src/app/shared/components/unified-header/unified-header.component";
import { CorporateService } from "./corporate-list.service";
import { ApiResponseModel } from "src/app/core/services/api/api-response.model";
import { AuthService } from "src/app/core/services/auth.service";
import { StorageEnum } from "src/app/shared/enums/storage.enum";
import { StorageService } from "src/app/core/services/storage.service";

@Component({
  standalone: true,
  selector: 'app-corporate-list',
  templateUrl: './corporate-list.page.html',
  styleUrls: ['./corporate-list.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UnifiedHeaderComponent
  ],
})
export class CorporateListPage {
  items: any[] = [];
  originalList: any[] = [];
  selectControl = new FormControl('');
  isChanged = false;
  currentAccount = '';

  constructor(private uiUtilityService: UiUtilityService, private corporateService: CorporateService, private authService: AuthService, private storageService: StorageService, private menuCtrl: MenuController) {

  }

  ngOnInit() {
    const { corpId } = this.authService.getLoginData;
    this.selectControl.patchValue(corpId);
    this.currentAccount = corpId;
    this.getList();
  }

  getList() {
    this.corporateService.getCorporateAccountsList().subscribe((response: ApiResponseModel<any[]>) => {
      if (response && response.success && response.data) {
        this.originalList = this.uiUtilityService.sortList(response.data, 'corporateName', 1);
        this.items = [...this.originalList];
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  onSelect(event: any) {
    this.isChanged = true;
    this.selectControl.patchValue(event.target.value);
  }

  handleChange(event: any) {
    const keyword = event.target.value?.toLowerCase()?.trim();
    if (keyword && keyword !== '') {
      this.items = this.originalList.filter((item: any) => item.corporateName?.toLowerCase()?.includes(keyword));
    } else {
      this.items = [...this.originalList];
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  dismissMenu() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    }
  }

  switchAccount() {
    const corpId = this.selectControl.value;
    if (!corpId) {
      return;
    }
    this.corporateService.getCorpAccountDetails(corpId).subscribe((response: ApiResponseModel<any>) => {
      if (response && response.data?.loginResponse) {
        if (response.data.loginResponse?.corpId) {
          const loginData = this.authService.getLoginData;
          this.storageService.setLocalStorage(StorageEnum.loginData, { ...loginData, ...response.data?.loginResponse }, true);
          this.dismissMenu();
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert('No details found for the selected corporate.', header);
        }
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }
}