import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ActionSheetController,
  IonNav,
  IonicModule,
  NavParams,
} from '@ionic/angular';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { InputConfigService } from 'src/app/core/services/input-config.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { HttpRequestTypeEnum } from 'src/app/shared/enums/http-request-type.enum';
import { InputEnum } from 'src/app/shared/enums/input-field.enum';
import { Floor } from 'src/app/shared/models/floor.model';
import { ValidationConfig } from 'src/app/shared/models/login-response.model';
import { environment } from 'src/environments/environment';
import { PhotoService } from '../services/photo.service';
import { maxImageSizeInBytes } from '../../../../../shared/config/floor.constant';
import { CommonModule } from '@angular/common';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';

@Component({
  selector: 'app-floor',
  templateUrl: './floor.page.html',
  styleUrls: ['./floor.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
})
export class FloorPage implements OnInit, OnDestroy {
  floorId: any = null;
  floorDetails: Floor = new Floor();
  subscriptions: any[] = [];
  floorsForm!: FormGroup;
  facilityId: any;
  floorNameConfig: any;
  editEnabled = false;
  isFormDisabled = true;
  EndPoints = EndPointsEnum;

  destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private uiUtilityService: UiUtilityService,
    private actionSheetController: ActionSheetController,
    public photoService: PhotoService,
    private dataService: DataService<any>,
    private icService: InputConfigService,
    private namParams: NavParams,
    private ionNav: IonNav
  ) { }

  ngOnInit() {
    this.facilityId = this.namParams.get('facilityId') || null;
    this.floorNameConfig = this.icService.getValidator(InputEnum.FLOORNAME);
    this.floorsForm = this.fb.group({
      floorName: [this.floorDetails.floorName, this.floorNameConfig],
      floorImage: [this.floorDetails.floorImage],
    });
    this.floorId = this.namParams.get('floorId') || null;
    if (this.floorId) {
      this.getFloorDetails();
    }

    this.floorsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isFormDisabled = this.floorsForm.invalid;
      });
  }

  getFloorDetails() {
    const manageFloorsEndpoint = `${environment.apiUrl.corporate}${this.facilityId}/${EndPointsEnum.manageFloors}?floorID=${this.floorId}`;
    this.dataService.getData(manageFloorsEndpoint).subscribe((data) => {
      this.floorDetails = data.data;
      this.floorsForm.patchValue(this.floorDetails, { emitEvent: false });
    });
  }

  backClick() {
    if (!this.floorId || this.editEnabled) {
      if (!this.floorsForm.pristine) {
        const header = 'Confirm';
        const message =
          'Are you sure you want to go back without saving? Any changes made will be discarded.';
        const buttons = [
          {
            text: 'cancel',
            role: 'cancel',
            cssClass: 'o-dialog-buttons',
            handler: () => {
              console.log('Confirm Cancel');
            },
          },
          {
            text: 'Go Back',
            cssClass: 'o-dialog-buttons',
            handler: () => {
              if (!this.floorId) {
                this.ionNav.pop();
              } else {
                this.editEnabled = !this.editEnabled;
                this.floorsForm.patchValue(this.floorDetails);
              }
            },
          },
        ];
        this.uiUtilityService.showAlert(message, header, buttons, [], '');
      } else {
        if (!this.floorId) {
          this.ionNav.pop();
        } else {
          this.editEnabled = !this.editEnabled;
        }
      }
    } else {
      this.ionNav.pop();
    }
  }
  get title() {
    // this.setTitle = `${this.floorId ? 'Floor Details' : 'Add Floor'}`;
    return `${this.floorId && !this.editEnabled
      ? 'Floor Details'
      : this.floorId && this.editEnabled
        ? 'Edit Floor'
        : 'Add Floor'
      }`;
  }

  async manageFloors(operationType: any) {
    const manageFloorsEndpoint = `${environment.apiUrl.corporate}${this.facilityId}/${EndPointsEnum.managefloors}`;
    let manageApiObservable: any = null;
    this.floorDetails = {
      ...this.floorDetails,
      ...this.floorsForm?.value,
    };
    switch (operationType) {
      case HttpRequestTypeEnum.post:
        manageApiObservable = this.dataService.post(
          manageFloorsEndpoint,
          this.floorDetails
        );
        break;
      case HttpRequestTypeEnum.put:
        manageApiObservable = this.dataService.put(
          manageFloorsEndpoint,
          this.floorDetails
        );
        break;
      case HttpRequestTypeEnum.delete:
        manageApiObservable = this.dataService.delete(
          manageFloorsEndpoint,
          this.floorDetails
        );
        break;
    }
    await this.uiUtilityService.showLoader();
    manageApiObservable.subscribe((data: ApiResponseModel<Floor>) => {
      this.uiUtilityService.closeLoader();
      if (data && data.success) {
        this.uiUtilityService.showToast('Successfull');
        if (HttpRequestTypeEnum.delete === operationType) {
          this.ionNav.pop();
        } else {
          this.floorId = data.data?.floorID;
          this.floorDetails = data.data!;
          this.editEnabled = false;
          this.floorsForm.markAsPristine();
          this.isFormDisabled = true;
        }
      } else {
        const header = 'Error';
        let errorMsg: any = data.errorMessage;
        this.uiUtilityService.showAlert(errorMsg, header);
      }
    });
  }

  async addFloorName() {
    const buttons = [
      {
        text: 'cancel',
        role: 'cancel',
        cssClass: 'o-dialog-buttons',
        handler: () => {
          console.log('Confirm Cancel');
        },
      },
      {
        text: 'ok',
        cssClass: 'o-dialog-buttons',
        handler: (data: any) => {
          const config: ValidationConfig =
            this.floorNameConfig || data.floorName;
          const value = data.floorName;
          if (!value) {
            this.uiUtilityService.showToast('Please enter floor name');
            return false;
          } else if (
            (config.minLength &&
              value &&
              value.trim().length < config.minLength) ||
            (config.maxLength &&
              value &&
              value.trim().length > config.maxLength) ||
            (config.pattern &&
              !new RegExp(config.pattern).test(value.toString()))
          ) {
            this.uiUtilityService.showToast(
              `Please enter valid floor name${config.errorMessage ? ', ' + config.errorMessage : ''
              }`
            );
            return false;
          } else {
            this.floorsForm.patchValue({ floorName: data.floorName });
            this.floorsForm.markAsDirty();
          }
        },
      },
    ];
    const inputs = [
      {
        // type: 'textarea',
        name: 'floorName',
        placeholder: '',
        value: this.floorDetails.floorName || '',
        id: 'autofocus',
        attributes: this.floorNameConfig,
      },
    ];
    await this.uiUtilityService
      .showAlert('', 'Floor Name', buttons, inputs)
      .then(() => {
        const id = document.getElementById('autofocus');
        if (id) id.focus();
      });
  }

  async actions() {
    const buttons = [
      {
        text: 'Delete',
        cssClass: 'delete-button o-dialog-buttons',
        handler: () => {
          this.removePhotoConfirmation();
        },
      },
      {
        text: 'Take Photo',
        handler: () => {
          this.choosePhoto(1);
        },
      },
      {
        text: 'Choose Image',
        handler: () => {
          this.choosePhoto(0);
        },
      },
      { text: 'cancel', role: 'cancel' },
    ];

    if (!this.floorDetails.floorImage) {
      buttons.shift();
    }

    const actionSheet = await this.actionSheetController.create({
      cssClass: 'custom-actions',
      buttons,
    });
    await actionSheet.present();
  }

  choosePhoto(isCamera: number) {
    this.photoService.getPhotoFromNative(isCamera).then(
      (image: any) => {
        if (this.validateSize(image.dataUrl)) {
          this.floorsForm.patchValue({ floorImage: image.dataUrl });
          this.floorsForm.markAsDirty();
        } else {
          this.uiUtilityService.showAlert(
            'The image size cannot be larger than 1Mb.',
            'Could Not Add Image'
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  validateSize(data: string) {
    const maxSize = maxImageSizeInBytes;
    const currentSize = data.length * (3 / 4) - (data.endsWith('==') ? 2 : 1);
    return currentSize < maxSize;
  }

  removePhotoConfirmation() {
    if (!this.floorDetails.floorID) {
      this.floorDetails.floorImage = '';
    } else {
      const header = 'Delete Image';
      const msg = 'Are you sure you want to delete this image?';
      const buttons = [
        {
          text: 'cancel',
          role: 'cancel',
          cssClass: 'o-dialog-buttons',
          handler: () => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Delete',
          cssClass: 'o-dialog-buttons delete-button',
          handler: () => {
            this.floorDetails.floorImage = '';
            this.floorsForm.markAsDirty();
          },
        },
      ];
      this.uiUtilityService.showAlert(
        msg,
        header,
        buttons,
        [],
        'confirm-delete'
      );
    }
  }

  deleteFloor() {
    const header = 'Delete This Floor';
    const msg =
      'Are you sure you want to delete this floor? This action cannot be undone. Any bridges that were added to this floor will be unassigned.';
    const buttons = [
      {
        text: 'cancel',
        role: 'cancel',
        cssClass: 'o-dialog-buttons',
        handler: () => {
          console.log('Confirm Cancel');
        },
      },
      {
        text: 'Delete',
        cssClass: 'o-dialog-buttons delete-button',
        handler: (data: any) => {
          this.manageFloors('delete');
        },
      },
    ];
    this.uiUtilityService.showAlert(msg, header, buttons, [], 'confirm-delete');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: { unsubscribe: () => any }) =>
      sub.unsubscribe()
    );
  }

  editFloor() {
    this.editEnabled = true;
  }
}
