<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="navigateBack()" *ngIf="!editModeEnable">Close</ion-button>
      <ion-back-button class="header-modal-close-btn" defaultHref="/dashboard" (click)="backClick()"
        *ngIf="editModeEnable"></ion-back-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="backClick()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">{{editModeEnable ? 'Edit Corporate Account' : 'Corporate Account'}}</ion-title>
    <ion-buttons slot="end" *ngIf="!editModeEnable">
      <ion-button (click)="editDetails()" [nAccess]="EndPoints.editCorpAccount">Edit</ion-button>
    </ion-buttons>
    <ion-buttons slot="end" *ngIf="editModeEnable">
      <ion-button [disabled]="corporateForm.pristine || isSaveDisabled" (click)="saveDetails()">Save</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="corporateForm">
    <div class="coporate-details-list" *ngIf="!editModeEnable">
      <ion-item>
        <div class="section-title">Account Information</div>
      </ion-item>
      <ion-item>
        <ion-label>Corporate Name</ion-label>{{corporateForm?.get('corporateName')?.value}}
      </ion-item>
      <ion-item>
        <ion-label>Corporate Phone</ion-label>{{corporateForm?.get('corporatePhone')?.value}}
      </ion-item>
      <ion-item>
        <ion-label>Corporate Email</ion-label>{{corporateForm?.get('corporateEmail')?.value}}
      </ion-item>
      <ion-item>
        <div class="section-label">Account Representative</div>
      </ion-item>
      <ion-item>
        <ion-label>Name</ion-label>{{corporateForm?.get('corporateOwnerName')?.value}}
      </ion-item>
      <ion-item>
        <ion-label>Title</ion-label>{{corporateForm?.get('corporateOwnerTitle')?.value}}
      </ion-item>
      <ion-item class="alert-spike first-item-border" id="disable-click">
        <ion-text>Unit</ion-text>
        <ion-select slot="end" interface="popover" [interfaceOptions]="{cssClass: 'alert-spike'}" placeholder="Select"
          formControlName="tempUnitId">
          <ion-select-option *ngFor="let id of tempUnitIds" [value]="+id">
            &#176;{{tempUnit[+id]}}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <div class="coporate-details-edit" *ngIf="editModeEnable">
      <ion-item>
        <div class="section-title">Account Information</div>
      </ion-item>
      <ion-item button
        (click)="presentAlert(1,'Corporate Name', 'Corporate Name', corporateForm?.get('corporateName')?.value)">
        <ion-label>Corporate Name</ion-label>{{corporateForm?.get('corporateName')?.value}}
      </ion-item>
      <ion-item button
        (click)="presentAlert(2,'Corporate Phone', 'Corporate Phone', corporateForm?.get('corporatePhone')?.value)">
        <ion-label>Corporate Phone</ion-label>{{corporateForm?.get('corporatePhone')?.value}}
      </ion-item>
      <ion-item button
        (click)="presentAlert(3,'Corporate Email', 'Corporate Email', corporateForm?.get('corporateEmail')?.value)">
        <ion-label>Corporate Email</ion-label>{{corporateForm?.get('corporateEmail')?.value}}
      </ion-item>
      <ion-item>
        <div class="section-label">Account Representative</div>
      </ion-item>
      <ion-item button (click)="presentAlert(4,'Name', 'Name', corporateForm?.get('corporateOwnerName')?.value)">
        <ion-label>Name</ion-label>{{corporateForm?.get('corporateOwnerName')?.value}}
      </ion-item>
      <ion-item button (click)="presentAlert(5,'Title', 'Title', corporateForm?.get('corporateOwnerTitle')?.value)">
        <ion-label>Title</ion-label>{{corporateForm?.get('corporateOwnerTitle')?.value}}
      </ion-item>
      <ion-item class="alert-spike first-item-border" style="margin-top: 50px;">
        <ion-text>Unit</ion-text>
        <ion-select slot="end" interface="popover" [interfaceOptions]="{cssClass: 'alert-spike'}" placeholder="Select"
          formControlName="tempUnitId">
          <ion-select-option *ngFor="let id of tempUnitIds" [value]="+id">
            &#176;{{tempUnit[+id]}}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>


    <!-- <div class="wearer-item">
      <div class="section-label">General Settings</div>
      <ion-row>
        <ion-col>
          <ion-item class="first-item-border" hidden="true" [disabled]="!editModeEnable">
            <ion-label>Wearer Group</ion-label>
            <ion-select interface="popover" placeholder="Select" formControlName="wearerGroupId">
              <ion-select-option *ngFor="let id of wearerGroupIds" [value]="id">
                {{wearerGroups[+id]}}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item hidden="true" [disabled]="!editModeEnable">
            <ion-label>Wearer Management</ion-label>
            <ion-select slot="end" interface="popover" [interfaceOptions]="{cssClass: 'alert-spike'}"
              placeholder="Select" formControlName="wearerIdSel">
              <ion-select-option *ngFor="let id of wearerNameFormatIds" [value]="id">
                {{wearerNameFormat[+id]}}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item class="alert-spike first-item-border" [disabled]="!editModeEnable">
            <ion-text>Unit</ion-text>
            <ion-select slot="end" interface="popover" [interfaceOptions]="{cssClass: 'alert-spike'}"
              placeholder="Select" formControlName="tempUnitId">
              <ion-select-option *ngFor="let id of tempUnitIds" [value]="+id">
                &#176;{{tempUnit[+id]}}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
    </div> -->
  </form>
</ion-content>