import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { UserModel } from 'src/app/shared/models/user.model';
import { UserService } from './services/user.service';
import { IonNav, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HeaderService } from 'src/app/core/services/header.service';
import { Subscription } from 'rxjs';
import { FacilityModel } from 'src/app/shared/models/login-response.model';
import { AddAccountPage } from './add-account/add-account.page';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';

@Component({
  standalone: true,
  selector: 'app-user-accounts',
  templateUrl: './user-accounts.page.html',
  styleUrls: ['./user-accounts.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, AccessControlDirective],
})
export class UserAccountsPage implements OnInit {
  userList: UserModel[] = [];
  roles: any = {};
  subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private uiUtilityService: UiUtilityService,
    private authService: AuthService,
    private headerService: HeaderService,
    private ionNav: IonNav
  ) { }

  ngOnInit(): void {
    this.getUserRoles();
  }

  ionViewWillEnter() {
    this.subscriptions.push(
      this.headerService
        .getFacility()
        .subscribe((facility: FacilityModel) => this.onFacilityChange(facility))
    );
    this.getUserList();
  }

  getUserList() {
    this.userService.getUserList().subscribe((response: any) => {
      if (response.success) {
        const sorted = this.uiUtilityService.sortList(
          response.data,
          'userName',
          1
        );
        const grouped = sorted.reduce((groups: any, contact: any) => {
          const letter = contact.userName.charAt(0);
          groups[letter] = groups[letter] || [];
          groups[letter].push(contact);
          return groups;
        }, {});
        let result: any[] = [];
        for (const [key, value] of Object.entries(grouped)) {
          const contacts = value as UserModel[];
          result = [
            ...result,
            { isGroupHeader: true, title: key },
            ...contacts,
          ];
        }
        this.userList = result;
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage, header);
      }
    });
  }

  getUserRoles() {
    this.userService.getUserRoles().subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.roles = response.data.reduce(
          (obj: any, cur: any) => ({ ...obj, [cur.id]: cur.roleName }),
          {}
        );
      }
    });
  }

  navigateToDetailsPage(user: UserModel) {
    if (this.authService.isFeatureEnabled('getUserDetail')) {
      this.ionNav.push(AddAccountPage, { userId: user.userId, mode: 'detail' });
    }
  }

  navigateToAddAccount() {
    this.ionNav.push(AddAccountPage);
  }

  isCurrentUser(user: UserModel) {
    return user.userId === this.authService.getLoginData.userId;
  }

  onFacilityChange(facility: FacilityModel) {
    const facilityId = this.headerService.getFacilityId();
    if (facility.facilityId !== facilityId) {
      this.getUserList();
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }
}
