import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonNav, IonicModule, NavController, NavParams } from '@ionic/angular';
import { Router, RouterModule } from '@angular/router';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { BandIntroPage } from '../band-intro/band-intro.page';

@Component({
  selector: 'app-assign-device',
  templateUrl: './assign-device.page.html',
  styleUrls: ['./assign-device.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, RouterModule],
})
export class AssignDevicePage implements OnInit {
  constructor(
    private router: Router,
    private navController: NavController,
    private ionNav: IonNav,
    private navParams: NavParams
  ) { }

  ngOnInit() { }

  findBand(): void {
    // this.router.navigate([RouterEnum.bandIntro], {
    //   queryParamsHandling: 'preserve',
    // });
    const param = this.navParams.data;
    this.ionNav.push(BandIntroPage, param);
  }

  goBack() {
    this.ionNav.pop();
  }
}
