import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { HeaderService } from '../header.service';
import { environment } from 'src/environments/environment';
import { FACILITY_ID_URL, INGNORE_AUTH } from 'src/app/shared/config/app.constant';
import { Device } from '@capacitor/device';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    deviceId = '';

    constructor(private authService: AuthService, private headerService: HeaderService) { }

    async getDeviceId() {
        const info = await Device.getId();
        this.deviceId = info.identifier;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const token = this.authService.authToken;
        const refreshToken = this.authService.refreshToken;
        const cid = this.authService.getLoginData.corpId;
        const fid = this.headerService.getFacilityId();
        const tenantId = environment.clientCode.toLowerCase();
        const requestHeders: any = {
            'X-TenantID': tenantId
        };
        const isIngore = INGNORE_AUTH.some(endpoint => request.url.includes(endpoint));
        const facilityId = fid || 'not-found';
        if (token && refreshToken && !isIngore) {
            requestHeders['Authorization'] = `Bearer ${token}`;

            if (fid) {
                requestHeders['fid'] = fid;
            }
            if (cid) {
                requestHeders['cid'] = cid;
            }
            if (!this.deviceId) {
                this.getDeviceId();
            }
            if (this.deviceId) {
                requestHeders['deviceId'] = this.deviceId;
            }

            request = request.clone({
                setHeaders: requestHeders,
                url: request.url.replace(FACILITY_ID_URL, facilityId)
            });

            const authExpTime = this.authService.getJwtTokenExpiryTime(token);
            const refreshExpTime = this.authService.getJwtTokenExpiryTime(refreshToken);
            const nowTime = Date.now();
            const authTokenIsAboutToExpire = !(nowTime < authExpTime - 30000); // check before 30 secs of auth token expiry
            const authTokenIsExpired = nowTime > authExpTime; // check if token already expired in case of app stand by mode
            const refreshTokenIsAboutToExpire = !(nowTime < refreshExpTime - 30000); // check before 15 secs of refresh token expiry
            const refreshTokenIsExpired = nowTime > refreshExpTime;

            if (!refreshTokenIsAboutToExpire || !refreshTokenIsExpired) {
                if (authTokenIsAboutToExpire || authTokenIsExpired) {
                    // this.authService.isRefreshingToken = false;
                    return this.authService.getNewAccessTokenBefore401Error(request, next);
                }
                return next.handle(request);
            }
            return throwError(new HttpErrorResponse({
                status: 401,
                error: 'Authorization error: The request did not go through'
            }));
        } else {
            request = request.clone({
                setHeaders: requestHeders,
                url: request.url.replace(FACILITY_ID_URL, facilityId)
            });
            return next.handle(request);
        }
    }
}
