import { FacilityService } from 'src/app/features/settings/facility-management/services/facility.service';
import { Component } from '@angular/core';
import { Floor } from 'src/app/shared/models/floor.model';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { FloorPage } from '../floor/floor.page';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';

@Component({
  selector: 'app-floor-list',
  templateUrl: './floor-list.page.html',
  styleUrls: ['./floor-list.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, UnifiedHeaderComponent],
})
export class FloorListPage {
  floorList: Array<Floor> = [];
  facilityId: any;
  maxFloors: number = 5;
  EndPoints = EndPointsEnum;

  constructor(
    private facilityService: FacilityService,
    private ionNav: IonNav,
    private navParams: NavParams
  ) { }

  ionViewWillEnter() {
    this.facilityId = this.navParams.get('facilityId');
    this.fetchFloors();
  }

  fetchFloors() {
    this.facilityService.getFacilityFloorListById(this.facilityId).subscribe((floorList: any) => {
      this.floorList = floorList.data.floors.sort((a: any, b: any) =>
        a.floorName.localeCompare(b.floorName)
      );
      this.maxFloors = floorList.data.max_no_of_floors;
    });
  }

  navigateToDetailsPage(floor: Floor) {
    this.ionNav.push(FloorPage, {
      facilityId: this.facilityId,
      floorId: floor.floorID,
    });
  }

  navigateBack() {
    this.ionNav.pop();
  }

  navigateToAddFloor() {
    this.ionNav.push(FloorPage, { facilityId: this.facilityId });
  }
}
