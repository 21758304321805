import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  InputConfigService,
  InputValidator,
} from 'src/app/core/services/input-config.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import {
  tempUnit,
  tempUnitIds,
} from 'src/app/shared/config/temp-unit.constant';
import { InputEnum } from 'src/app/shared/enums/input-field.enum';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { AlertButtonModel } from 'src/app/shared/models/alertButtonModel';
import { CorporateDetailsModel } from 'src/app/shared/models/corporate-details.model';
import { SettingsService } from '../services/settings.service';
import { AlertController, IonNav, IonicModule } from '@ionic/angular';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { ValidationConfig } from 'src/app/shared/models/login-response.model';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
@Component({
  standalone: true,
  selector: 'app-corporate-account',
  templateUrl: './corporate-account.page.html',
  styleUrls: ['./corporate-account.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
})
export class CorporateAccountPage implements OnInit {
  corporateFormData: CorporateDetailsModel = new CorporateDetailsModel();
  wearerGroups: { [key: number]: string } = {
    1: 'Wearer',
    2: 'Caregiver',
    3: 'Other',
    4: 'Management',
  };
  wearerGroupIds: string[] = Object.keys(this.wearerGroups);
  wearerNameFormat: { [key: number]: string } = {
    1: 'NAME',
    2: 'ID',
  };
  wearerNameFormatIds: string[] = Object.keys(this.wearerNameFormat);
  tempUnit = tempUnit;
  tempUnitIds = tempUnitIds;
  editModeEnable: boolean = false;
  corporateForm: FormGroup = this.fb.group({
    corporateId: [''],
    corporateName: [''],
    corporateOwnerName: [''],
    corporatePhone: [''],
    corporateOwnerTitle: [''],
    corporateEmail: [''],
    tempUnitId: [''],
  });
  validators: InputValidator[] = [];
  formConfig = {
    abstractName: {} as ValidationConfig,
    ownerName: {} as ValidationConfig,
    corpPhone: {} as ValidationConfig,
    email: {} as ValidationConfig,
  };
  corpId = null;
  title = '';
  isSaveDisabled: boolean = true;
  EndPoints = EndPointsEnum;

  constructor(
    private settingsService: SettingsService,
    private uiUtilityService: UiUtilityService,
    private storageService: StorageService,
    private fb: FormBuilder,
    private icService: InputConfigService,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.corporateForm = this.fb.group({
      corporateId: [null, Validators.required],
      corporateName: [null, Validators.required],
      corporateOwnerName: [null, Validators.required],
      corporatePhone: [null, Validators.required],
      corporateOwnerTitle: [null, Validators.required],
      corporateEmail: [null, Validators.required],
      tempUnitId: [null, Validators.required],
    });
    this.addFormValidators();
    this.corporateForm.valueChanges.subscribe((value) => {
      if (this.corporateForm.valid) {
        this.isSaveDisabled = false;
      } else {
        this.isSaveDisabled = true;
      }
    });
  }

  addFormValidators() {
    this.validators = this.icService.getValidatorList([
      InputEnum.ABSTRACTNAME,
      InputEnum.CORPPHONE,
      InputEnum.OWNERNAME,
      InputEnum.EMAIL,
    ]);
    const abstractNameConfig = this.validators.find(
      (config: any) => config.type === InputEnum.ABSTRACTNAME
    );
    if (abstractNameConfig) {
      this.corporateForm
        .get('corporateName')
        ?.setValidators(abstractNameConfig.validators);
      this.corporateForm
        .get('corporateOwnerTitle')
        ?.setValidators(abstractNameConfig.validators);
      this.formConfig.abstractName = abstractNameConfig.config;
    }
    const ownerNameConfig = this.validators.find(
      (config: any) => config.type === InputEnum.OWNERNAME
    );
    if (ownerNameConfig) {
      this.corporateForm
        .get('corporateOwnerName')
        ?.setValidators(ownerNameConfig.validators);
      this.formConfig.ownerName = ownerNameConfig.config;
    }
    const corpPhoneConfig = this.validators.find(
      (config: any) => config.type === InputEnum.CORPPHONE
    );
    if (corpPhoneConfig) {
      this.corporateForm
        .get('corporatePhone')
        ?.setValidators(corpPhoneConfig.validators);
      this.formConfig.corpPhone = corpPhoneConfig.config;
    }
    const emailConfig = this.validators.find(
      (config: any) => config.type === InputEnum.EMAIL
    );
    if (emailConfig) {
      this.corporateForm
        .get('corporateEmail')
        ?.setValidators(emailConfig.validators);
      this.formConfig.email = emailConfig.config;
    }
    this.settingsService.getCorpAccountDetails().subscribe((response) => {
      if (response.success) {
        this.corporateFormData = response.data!;
        this.corporateForm.patchValue(this.corporateFormData);
        this.corporateForm.updateValueAndValidity();
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  async saveDetails() {
    this.settingsService
      .editCorpAccountDetails(this.corporateForm.value)
      .subscribe((response) => {
        const loginData = this.storageService.getLocalStorage(
          StorageEnum.loginData,
          true
        );
        loginData.tempUnit = response.data.tempUnitId;
        this.storageService.setLocalStorage(
          StorageEnum.loginData,
          { ...loginData },
          true
        );
      });
    const header = 'Account updated';
    const message =
      'Other devices will not see the updated temperature change, if any, until you sign out and back in on those devices.';
    const buttons = [
      {
        text: 'ok',
        handler: () => {
          this.editModeEnable = !this.editModeEnable;
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  get(controlName: string): AbstractControl {
    return this.corporateForm.get(controlName)!;
  }

  backClick() {
    if (this.editModeEnable) {
      if (!this.corporateForm?.pristine) {
        const header = 'Confirm';
        const message =
          'It looks like you have been editing something. ' +
          'If you leave before saving, your changes will be lost.';
        this.showAlert(header, message);
      } else {
        this.editModeEnable = !this.editModeEnable;
      }
    } else {
      this.navigateBack();
    }
  }

  async showAlert(header: string, message: string) {
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
      },
      {
        text: 'ok',
        handler: () => {
          this.editModeEnable = !this.editModeEnable;
          this.corporateForm.patchValue(this.corporateFormData);
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  navigateBack() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  editDetails() {
    this.editModeEnable = !this.editModeEnable;
  }

  async presentAlert(
    operation: any,
    header: any,
    placeholder: any,
    value: any
  ) {
    const alert = await this.alertController.create({
      header: header,
      buttons: [
        {
          text: 'Cancel',
          role: 'dismiss',
        },
        {
          text: 'Ok',
          handler: (values: any) => {
            switch (operation) {
              case 1:
                if (
                  this.corporateForm?.get('corporateName')?.value != values[0]
                ) {
                  this.corporateForm.patchValue({
                    corporateName: values[0],
                  });
                }
                break;
              case 2:
                if (
                  this.corporateForm?.get('corporatePhone')?.value != values[0]
                ) {
                  this.corporateForm.patchValue({
                    corporatePhone: values[0],
                  });
                }
                break;

              case 3:
                if (
                  this.corporateForm?.get('corporateEmail')?.value != values[0]
                ) {
                  this.corporateForm.patchValue({
                    corporateEmail: values[0],
                  });
                }
                break;

              case 4:
                if (
                  this.corporateForm?.get('corporateOwnerName')?.value !=
                  values[0]
                ) {
                  this.corporateForm.patchValue({
                    corporateOwnerName: values[0],
                  });
                }
                break;

              case 5:
                if (
                  this.corporateForm?.get('corporateOwnerTitle')?.value !=
                  values[0]
                ) {
                  this.corporateForm.patchValue({
                    corporateOwnerTitle: values[0],
                  });
                }
                break;

              default:
                console.log('Invalid operator');
            }
            this.corporateForm?.markAsDirty();
          },
        },
      ],
      backdropDismiss: false,
      inputs: [
        {
          placeholder: placeholder,
          value: value,
        },
      ],
    });

    await alert.present();
  }
}
