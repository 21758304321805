<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">User Accounts</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToAddAccount()" [nAccess]="'postUser'">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <cdk-virtual-scroll-viewport itemSize="48" minBufferPx="900" maxBufferPx="1350">
    <ion-list class="ion-no-padding">
      <div *cdkVirtualFor="let user of userList">
        <ion-item-divider color="tertiary" *ngIf="user.isGroupHeader">
          <ion-label>{{user.title}}</ion-label>
        </ion-item-divider>
        <ion-item (click)="navigateToDetailsPage(user)" detail *ngIf="!user.isGroupHeader">
          <ion-label class="user-name">{{user.userName}}
            <ion-icon *ngIf="isCurrentUser(user)" [src]="'assets/icon/icons_User.svg'"></ion-icon>
          </ion-label>
          <ion-text slot="end">{{roles[user?.userRoleId]}}</ion-text>
        </ion-item>
      </div>
    </ion-list>
  </cdk-virtual-scroll-viewport>
  <div class="no-users" *ngIf="!userList?.length">
    No users found in the selected facility.
  </div>
</ion-content>