import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { HeaderService } from 'src/app/core/services/header.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { FacilityModel } from 'src/app/shared/models/login-response.model';
import { AssetService } from '../../services/asset.service';
import { IonNav, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AssetModel } from 'src/app/shared/models/asset-model';
import { AssetDetailsPage } from '../asset-details/asset-details.page';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { cloneDeep } from 'lodash';
import { assetFilterData } from 'src/app/shared/data/filter.data';
import { SubHeaderComponent } from 'src/app/shared/components/sub-header/sub-header.component';

@Component({
  standalone: true,
  selector: 'app-asset-list',
  templateUrl: './asset-list.page.html',
  styleUrls: ['./asset-list.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, RouterModule, SubHeaderComponent, AccessControlDirective]
})
export class AssetListPage {
  assetListCopy: AssetModel[] = [];
  assetList: AssetModel[] = [];
  searchAssetList: AssetModel[] = [];
  subscriptions: Subscription[] = [];
  groups = cloneDeep(assetFilterData);
  filters: any = {};
  searchQuery = '';

  constructor(
    private assetService: AssetService,
    private headerService: HeaderService,
    private uiUtilityService: UiUtilityService,
    private nav: IonNav,
    private authService: AuthService,
    private filterService: FilterService
  ) { }

  ionViewWillEnter() {
    this.subscriptions.push(
      this.headerService
        .getFacility()
        .subscribe((facility: FacilityModel) => this.onFacilityChange(facility))
    );
    this.getAssetList();
    this.sortAssetList();
  }

  sortAssetList() {
    const result = this.filterService.getFilterData(this.groups);
    this.filters = this.filterService.setSortFilter(result);
  }

  didSortAssetList() {
    const result = this.filterService.filteredData(
      this.assetListCopy,
      this.filters
    );
    this.assetList = result;
    this.searchAssetList = result;
  }

  async getAssetList() {
    await this.uiUtilityService.showLoader();
    this.assetService.getAssetList().subscribe((response: ApiResponseModel<AssetModel[]>) => {
      if (response && response.success && response.data) {
        this.assetListCopy = response.data?.map((item: any) => {
          if (!item.displayName) {
            item.displayName = item.assetName;
          }
          if (item.displayName) {
            item.displayName = item.displayName?.trim();
          }
          if (!item.floorId) {
            item.floorId = item.room?.floorID || null;
          }
          if (!item.assetTypeId) {
            item.assetTypeId = item.assetType?.assetTypeId || null;
          }
          return item;
        });
        this.didSortAssetList();
        // this.assetList = this.uiUtilityService.sortList(response.data, 'assetName', 1);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateToDetailsPage(asset: AssetModel) {
    if (this.authService.isFeatureEnabled('getAssetDetail')) {
      this.nav.push(AssetDetailsPage, { assetId: asset.assetId, mode: 'detail' });
    }
  }

  onFacilityChange(facility: FacilityModel) {
    const facilityId = this.headerService.getFacilityId();
    if (facility.facilityId !== facilityId) {
      this.getAssetList();
    }
  }

  navigateToAddAsset() {
    this.nav.push(AssetDetailsPage);
  }

  onFilterApply(data: any) {
    this.filters = this.filterService.setSortFilter(data);
    this.didSortAssetList();
  }

  onFilterCancel(data: any) {
    this.groups = data;
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  handleChange(event: any) {
    this.assetList = this.searchAssetList;
    this.searchQuery = event.target.value;
    if (this.searchQuery && this.searchQuery.trim() !== '') {
      this.assetList = this.assetList.filter((term: any) => term.assetName.toLowerCase().indexOf(this.searchQuery.trim().toLowerCase()) > -1);
    } else if (this.searchQuery === '') {
      this.assetList = this.searchAssetList;
    }
  }
}
