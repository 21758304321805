import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AlertController,
  IonicModule,
  SelectCustomEvent,
  ToggleCustomEvent,
} from '@ionic/angular';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { ControlType, FormError, UnifiedInput } from './unified-input.types';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputConfigService } from 'src/app/core/services/input-config.service';
import { AccessControlDirective } from '../../directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  standalone: true,
  selector: 'unified-input',
  templateUrl: './unified-custom-input.component.html',
  styleUrls: ['./unified-custom-input.component.scss'],
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, AccessControlDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnifiedCustomInputComponent implements OnInit {
  private _config!: UnifiedInput;
  @Input()
  get config() {
    return this._config;
  }
  set config(_config: UnifiedInput) {
    this._config = _config;
  }
  detail: boolean = false;

  @Output() changed = new EventEmitter<UnifiedInput>();
  readonly none = 'None';
  readonly ControlType = ControlType;
  formControl!: FormControl;

  constructor(
    private uiUtilityService: UiUtilityService,
    private cdRef: ChangeDetectorRef,
    private alertController: AlertController,
    private inputConfigService: InputConfigService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.formControl = new FormControl(this.config.value, {
      validators: this.inputConfigService.getValidatorForPrompt(this.config)
    });
    this.detail = this.config?.detail || false;
    if (this.config?.rule) {
      this.detail = this.authService.isFeatureEnabled(this.config.rule);
    }
  }

  async showDialog() {
    if (!this.config.detail) return;
    const customPromt = await this.alertController.create({
      header: this.config.title || this.config.label, // If specific title not passed show label as header
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'o-dialog-buttons',
          handler: () => { },
        },
        {
          text: 'OK',
          cssClass: 'o-dialog-buttons',
          handler: (data) => {
            if (!data) return false;
            let errorMsg = '';
            const value = this.config.name && data[this.config.name];
            this.formControl.patchValue(value);
            const errorType = this.formControl.errors as FormError;
            const errMsg = this.inputConfigService.getValidationMessage(this.config);
            if (errorType) {
              if (errorType.required) {
                errorMsg = `Please ${this.config.controlType == 'text' ? 'Enter' : 'select'} ${this.config.title || this.config.label}.`;
              } else if (
                errorType.maxlength ||
                errorType.minlength ||
                errorType.pattern
              ) {
                errorMsg = `Please enter valid ${this.config.title || this.config.label}${errMsg ? `, ${errMsg}.` : '.'}`
              }
            }
            if (errorMsg) {
              customPromt.message = errorMsg;
              return false;
            } else {
              return this.update(this.config.name && data[this.config.name]);
            }
          },
        },
      ],
      inputs: [this.config],
      cssClass: `notable-state-alert unified-custom-input-alert`,
      animated: false,
      backdropDismiss: false,
    });
    await customPromt.present();
  }

  update(value: string) {
    this.config.value = value;
    this.changed.emit(this.config);
  }

  toggleChanged(ev: Event) {
    this.config.value = (ev as ToggleCustomEvent).detail.checked;
    this.changed.emit(this.config);
  }

  dropdownChanged(dataChangeEvent: Event) {
    this.config.value = (dataChangeEvent as SelectCustomEvent).detail.value;
    this.changed.emit(this.config);
  }

  navigate() {
    const valCheck = this.config.value.trim();
    if (!this.config.detail && !valCheck) return;
    if (valCheck === this.none) return;
    if (!this.config.detail && !this.config.value) return;
    if (this.config.nav) {
      if (this.config.navParams) {
        this.uiUtilityService.menuClicked.next({
          rootPage: this.config.nav,
          rootParam: this.config.navParams,
        });
        // this.nav.push(this.config.nav, this.config.navParams);
      } else {
        this.uiUtilityService.menuClicked.next({ rootPage: this.config.nav });
        // this.nav.push(this.config.nav);
      }
    }
  }

  getClasses(config: any) {
    const temp: any = {};
    if (config.colorCodeClass && typeof config.value === 'string') {
      temp[config.colorCodeClass] = true;
      const val = config.value.toLowerCase();
      temp[val] = true;
    }
    return { ...temp, 'interactive': config.detail };
  }
}
