export enum RouterEnum {
  login = 'login',
  dashboard = 'dashboard',
  bandIntro = 'band-intro',
  bandScan = 'band-scan',
  bandConfirmation = 'band-confirmation',
  bandCompletion = 'band-completion',
  wearerList = 'wearer-list',
  wearerDetails = 'wearer-details',
  settings = 'settings',
  corporateAccount = 'corporate-account',
  facilityManagement = 'facility-management',
  facilityFloors = 'facility-floors',
  addFacility = 'add-facility',
  floorList = 'floor-list',
  userAccounts = 'user-accounts',
  addAccount = 'add-account',
  bridgeList = 'bridge-list',
  bridgeDetails = 'bridge-details',
  bridgeIntro = 'bridge-intro',
  bridgeConnect = 'bridge-connect',
  wifiList = 'wifi-list',
  forgotPassword = 'forgot-password',
  orderDevice = 'order-device',
  aboutOndo = 'about',
  resetPassword = 'reset-password',
  help = 'help',
  roomList = 'room-list',
  roomDetails = 'room-details',
  editRoom = 'edit-room',
  assignDevice = 'assign-device',
  residentDetails = 'resident-details',
  residentList = 'resident-list',
  assetList = 'asset-list',
  assetDetails = 'asset-details',
  bedList = 'bed-list',
  bedDetails = 'bed-details',
  assignItems = 'assign-items',
  removeAssignedItems = 'remove-items',
  unassignedItems = 'unassign-items',
  floorPlanLocator = 'floor-plan-locator',
  roomPlacement = 'room-placement'
}
