export class AvailableResidentsList {
    createDate = ''
    createdBy = ''
    endDate = ''
    facilityId = ''
    firstName = ''
    id = ''
    isDeleted = ''
    lastName = ''
    startDate = ''
    subWearerGroupId = ''
    updateDate = ''
    updatedBy = ''
    wearerGroupId = ''
    }

export class AssociatesResidentsToRoom {
    ID = ''
}