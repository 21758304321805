import {
  Component,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { UnifiedCustomInputComponent } from 'src/app/shared/components/unified-custom-input/unified-custom-input.component';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { SettingsService } from '../../services/settings.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { RoomPlacementPage } from '../room-placement/room-placement.page';
import { COMMON_ROOM } from 'src/app/shared/config/app.constant';

@Component({
  standalone: true,
  selector: 'app-select-room-placement',
  templateUrl: './select-room-placement.page.html',
  styleUrls: ['./select-room-placement.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedCustomInputComponent,
    UnifiedHeaderComponent
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRoomPlacementPage implements OnInit {
  roomDetails: any = {};
  roomId: string = '';
  isCommonRoom = false;

  constructor(
    private uiUtilityService: UiUtilityService,
    private navParams: NavParams,
    private settingsService: SettingsService,
    private nav: IonNav
  ) { }

  ngOnInit(): void {
    this.roomId = this.navParams.get('roomId');
  }

  didViewWillEnter() {
    this.getRoomDetails();
  }

  setRoomDetails(data: any) {
    this.roomDetails = data;
  }

  async getRoomDetails() {
    await this.uiUtilityService.showLoader();
    this.settingsService.getRoomDetails(this.roomId).subscribe((res) => {
      if (res && res.success && res.data?.roomDetail) {
        this.roomDetails = res.data.roomDetail;
        if (res.data.roomDetail?.roomTypeName === COMMON_ROOM) {
          this.isCommonRoom = true;
        }
        this.setRoomDetails(res.data.roomDetail);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(res.errorMessage!, header);
      }
    });
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  navigateBack() {
    this.nav.pop();
  }

  onPlacement(placementType: string) {
    const title = placementType === 'BED' ? 'Bed Icon' : 'Center Point';
    this.nav.push(RoomPlacementPage, { roomId: this.roomDetails.id, placementType, title });
  }

  ngOnDestroy() {

  }
}
