import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';

@Component({
  standalone: true,
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss'],
  imports: [IonicModule, CommonModule],
})
export class AboutPage implements OnInit {
  isCompTempOn = true;
  appVersion = '';

  constructor(
    private storageService: StorageService,
    private uiUtilityService: UiUtilityService
  ) { }

  ngOnInit() {
    this.appVersion = this.uiUtilityService.getVersion();
    this.isCompTempOn =
      this.storageService.getLocalStorage(StorageEnum.compTemperatureMode) ||
      this.isCompTempOn;
  }

  ionViewWillLeave() {
    this.storageService.setLocalStorage(
      StorageEnum.compTemperatureMode,
      this.isCompTempOn
    );
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }
}
