<!-- <ion-split-pane contentId="main" when="xl"> -->
<ion-menu contentId="main" type="overlay" menuId="sideMenu" id="sideMenu" class="app-side-menu"
  (ionWillOpen)="onMenuOpen()" (ionWillClose)="onMenuClose()" swipe-gesture="false"
  *ngIf="router.url !== '/login' && router.url !== '/forgot-password'">
  <ion-content color="primary">
    <ng-container *ngFor="let item of menuItems">
      <ion-item *ngIf="!item.subItems" class="menu-item interactive"
        [ngClass]="{ 'active': item.id === selectedMenuItem }" (click)="menuClick(item)">
        <ion-icon src="assets/icon/{{item.iconUrl}}" class="menu-item-icon" slot="start"> </ion-icon>
        <ion-label>{{item.name}}</ion-label>
      </ion-item>
      <ion-accordion-group #accordionGroup class="side-menu-accordion" *ngIf="item.subItems">
        <!-- [value]="item.name.toLowerCase().replace(' ', '_') -->
        <ion-accordion [value]="item.id">
          <ion-item slot="header" class="menu-item interactive">
            <ion-icon class="menu-icons menu-item-icon" src="assets/icon/{{item.iconUrl}}" slot="start"></ion-icon>
            <ion-label>{{item.name}}</ion-label>
          </ion-item>
          <div slot="content">
            <ng-container *ngFor="let subItem of item.subItems">
              <!-- [class.activated]="activeMenu(subMenu)" -->
              <!-- (click)="menuClick(subMenu.component, subMenu.confirm)" -->
              <ion-item class="menu-item interactive" [ngClass]="{ 'active': subItem.id === selectedMenuItem }"
                (click)="menuClick(subItem)">
                <ion-icon class="sub-menu-icons menu-item-icon" src="assets/icon/{{subItem.iconUrl}}"
                  slot="start"></ion-icon>
                <ion-label>{{subItem.name}}</ion-label>
              </ion-item>
            </ng-container>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ng-container>
  </ion-content>
</ion-menu>
<ion-router-outlet id="main"></ion-router-outlet>
<!-- </ion-split-pane> -->