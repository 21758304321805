<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button (click)="navigateBack()">
        <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>
    <ion-title mode="ios">Assign Resident</ion-title>
    <ion-buttons slot="end" (click)="associateResidentsToRoom()">
      <ion-button>Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
  <ion-searchbar (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar" animated
    placeholder="Type to search...">
  </ion-searchbar>
  <ion-list *ngIf="residentsLength; else noResidentsAvailable">
    <ion-radio-group [formControl]="selectResidentsControl" (ionChange)="isResidentsListChecked($event)">
      <ion-item *ngFor="let result of residentsData; let i = index">
        <ion-radio mode="md" color="controls" justify="start" labelPlacement="end" [value]="result.id">{{result.localId
          ||
          result.firstName}}</ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
  <ng-template #noResidentsAvailable>
    <div class="ion-text-center">
      <p>No residents available right now.</p>
    </div>
  </ng-template>
</ion-content>