<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Bridge Management</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToAddBridge()" [nAccess]="'postBridge'">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="bridgeList?.length">
    <ion-item *ngFor="let bridge of bridgeList" (click)="navigateToDetailsPage(bridge)" detail class="list-with-name">
      <ion-label>
        <h3>{{bridge.bridgeName}}</h3>
      </ion-label>
      <ion-label slot="end" class="no-margin-right end-name" *ngIf="bridge.roomName">
        <h3>
          <span>{{bridge.roomName}}</span>
        </h3>
      </ion-label>
      <ion-label slot="end">
        <h3>
          <span *ngIf="bridge.roomName" class="seperator">-</span><span class="bridge-status"
            [ngClass]="bridge.bridgeStatus === 1 ? 'online' : 'offline'">{{bridge.appBridgeStatus}}</span>
        </h3>
      </ion-label>
    </ion-item>
  </div>
  <div class="no-bridge" *ngIf="!bridgeList?.length">
    No bridges found in the selected facility.
  </div>
</ion-content>