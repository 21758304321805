import { ChangeDetectorRef, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WearerService } from 'src/app/features/wearer-management/services/wearer.service';
import { BandEnum, BandPageFlowEnum } from 'src/app/shared/enums/band.enum';
import { BandAssociationService } from '../../services/band-association.service';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { Capacitor } from '@capacitor/core';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { BandScanPage } from '../band-scan/band-scan.page';

@Component({
  standalone: true,
  selector: 'app-p',
  templateUrl: './band-intro.page.html',
  styleUrls: ['./band-intro.page.scss'],
  imports: [IonicModule, CommonModule, RouterModule],
  providers: [BLE],
})
export class BandIntroPage {
  title = BandEnum.BandAssociation;
  wearerId = this.wearerService.activeWearerDetails.residentDetail.id;
  bluetoothEnabled = false;
  canGoBack = false;
  isBandLookUpFlow = false;

  constructor(
    private bandService: BandAssociationService,
    private wearerService: WearerService,
    private ble: BLE,
    private cdr: ChangeDetectorRef,
    private ionNav: IonNav,
    private navParams: NavParams,
    private uiUtilityService: UiUtilityService,
    private nav: IonNav
  ) { }

  ionViewWillEnter() {
    const bandFlow = this.navParams.get('flow') || '';
    this.bandService.bandFlow = bandFlow || '';
    this.title = this.bandService.bandFlowTitle(bandFlow);
    if (Capacitor.isNativePlatform()) {
      this.checkBluetooth();
      this.startNotifications();
    }
    this.setInitValues();
  }

  async setInitValues() {
    this.canGoBack = await this.nav.canGoBack();
    const bandFlow = this.navParams.get('flow') || '';
    this.isBandLookUpFlow = bandFlow === BandPageFlowEnum.BandLookup;
  }

  /**
   * method to check bluetooth stats
   * iOS bluetooth stats
   * @"unknown",
   * @"resetting",
   * @"unsupported",
   * @"unauthorized",
   * @"off",
   * @"on"
   */
  startNotifications() {
    this.ble.startStateNotifications().subscribe((stat) => {
      this.bluetoothEnabled = stat === 'on';
      if (this.bluetoothEnabled) {
        this.cdr.detectChanges();
      }
    });
  }

  async isBluetoothEnabled(): Promise<boolean> {
    return this.ble.isEnabled().then(
      (success) => {
        return true;
      },
      (failure) => {
        if (failure > 0) {
          return false;
        }
        return this.isBluetoothEnabled();
      }
    );
  }

  /**
   * Check weather device's bluetooth is enable or not
   */
  checkBluetooth(): void {
    this.isBluetoothEnabled()
      .then((isEnabled) => {
        if (isEnabled) {
          this.bluetoothEnabled = true;
        } else {
          this.bluetoothEnabled = false;
        }
      })
      .catch((e) => {
        this.bluetoothEnabled = false;
      });
  }

  navigateBack() {
    this.ionNav.pop();
  }

  onStart() {
    this.bandService.resetBandStatus();
    const param = this.navParams.data;
    this.ionNav.push(BandScanPage, { ...param, title: this.title });
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  ionViewWillLeave() {
    if (Capacitor.isNativePlatform()) {
      this.ble.stopStateNotifications().then((stopped) => {
        console.log('ble stopStateNotifications', stopped);
      });
    }
  }
}
