import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/services/api/data.service';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CorporateDetailsModel } from 'src/app/shared/models/corporate-details.model';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { AddRoomDetails } from 'src/app/shared/models/addRoomModel';
import { RoomDetails } from 'src/app/shared/models/roomDetailsModel';

import { AvailableResidentsList } from 'src/app/shared/models/availableResidentsList';
import { ResidentDetails } from 'src/app/shared/models/resdientDetailsModel';
import { RoomListModel } from '../../wearer-management/pages/select-room/select-room.page';
import { BedListModel } from '../../bed-management/pages/select-bed/select-bed.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { HeaderService } from 'src/app/core/services/header.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    public dataService: DataService<any>,
    public rommManagementDataService: DataService<AddRoomDetails>,
    public roomDetailsService: DataService<RoomDetails>,
    public availableResidentsDataService: DataService<AvailableResidentsList>,
    public residentsDataService: DataService<any>,
    public residentDetailsModel: DataService<ResidentDetails>,
    private authService: AuthService,
    private headerService: HeaderService
  ) { }

  getCorpAccountDetails(): Observable<ApiResponseModel<CorporateDetailsModel>> {
    const { corpId } = this.authService.getLoginData;
    // const corpId = 'bbbfabea-7044-4cca-b4e0-8d8a2497f287';
    const url = `${environment.apiUrl.corporate}${EndPointsEnum.getCorpAccount}?corpId=${corpId}`;
    return this.dataService.getData(url);
  }

  addCorpAccountDetails(payload: CorporateDetailsModel): Observable<any> {
    const url = `${environment.apiUrl.corporate}${EndPointsEnum.addCorpAccount}`;
    return this.dataService.post(url, payload);
  }

  editCorpAccountDetails(payload: CorporateDetailsModel): Observable<any> {
    const url = `${environment.apiUrl.corporate}${EndPointsEnum.editCorpAccount}`;
    return this.dataService.put(url, payload);
  }

  getRoomTypeDetails(): Observable<ApiResponseModel<any>> {
    // const { corpId } = this.authService.getLoginData;
    const url = `${environment.apiUrl.roomtype}${EndPointsEnum.getRoomType}`;
    return this.dataService.getData(url);
  }

  getAssetTypes(): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.assetManagement}getAssetTypes`;
    return this.dataService.getData(url);
  }

  getFloorListDetails(): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.managefloors}${FACILITY_ID_URL}${EndPointsEnum.getFacilityFloorList}`;
    return this.dataService.getData(url);
  }

  getRoomListByFacilityId(): Observable<ApiResponseModel<RoomListModel[]>> {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}/${EndPointsEnum.getRoomList}`;
    return this.dataService.getData(url);
  }

  getBedRoomList(): Observable<ApiResponseModel<RoomListModel[]>> {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}/${EndPointsEnum.getRoomList
      }${'?bedroom=1'}`;
    return this.dataService.getData(url);
  }

  getBedList(roomId: any): Observable<ApiResponseModel<BedListModel[]>> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/${EndPointsEnum.getAvailableBeds}/${roomId}`;
    return this.dataService.getData(url);
  }

  saveRoomManagementDetails(
    payload: any,
    floorID: any
  ): Observable<any> {
    payload.facilityID = this.headerService.getFacilityId();
    const url = `${environment.apiUrl.roomManagement}${floorID}/${EndPointsEnum.manage}`;
    return this.rommManagementDataService.post(url, payload);
  }

  editRoomManagementDetails(
    payload: any,
    floorID: any
  ): Observable<any> {
    payload.facilityID = this.headerService.getFacilityId();
    const url = `${environment.apiUrl.roomManagement}${floorID}/${EndPointsEnum.manage}`;
    return this.rommManagementDataService.put(url, payload);
  }

  getSubtype(): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.roomtype}${EndPointsEnum.getRoomSubtype}`;
    return this.dataService.getData(url);
  }

  getRoomDetails(roomID: any, params = {}): Observable<ApiResponseModel<RoomDetails>> {
    const url = `${environment.apiUrl.roomManagement}${roomID}${EndPointsEnum.getRoomDetail}`;
    return this.roomDetailsService.getData(url, params);
  }

  getAvailableResidentsListByFacilityId(): Observable<
    ApiResponseModel<AvailableResidentsList>
  > {
    const url = `${environment.apiUrl.residentManagement
      }${FACILITY_ID_URL}${'/'}${EndPointsEnum.getAvailableResidentList}`;
    return this.availableResidentsDataService.getData(url);
  }

  associatesResidentsToRoom(
    rwesidentsID: any[] = [],
    roomID: any
  ): Observable<any> {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}${'/'}${EndPointsEnum.addResidentToRoom
      }${'/'}${roomID}`;
    return this.residentsDataService.post(url, rwesidentsID);
  }

  removeAssosciatedResidents(
    residentsID: any[] = [],
    roomID: any
  ): Observable<any> {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}${'/'}${EndPointsEnum.removeResidentToRoom
      }${'/'}${roomID}`;
    return this.residentsDataService.post(url, residentsID);
  }

  getResidentDetails(
    residentID: any
  ): Observable<ApiResponseModel<ResidentDetails>> {
    const url = `${environment.apiUrl.residentManagement}${residentID}${'/'}${EndPointsEnum.getResidentDetails
      }`;
    return this.residentDetailsModel.getData(url);
  }

  getWearerResidentDetails(residentID: any): Observable<any> {
    const url = `${environment.apiUrl.residentManagement}${residentID}${'/'}${EndPointsEnum.getResidentDetails
      }`;
    return this.residentDetailsModel.getData(url);
  }

  removeRoomToResident(roomID: any, residentsID: any[] = []) {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}${'/'}${EndPointsEnum.removeResidentToRoom
      }${'/'}${roomID}`;
    return this.residentsDataService.post(url, residentsID);
  }

  updateWearerDetails(wearerDetails: any) {
    const url = `${environment.apiUrl.residentManagement
      }${FACILITY_ID_URL}${'/'}${EndPointsEnum.manage}`;
    return this.residentsDataService.put(url, wearerDetails);
  }

  deleteRoom(roomDetails: any) {
    const url = `${environment.apiUrl.roomManagement}${EndPointsEnum.manageRoom}`;
    return this.residentsDataService.delete(url, roomDetails);
  }

  getResidentListByRoom(
    roomId: any
  ): Observable<ApiResponseModel<ResidentDetails>> {
    const params: any = { ROOMID: roomId };
    const url = `${environment.apiUrl.residentManagement}${FACILITY_ID_URL}/getAvailableResidentList`;
    return this.residentDetailsModel.getData(url, params);
  }

  postBedToResident(bedId: any, residentId: any) {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/assignResidentToBed/${bedId}/${residentId}`;
    return this.residentDetailsModel.getData(url);
  }

  removeBedFromResident(bedId: any) {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/removeResidentToBed/${bedId}`;
    return this.residentDetailsModel.getData(url);
  }

  removeAssetToRoom(roomId: any, payload: any) {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}/removeAssetToRoom/${roomId}`;
    return this.dataService.post(url, payload);
  }

  removeBedToRoom(roomId: any, payload: any) {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}/removeBedToRoom/${roomId}`;
    return this.dataService.post(url, payload);
  }

  addAssetToRoom(roomId: any, payload: any) {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}/addAssetToRoom/${roomId}`;
    return this.dataService.post(url, payload);
  }

  addBedToRoom(roomId: any, payload: any) {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}/addBedToRoom/${roomId}`;
    return this.dataService.post(url, payload);
  }

  getAvailableAssets() {
    const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}/getAvailableAsset`;
    return this.dataService.getData(url);
  }

  getAvailableBeds() {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/getAvailableBeds`;
    return this.dataService.getData(url);
  }

  getFloorDetails(floorId: string): Observable<ApiResponseModel<any[]>> {
    const url = `${environment.apiUrl.corporate}${FACILITY_ID_URL}/${EndPointsEnum.manageFloors}?floorID=${floorId}`;
    return this.dataService.getData(url);
  }

  updateRoomPlacement(roomId: string, payload: any) {
    const url = `${environment.apiUrl.roomManagement}${roomId}/updatePlacement`;
    return this.dataService.post(url, payload);
  }
}
