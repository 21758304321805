import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/core/services/api/data.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { FacilityModel } from 'src/app/shared/models/facility.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  public facilityData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public facilityDataChanged = this.facilityData.asObservable();

  constructor(
    private authService: AuthService,
    private dataService: DataService<FacilityModel[]>,
    private headerService: HeaderService
  ) { }

  get corporateId() {
    return this.authService.getLoginData.corpId;
  }

  getFacilityList() {
    const corpId = this.corporateId;
    const url = `${environment.apiUrl.corporate}${EndPointsEnum.facilityList}?corpId=${corpId}`;
    return this.dataService.getData(url);
  }

  getFacilityDetails() {
    const facilityId = this.headerService.getFacilityId();
    const url = `${environment.apiUrl.corporate}${EndPointsEnum.facilityDetail}?facilityId=${facilityId}`;
    return this.dataService.getData(url);
  }

  filterFacilityList(
    wearerList: FacilityModel[],
    sortingType: string,
    order: number
  ): FacilityModel[] {
    return wearerList.sort((a, b) => {
      if (
        (a as any)[sortingType]?.toLowerCase() <
        (b as any)[sortingType]?.toLowerCase()
      ) {
        return -order;
      }
      if (
        (a as any)[sortingType]?.toLowerCase() >
        (b as any)[sortingType]?.toLowerCase()
      ) {
        return order;
      }
      return 0;
    });
  }

  /**
   * Subscriber to check/update if facility data has been updated.
   */
  updateFacilityHeader(changed: boolean = false) {
    this.facilityData.next(changed);
  }

  getFacilityFloorList() {
    const url = `${environment.apiUrl.corporate}${FACILITY_ID_URL}/${EndPointsEnum.getFacilityFloorsList}`;
    return this.dataService.getData(url);
  }

  getFacilityFloorListById(facilityId: string) {
    const url = `${environment.apiUrl.corporate}${facilityId}/${EndPointsEnum.getFacilityFloorsList}`;
    return this.dataService.getData(url);
  }
}
