<app-unified-header [title]="!bridgeId ? 'Add Bridge' : isReadMode ? 'Bridge Details' : 'Edit Bridge'"
  (onSave)="manageBridge()" defaultHref="/bridge-list" [restrictBack]="bridgeDetailsForm && !bridgeDetailsForm.pristine"
  [saveBtn]="{title: bridgeId && isReadMode ? 'Edit' : 'Save', isDisable: bridgeId && isReadMode ? false : isSaveDisabled, rule: bridgeId ? 'putBridge' : ''}"
  [isRootPage]="isRootPage"></app-unified-header>

<ion-content *ngIf="bridgeDetailsForm">
  <form [formGroup]="bridgeDetailsForm">
    <ng-container *ngFor="let config of bridgeDetailsInputConfig">
      <ng-container *ngIf="!config.readOnly || (config.readOnly && bridgeId && isReadMode)">
        <unified-input ngDefaultControl [formControlName]="config.fieldName || null" [config]="getConfig(config)"
          (changed)="inputChanged($event)"></unified-input>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="bridgeId">
      <ng-container *ngIf="isReadMode && bridgeDetails.placementInfo?.placeMentType">
        <ion-list-header class="detail-header">
          <ion-label>Placement</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>{{bridgeDetails.placementInfo.placeMentType}}</ion-label>
          <ion-text slot="end" [class.app-link]="placementTypeValue && isReadMode && isPlacementAllowNav"
            [class.no-link]="!isSummaryEnabled" [class.none-value]="!placementTypeValue"
            (click)="onPlacementDetails(bridgeDetails.placementInfo)">{{placementTypeValue ||
            'None'}}</ion-text>
        </ion-item>
      </ng-container>
      <ng-container *ngIf="!isReadMode">
        <br>
        <ion-item detail (click)="setPlacement()">
          <ion-label>Placement</ion-label>
          <ion-text slot="end" [class.none-value]="!f['placementName']?.value">{{f['placementName']?.value ||
            'None'}}</ion-text>
        </ion-item>
        <ion-item detail (click)="setRoom()" *ngIf="f['placementId']?.value === '1'">
          <ion-label>Room</ion-label>
          <ion-text slot="end" [class.none-value]="!f['roomName']?.value">{{f['roomName']?.value ||
            'None'}}</ion-text>
        </ion-item>
        <ion-item detail (click)="setBed()" *ngIf="f['placementId']?.value === '2'">
          <ion-label>Bed</ion-label>
          <ion-text slot="end" [class.none-value]="!f['bedName']?.value">{{f['bedName']?.value ||
            'None'}}</ion-text>
        </ion-item>
        <ion-item detail (click)="setFloor()" *ngIf="f['placementId']?.value === '3'"
          [nAccess]="EndPoints.getFacilityFloorsList">
          <ion-label>Floor</ion-label>
          <ion-text slot="end" [class.none-value]="!f['floorName']?.value">{{f['floorName']?.value ||
            'None'}}</ion-text>
        </ion-item>
      </ng-container>
      <ng-container>
        <ion-item>
          <ion-label>Use for location</ion-label>
          <ion-toggle mode="ios" color="controls" slot="end" [checked]="f['useForLocation']?.value" *ngIf="!isReadMode"
            (ionChange)="useLocToggleChanged($event)"></ion-toggle>
          <div slot="end" class="custom-input" *ngIf="isReadMode">
            <ion-input [value]="f['useForLocation']?.value ? 'Yes' : 'No'" readonly></ion-input>
          </div>
        </ion-item>
      </ng-container>
      <ng-container *ngIf="floorImage && (f['placementId']?.value === '1' || f['placementId']?.value === '3')">
        <br>
        <div class="coordinates" *ngIf="!isReadMode">
          <ion-label>Coordinates</ion-label>
          <div class="coordinate-actions">
            <ion-input fill="outline" placeholder="X Value" formControlName="xvalue" type="number" min="0"></ion-input>
            <ion-input fill="outline" placeholder="Y Value" formControlName="yvalue" type="number" min="0"></ion-input>
            <ion-button (click)="preview()">Preview</ion-button>
          </div>
        </div>
        <div class="floor-image">
          <ion-label *ngIf="!isReadMode" class="placement-info">Or tap on the map below to place the bridge <ion-icon
              src="assets/icon/icons_placement.svg"></ion-icon></ion-label>
          <div class="floor-image-container">
            <div id="plot" class="plan-image-container" #plotElement>
              <img [src]="floorImage" (click)="plotBridgeLocation($event, null)" [alt]="'Plan Image'" />
            </div>
          </div>
          <div class="ion-text-center floor-name">
            {{f['floorName']?.value}}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
  <div class="delete-bridge" *ngIf="bridgeId && isReadMode">
    <ion-button color="danger" (click)="deleteBridge()" [nAccess]="'deleteBridge'">Delete This Bridge</ion-button>
  </div>
</ion-content>