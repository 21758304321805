import { HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { UserRolesEnum } from 'src/app/shared/enums/user-roles.enum';
import { LoginResponseModel } from 'src/app/shared/models/login-response.model';
import { environment } from 'src/environments/environment';
import { DataService } from './api/data.service';
import { StorageService } from './storage.service';
import { MenuController, ModalController, PopoverController } from '@ionic/angular';
import { PERMISSIONS } from 'src/app/shared/config/app.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isRefreshingToken = false;

  constructor(
    private storageService: StorageService,
    private dataService: DataService<any>,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private menuCtrl: MenuController
  ) { }

  /**
   * Get Login data
   */
  get getLoginData(): LoginResponseModel {
    return (
      this.storageService.getLocalStorage(StorageEnum.loginData, true) ||
      new LoginResponseModel()
    );
  }

  /**
   * Get auth token
   */
  get authToken() {
    return this.storageService.getLocalStorage(StorageEnum.authToken);
  }

  get compTemperatureMode() {
    return this.storageService.getLocalStorage(StorageEnum.compTemperatureMode);
  }

  /**
   * Get user role
   */
  get getUserRole() {
    const loginData: LoginResponseModel = this.getLoginData;
    switch (+loginData.userRole) {
      case 1:
        return UserRolesEnum.Corporate_Admin;
      case 2:
        return UserRolesEnum.Facility_Admin;
      case 3:
        return UserRolesEnum.Facility_Staff;
      default:
        return UserRolesEnum.Facility_Staff;
    }
  }

  /**
   * Check weather user is logged in or not
   */
  get isUserLoggedIn() {
    return this.authToken ? true : false;
  }

  /**
   * Get refresh token
   */
  get refreshToken() {
    return this.storageService.getLocalStorage(StorageEnum.refreshToken);
    // return this.storageService.getCookie(StorageEnum.refreshToken);
  }

  clearStorage() {
    const storageKeyToClear = [
      StorageEnum.loginData,
      StorageEnum.authToken,
      StorageEnum.compTemperatureMode,
      StorageEnum.facilityWifi,
      StorageEnum.refreshToken
    ];
    sessionStorage.clear();
    storageKeyToClear.forEach((storeKey) =>
      this.storageService.removeLocalStorage(storeKey)
    );
  }

  async hideOverlays() {
    if (this.popoverCtrl) {
      try {
        let topLoader = await this.popoverCtrl?.getTop();
        while (topLoader) {
          await topLoader.dismiss();
          topLoader = await this.popoverCtrl.getTop();
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (this.modalCtrl) {
      try {
        let topLoader = await this.modalCtrl?.getTop();
        while (topLoader) {
          await topLoader.dismiss();
          topLoader = await this.modalCtrl.getTop();
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (this.menuCtrl && await this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }
  }

  logout() {
    // to do write api call to invalidate jwt
    this.hideOverlays();
    const refreshToken = this.storageService.getLocalStorage(
      StorageEnum.refreshToken
    );
    const authToken = this.storageService.getLocalStorage(
      StorageEnum.authToken
    );
    const url = `${environment.apiUrl.auth}${EndPointsEnum.logout}`;
    return this.dataService.post(url, {
      accessToken: authToken,
      refreshToken: refreshToken,
      userId: this.storageService.getLocalStorage(StorageEnum.loginData, true)
        ?.userId,
      pnToken: this.storageService.getLocalStorage(
        StorageEnum.pushNotificationToken
      ),
    });
  }

  getAccessTokenUsingRefreshToken(): Observable<any> {
    const refreshToken = this.storageService.getLocalStorage(
      StorageEnum.refreshToken
    );
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${refreshToken}`,
        'X-TenantID': environment.clientCode.toLowerCase(),
      }),
    };
    const baseUrl = environment.baseUrl;
    return this.dataService.post(
      `${EndPointsEnum.accessToken}`,
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
      httpOptions
    );
  }

  getNewAccessTokenBefore401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      return this.getAccessTokenUsingRefreshToken().pipe(
        switchMap((token: any) => {
          const accessToken = token?.data?.accessToken;
          this.storageService.setLocalStorage(
            StorageEnum.authToken,
            accessToken
          );
          this.isRefreshingToken = false;
          return next.handle(request);
        })
      );
    } else {
      return next.handle(request);
    }
  }

  getJwtTokenExpiryTime(token: string) {
    return JSON.parse(atob(token.split('.')[1])).exp * 1000;
  }

  isFeatureEnabled(rule: any) {
    if (!PERMISSIONS[rule]) {
      return true;
    }
    const { code, action } = PERMISSIONS[rule];
    if (!code) {
      return true;
    }
    const transactions = this.getLoginData?.transactions || [];
    return transactions.find((item: any) => item.transactionCode === code && item.httpmethod === action) ? true : false;
  }
}
