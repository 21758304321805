import {
  Component,
  DestroyRef,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule, ModalController, Platform, NavParams, IonNav, MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import {
  InputValidator,
} from 'src/app/core/services/input-config.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { BedService } from '../../services/bed.service';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { bedDetailsInputConfig } from './bed-details-input.config';
import { UnifiedInput } from 'src/app/shared/components/unified-custom-input/unified-input.types';
import { UnifiedCustomInputComponent } from 'src/app/shared/components/unified-custom-input/unified-custom-input.component';
import { cloneDeep } from 'lodash-es';
import { UnifiedSelectComponent } from 'src/app/shared/components/unified-select/unified-select.component';
import { SettingsService } from 'src/app/features/settings/services/settings.service';
import { BedDetailModel } from 'src/app/shared/models/bed-modal';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { WearerDetailsPage } from 'src/app/features/wearer-management/pages/wearer-details/wearer-details.page';
import { RoomDetailsPage } from 'src/app/features/settings/room-management/room-details/room-details.page';
import { BridgeDetailsPage } from 'src/app/features/bridge-management/pages/bridge-details/bridge-details.page';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  standalone: true,
  selector: 'app-bed-details',
  templateUrl: './bed-details.page.html',
  styleUrls: ['./bed-details.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedCustomInputComponent,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BedDetailsPage implements OnInit, OnDestroy {
  @Input('bedId') bedId: string = '';
  @Input('mode') mode: string = 'detail';
  isRootPage: boolean = false;
  destroyRef = inject(DestroyRef);
  bedDetails: BedDetailModel | null = null;
  bedDetailsForm: FormGroup | null = null;
  isSaveDisabled = true;
  subscriptions: Subscription = new Subscription();
  validator: InputValidator | null = null;
  bedDetailsInputConfig: UnifiedInput[] = cloneDeep(
    bedDetailsInputConfig
  );
  backButtonClicked: boolean = false;
  isReadMode = true;
  rooms: any[] = [];
  subscription: any;
  isRoomSummaryEnabled = true;
  isWearerSummaryEnabled = true;
  isBridgeSummaryEnabled = true;

  constructor(
    private bedService: BedService,
    private formBuilder: FormBuilder,
    private uiUtilityService: UiUtilityService,
    private modalCtrl: ModalController,
    private settingsService: SettingsService,
    private platform: Platform,
    private navParams: NavParams,
    private nav: IonNav,
    private menuCtrl: MenuController,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const bedId = this.navParams.get('bedId');
    const mode = this.navParams.get('mode');
    this.didOnInit(bedId, mode);
    this.isRoomSummaryEnabled = this.authService.isFeatureEnabled('getRoomDetail');
    this.isWearerSummaryEnabled = this.authService.isFeatureEnabled('getWearerDetail');
    this.isBridgeSummaryEnabled = this.authService.isFeatureEnabled('getBridgeDetail');
  }

  didOnInit(assetId: string, mode: string) {
    this.bedId = assetId;
    this.mode = mode;
    this.isReadMode = this.mode === 'edit' ? false : true;
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribeWithPriority(10, () => {
      console.log('Hardware back clicked');
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  didViewWillEnter() {
    this.isSaveDisabled = true;
    if (this.bedId) {
      this.getBedDetails();
    } else {
      this.setConfig('detail', true);
      const defaultValues = this.uiUtilityService.getValuesFromUnifiedInput(bedDetailsInputConfig);
      this.createForm(defaultValues);
      this.subscribeForm();
    }
  }

  subscribeForm() {
    this.bedDetailsForm?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.bedDetailsForm) {
          this.isSaveDisabled =
            this.bedDetailsForm.invalid || !this.bedDetailsForm.dirty;
        }
      });
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  setBedDetails(data: any) {
    this.bedDetails = data;
    if (this.bedDetails?.id) {
      this.setConfig('', '', this.bedDetails);
      this.setConfig('detail', !this.isReadMode);
    }
    this.createForm(data);
    this.subscribeForm();
    this.isSaveDisabled = false;
  }

  async getBedDetails() {
    await this.uiUtilityService.showLoader();
    this.bedService.getBedDetails(this.bedId).subscribe((response: ApiResponseModel<BedDetailModel>) => {
      if (response && response.success && response.data) {
        this.setBedDetails(response.data);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  setConfig(key: string, value: any, data?: any) {
    this.bedDetailsInputConfig.forEach((item: any) => {
      if (data) {
        item.value = data[item.name];
      } else {
        item[key] = value;
      }
    });
  }

  createForm(bedDetails: any) {
    this.bedDetailsForm = this.formBuilder.group({
      bedName: [
        bedDetails?.bedName,
        [Validators.required],
      ],
      bedId: [
        bedDetails?.bedId
      ],
      connexioEnabled: [bedDetails?.connexioEnabled],
      ublInstalled: [bedDetails?.ublInstalled],
      roomName: [bedDetails?.room?.roomName],
      residentName: [bedDetails?.resident?.localId || (bedDetails?.resident?.firstName ? `${bedDetails?.resident?.firstName} ${bedDetails?.resident?.lastName}` : '')],
      bridgeName: [bedDetails?.bridge?.bridgeName]
    });
  }

  inputChanged(inputConfig: UnifiedInput) {
    if (this.bedDetailsForm && inputConfig.name) {
      const found = this.bedDetailsInputConfig.find((item: any) => item.name === inputConfig.name);
      if (found) {
        found.value = inputConfig.value;
        this.bedDetailsForm.markAsDirty();
        this.bedDetailsForm.controls[inputConfig.name].patchValue(
          inputConfig.value
        );
        if (inputConfig.name === 'connexioEnabled' && !inputConfig.value) {
          this.bedDetailsForm.controls['ublInstalled'].setValue(false);
        }
      }
    }
  }

  async showSelectModal({ title, emptyMessage, key, displayName, value, list, isSave }: any) {
    const modal = await this.modalCtrl.create({
      component: UnifiedSelectComponent,
      componentProps: { data: { title, emptyMessage, key, displayName, value, list }, isSave }
    });
    modal.present();
    return await modal.onWillDismiss();
  };

  onAssignRoomToBed = async (room: any) => {
    this.bedDetailsForm?.controls['roomName'].patchValue(
      room.roomName
    );
    await this.uiUtilityService.showLoader();
    this.bedService.assignRoomToBed(this.bedId, room.id).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.uiUtilityService.showToast('The bed details saved successfully.');
        this.navigateBack(true);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  assignRoomToBed(room: any) {
    if (room?.id && this.bedDetails?.room?.roomId && room.id === this.bedDetails.room.roomId) {
      return;
    }
    if (this.bedDetails?.room?.roomId) {
      const message =
        'Please note changing or removing the room will clear the resident selection.';
      const header = 'Confirm';
      this.uiUtilityService.presentConfirm(message, () => this.onAssignRoomToBed(room), undefined, header, 'Ok');
    } else {
      this.onAssignRoomToBed(room);
    }
  }

  onRemoveRoomToBed = async () => {
    await this.uiUtilityService.showLoader();
    this.bedService.removeRoomToBed(this.bedId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.uiUtilityService.showToast('The bed details saved successfully.');
        this.navigateBack(true);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  async didSelectRoomModal() {
    const { data, role } = await this.showSelectModal({ title: 'Select Room', emptyMessage: 'No rooms available right now.', key: 'id', displayName: 'roomName', value: this.bedDetails?.room?.roomId || '', list: this.rooms, isSave: true });
    if (role === 'confirm') {
      if (data !== '') {
        const room = this.rooms.find((item: any) => item.id === data);
        if (room) {
          this.assignRoomToBed(room);
        }
      } else {
        const message =
          'Please note changing or removing the room will clear the resident selection.';
        const header = 'Confirm';
        this.uiUtilityService.presentConfirm(message, this.onRemoveRoomToBed, undefined, header, 'Ok');
      }
    }
  }

  async setRoom() {
    if (this.bedId && this.isReadMode) {
      if (this.f['roomName']?.value && this.bedDetails?.room?.roomId && this.isRoomSummaryEnabled) {
        this.nav.push(RoomDetailsPage, { id: this.bedDetails?.room?.roomId });
      }
      return;
    }
    if (this.rooms.length) {
      this.didSelectRoomModal();
    } else {
      await this.uiUtilityService.showLoader();
      this.settingsService.getBedRoomList().subscribe((response: any) => {
        if (response && response.success && response.data) {
          const none = {
            id: '',
            roomName: 'None'
          };
          this.rooms = response.data?.length ? [none, ...response.data] : [];
          this.didSelectRoomModal();
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
    }
  }

  async assignResidentToBed(residentId: any) {
    if (residentId && this.bedDetails?.resident?.residentId && residentId === this.bedDetails.resident.residentId) {
      return;
    }
    await this.uiUtilityService.showLoader();
    this.bedService.assignResidentToBed(this.bedId, residentId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.uiUtilityService.showToast('The bed details saved successfully.');
        this.navigateBack(true);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  async removeResidentToBed() {
    await this.uiUtilityService.showLoader();
    this.bedService.removeResidentToBed(this.bedId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.uiUtilityService.showToast('The bed details saved successfully.');
        this.navigateBack(true);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  async didSelectResidentModal(list: any) {
    const formattedList = list.map((item: any) => ({ ...item, name: item.name || item.localId || `${item.firstName} ${item.lastName}` }));
    const { data, role } = await this.showSelectModal({ title: 'Select Resident', emptyMessage: 'No residents available for the room.', key: 'id', displayName: 'name', value: this.bedDetails?.resident?.residentId || '', list: formattedList, isSave: true });
    if (role === 'confirm') {
      if (data !== '') {
        const resident = formattedList.find((item: any) => item.id === data);
        this.bedDetailsForm?.controls['residentName'].patchValue(resident.name);
        this.assignResidentToBed(data);
      } else {
        this.bedDetailsForm?.controls['residentName'].patchValue(null);
        this.removeResidentToBed();
      }
    }
  }

  async setResident() {
    if (!this.bedDetails?.room?.roomId) {
      return;
    }
    if (this.bedId && this.isReadMode) {
      if (this.f['residentName']?.value && this.bedDetails?.resident?.residentId && this.isWearerSummaryEnabled) {
        this.nav.push(WearerDetailsPage, { id: this.bedDetails?.resident?.residentId });
      }
      return;
    }
    await this.uiUtilityService.showLoader();
    this.settingsService.getResidentListByRoom(this.bedDetails.room.roomId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        const none = {
          id: '',
          name: 'None'
        };
        // const list = response.data?.length ? [none, ...response.data] : [];
        const list = [none, ...response.data];
        this.didSelectResidentModal(list);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  async manageBed() {
    if (this.bedId && this.isReadMode) {
      this.nav.push(BedDetailsPage, { bedId: this.bedId, mode: 'edit' });
      return;
    }
    if (this.bedDetailsForm?.invalid) {
      return;
    }
    const payload = this.bedDetailsForm?.value;
    if (!payload.connexioEnabled) {
      payload.ublInstalled = false;
    }
    let apiRef: any;
    if (this.bedId) {
      payload.id = this.bedId;
      apiRef = this.bedService.updateBed(payload);
    } else {
      apiRef = this.bedService.createBed(payload);
    }
    await this.uiUtilityService.showLoader();
    apiRef.subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.uiUtilityService.showToast('The bed details saved successfully.');
        if (!this.bedId && response.data?.id) {
          this.bedDetailsForm?.markAsPristine();
          this.didOnInit(response.data.id, 'detail');
          this.didViewWillEnter();
        } else {
          this.navigateBack();
        }
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateBack(isRestrict = false) {
    if (!(this.bedId && isRestrict && !this.bedDetailsForm?.pristine)) {
      this.nav.pop();
    }
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    }
  }

  onNavBack() {
    if (this.isRootPage) {
      this.dismiss();
    } else {
      this.nav.pop();
    }
  }

  onConfirmDeleteBed = async () => {
    await this.uiUtilityService.showLoader();
    this.bedService.deleteBed(this.bedId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.onNavBack();
        this.uiUtilityService.showToast('The bed deleted successfully.');
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  deleteBed() {
    const message =
      'You are about to delete this bed. This action cannot be undone.';
    const header = 'Delete This Bed';
    this.uiUtilityService.presentConfirm(message, this.onConfirmDeleteBed, undefined, header, 'Delete');
  }

  getConfig(config: UnifiedInput) {
    return { ...config };
  }

  get f() { return this.bedDetailsForm?.controls || {}; }

  setBridge() {
    if (this.bedDetails?.bridge?.bridgeId && this.isBridgeSummaryEnabled) {
      this.nav.push(BridgeDetailsPage, { bridgeId: this.bedDetails.bridge.bridgeId, mode: 'detail' });
    }
  }

  onLightInstalledChange(event: any) {
    const val = event.detail.checked;
    this.bedDetailsForm?.controls['ublInstalled'].setValue(val);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
