import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ENTITY_ASSET, ENTITY_BED } from 'src/app/shared/config/app.constant';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { SettingsService } from '../../services/settings.service';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';

@Component({
  standalone: true,
  selector: 'app-remove-list',
  templateUrl: './remove-list.page.html',
  styleUrls: ['./remove-list.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, RouterModule, UnifiedHeaderComponent],
})
export class RemoveListPage {
  title: string = '';
  defaultHref: string = '/room-list';
  entityType: string = '';
  entityId: string = '';
  assignedItems: any[] = [];
  isSaveDisabled: boolean = true;
  removeItemNote: string = 'Tap the red X next to the item(s) you want to remove from the room. Items that are removed are not deleted.';
  removedItems: any[] = [];

  constructor(
    private navParams: NavParams,
    private uiUtilityService: UiUtilityService,
    private settingsService: SettingsService,
    private nav: IonNav
  ) { }

  ngOnInit(): void {
    this.entityType = this.navParams.get('entityType');
    this.entityId = this.navParams.get('entityId');
    if (this.entityType === ENTITY_ASSET) {
      this.title = 'Assets';
      this.removeItemNote = 'Tap the red X next to the asset(s) you want to remove from the room. Assets that are removed are not deleted.';
    } else if (this.entityType === ENTITY_BED) {
      this.title = 'Beds';
      this.removeItemNote = 'Tap the red X next to the bed(s) you want to remove from the room. Beds that are removed are not deleted.';
    }
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  didViewWillEnter() {
    this.getItemDetails();
  }

  async getItemDetails() {
    await this.uiUtilityService.showLoader();
    this.settingsService.getRoomDetails(this.entityId).subscribe((response: any) => {
      if (response && response.success && response.data) {
        let result: any[] = [];
        let nameKey = 'name';
        let nameKeyFallback = 'localId';
        if (this.entityType === ENTITY_ASSET && response.data?.assets) {
          result = response.data.assets;
          nameKey = 'assetName';
          nameKeyFallback = 'assetLocalId';
        } else if (this.entityType === ENTITY_BED && response.data?.beds) {
          result = response.data.beds
          nameKey = 'bedName';
        }
        this.assignedItems = result.map((item: any) => ({ ...item, itemName: item[nameKey], itemNameFallback: item[nameKeyFallback] }));
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateBack() {
    this.nav.pop();
  }

  onConfirmRemoveItems = async () => {
    await this.uiUtilityService.showLoader();
    let endpoint: any;
    let successMsg = 'The items removed successfully.';
    if (this.entityType === ENTITY_ASSET) {
      endpoint = this.settingsService.removeAssetToRoom(this.entityId, this.removedItems);
      successMsg = 'The assets removed successfully.'
    } else if (this.entityType === ENTITY_BED) {
      endpoint = this.settingsService.removeBedToRoom(this.entityId, this.removedItems);
      successMsg = 'The beds removed successfully.'
    }
    if (!endpoint) {
      return;
    }
    endpoint.subscribe((response: any) => {
      if (response && response.success && response.data) {
        this.navigateBack();
        this.uiUtilityService.showToast(successMsg);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  removeItems() {
    let eType = 'items';
    if (this.entityType === ENTITY_ASSET) {
      eType = 'assets';
    } else if (this.entityType === ENTITY_BED) {
      eType = 'beds';
    }
    const message =
      `Are you sure you want to remove these ${eType} from the room? This will not delete the ${eType} from the system.`;
    const header = `Remove ${this.title}`;
    this.uiUtilityService.presentConfirm(message, this.onConfirmRemoveItems, undefined, header, 'Remove');
  }

  removeItem(assignedItem: any) {
    this.isSaveDisabled = false;
    this.removedItems.push(assignedItem.id);
    this.assignedItems = this.assignedItems.filter((item: any) => item.id !== assignedItem.id);
  }
}
