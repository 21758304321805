import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { environment } from 'src/environments/environment';
import { AssetModel, AssetTypeModel } from 'src/app/shared/models/asset-model';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  activeAssetDetails: any = {};
  constructor(
    private dataService: DataService<any>
  ) { }

  getAssetList(): Observable<ApiResponseModel<AssetModel[]>> {
    const url = `${environment.apiUrl.assetManagement}${FACILITY_ID_URL}`;
    return this.dataService.getData(url);
  }

  getAssetDetails(id: string): Observable<ApiResponseModel<AssetModel>> {
    const url = `${environment.apiUrl.assetManagement}${id}/read`;
    return this.dataService.getData(url);
  }

  getAssetTypes(): Observable<ApiResponseModel<AssetTypeModel[]>> {
    const url = `${environment.apiUrl.assetManagement}getAssetTypes`;
    return this.dataService.getData(url);
  }

  createAsset(payload: any): Observable<any> {
    const url = `${environment.apiUrl.assetManagement}${FACILITY_ID_URL}`;
    return this.dataService.post(url, payload);
  }

  updateAsset(payload: any): Observable<any> {
    const url = `${environment.apiUrl.assetManagement}${FACILITY_ID_URL}`;
    return this.dataService.put(url, payload);
  }

  deleteAsset(assetId: any): Observable<any> {
    const url = `${environment.apiUrl.assetManagement}${FACILITY_ID_URL}/delete/${assetId}`;
    return this.dataService.delete(url, {});
  }

  assignRoomToAsset(assetId: any, roomId: any): Observable<any> {
    const url = `${environment.apiUrl.assetManagement}${FACILITY_ID_URL}/assignRoomToAssets/${assetId}/${roomId}`;
    return this.dataService.getData(url);
  }

  removeRoomToAsset(assetId: any): Observable<any> {
    const url = `${environment.apiUrl.assetManagement}${FACILITY_ID_URL}/removeRoomToAssets/${assetId}`;
    return this.dataService.getData(url);
  }

  assignTagToAsset(url: string, params = {}) {
    return this.dataService.getData(url, params);
  }

  get(controlName: string, form: FormGroup | null): AbstractControl | null {
    return form && form.get(controlName);
  }

  removeBandTagFromAsset(facilityId:string, assetId: any): Observable<any> {
    const url = `${environment.apiUrl.assetManagement}${facilityId}/removeBandTagFromAsset/${assetId}`;
    return this.dataService.getData(url);
  }
}
