import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  /**
   * Set value in session storage.
   * @param name "of local session attribute.
   * @param value Value of item to set.
   * @param  stringify - wether its a json object or not
   */
  setSessionStorage(
    name: string,
    value: any,
    stringify: boolean = false
  ): void {
    sessionStorage.setItem(name, stringify ? JSON.stringify(value) : value);
  }

  /**
   * Get value from session storage.
   * @param name of session storage attribute
   * @param  parse the value or not
   * @returns Value from session storage.
   */
  getSessionStorage(name: string, parse: boolean = false): any {
    return parse
      ? this.jsonParse(sessionStorage.getItem(name) || '')
      : sessionStorage.getItem(name);
  }

  /**
   * Remove value from session storage.
   * @param name of session storage attribute.
   */
  removeSessionStorage(name: string): void {
    sessionStorage.removeItem(name);
  }

  /**
   * Set value in session storage.
   * @param name "of local session attribute.
   * @param value Value of item to set.
   * @param  stringify - wether its a json object or not
   */
  setLocalStorage(name: string, value: any, stringify: boolean = false): void {
    const key = name + environment.clientCode;
    localStorage.setItem(key, stringify ? JSON.stringify(value) : value);
  }

  /**
   * Get value from session storage.
   * @param name of session storage attribute
   * @param  parse the value or not
   * @returns Value from session storage.
   */
  getLocalStorage(name: string, parse: boolean = false): any {
    const key = name + environment.clientCode;
    return parse
      ? this.jsonParse(localStorage.getItem(key) || '')
      : localStorage.getItem(key);
  }

  /**
   * Remove value from session storage.
   * @param name of session storage attribute.
   */
  removeLocalStorage(name: string): void {
    const key = name + environment.clientCode;
    localStorage.removeItem(key);
  }

  /**
   * Check if the result is parsable before.
   * @param item - json string
   * @returns - parsed json object
   */
  jsonParse(item: string): any[] {
    return item ? JSON.parse(item) : null;
  }
}
