export enum ApiBasePathEnum {
    baseUrl = 'https://unified-qa-api.ondosystem.com/',
    dashboard = 'unifiedDashboard/v2/',
    corporate = 'corporate/v1/',
    wearer = 'wearer/v1/',
    bridge = 'bridge/v1/',
    band = 'band/v1/',
    auth = 'auth/',
    user = 'users/v1/',
    report = 'report/v1/',
    role = 'roles/v1/',
    simulator = 'simulator/v1/',
    simulatorQa = 'simulator/v1/',
    assignBandToWearer = 'assignBandToWearer/',
    residentManagement = 'residentManagement/v1/',
    location = 'location/v3/'
}