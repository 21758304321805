import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';

@Injectable({
  providedIn: 'root',
})
class AuthGuardService {

  constructor(private router: Router, private authService: AuthService, private storageService: StorageService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //your logic goes here
    return this.checkUserLogin(next);
  }

  checkUserLogin(route: ActivatedRouteSnapshot): boolean {
    const pathname = route?.routeConfig?.path;
    if (pathname === 'login' || pathname === 'forgot-password') {
      const authToken = this.storageService.getLocalStorage(
        StorageEnum.authToken
      );
      if (authToken) {
        this.authService.logout();
        this.authService.clearStorage();
      }
      return true;
    }
    if (this.authService.isUserLoggedIn) {
      const userRole = this.authService.getUserRole;
      if (route?.routeConfig?.path === 'simulate/:entity' && environment.production) {
        return !this.router.navigate([RouterEnum.dashboard]);
      }
      if (route.data['role'] && route.data['role'].indexOf(userRole) === -1) {
        return !this.router.navigate([RouterEnum.dashboard]);
      }
      return true;
    }
    return !this.router.navigate([RouterEnum.login]);
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthGuardService).canActivate(next, state);
}