import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CorporateService {
  constructor(
    private dataService: DataService<any>
  ) { }

  getCorporateAccountsList(): Observable<ApiResponseModel<any[]>> {
    const url = `${environment.apiUrl.corporate}getCorporateAccountsList`;
    return this.dataService.getData(url);
  }

  getCorpAccountDetails(corpId: string): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.corporate}corpLoginResponse`;
    return this.dataService.getData(url, { corpId });
  }
}
