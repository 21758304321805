export const locationStates = {
    warning: 1,
    ok: 2,
    offline: 3,
    locon: 4,
    locUndefined: 5,
    caution: 6
}
export const locationAlertThresholdState = 802; // i.e. Location Ok / Temperature Caution
export const locationWarningThresholdState = 602; // i.e. Location Warning
// https://ondo.atlassian.net/wiki/spaces/ENGINEERIN/pages/1315078145/Newest+Sorting+HLD

export const notableLocationStates = [locationStates.warning, locationStates.caution];
export const regularLocationStates = [locationStates.ok, locationStates.offline, locationStates.locon, locationStates.locUndefined];