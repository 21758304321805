import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonNav, IonicModule, MenuController, NavParams } from '@ionic/angular';
import { SettingsService } from '../../services/settings.service';
import { RoomListModel } from 'src/app/shared/models/roomListModel';
import { RoomDetails } from 'src/app/shared/models/roomDetailsModel';
import { UiUtilityService } from '../../../../core/services/ui-utility.service';
import { AlertButtonModel } from 'src/app/shared/models/alertButtonModel';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { CreateRoomComponent } from '../create-room/create-room.component';
import { ResidentsListComponent } from '../residents-list/residents-list.component';
import { WearerDetailsPage } from 'src/app/features/wearer-management/pages/wearer-details/wearer-details.page';
import { AssignListPage } from '../../assign-items/assign-list/assign-list.page';
import { SelectRoomPlacementPage } from '../select-room-placement/select-room-placement.page';
import { COMMON_ROOM } from 'src/app/shared/config/app.constant';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-room-details',
  templateUrl: './room-details.page.html',
  styleUrls: ['./room-details.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, AccessControlDirective],
})
export class RoomDetailsPage {
  roomFormData: RoomListModel = new RoomListModel();
  roomDetails: RoomDetails = new RoomDetails();
  RouterEnum = RouterEnum;

  roomName: any;
  roomType: any;
  floorName: any;
  roomId: string = '';
  isRootPage: boolean = false;
  showAssignedResidents: boolean = false;
  showSubtype: boolean = false;
  subType: any;
  isCommonRoom = false;
  isWearerSummaryEnabled = true;

  constructor(
    private settingsService: SettingsService,
    private uiUtilityService: UiUtilityService,
    private ionNav: IonNav,
    private navParams: NavParams,
    private menuCtrl: MenuController,
    private authService: AuthService
  ) { }

  ionViewDidEnter() {
    this.getRoomDetails();
    this.isWearerSummaryEnabled = this.authService.isFeatureEnabled('getWearerDetail');
  }

  getRoomDetails() {
    this.roomId = this.navParams.get('roomId') || this.navParams.get('id') || this.roomId;
    this.settingsService.getRoomDetails(this.roomId).subscribe((res) => {
      if (res && res.success && res.data) {
        const subType = 'None';
        const subRoomType = 'none';
        const roomTypeName = 'Bed Room';
        if (res.data.residents) {
          this.showAssignedResidents = true;
        } else {
          this.showAssignedResidents = false;
        }
        if (res.data.roomDetail.roomTypeName == roomTypeName.toString()) {
          this.showSubtype = false;
        } else {
          this.showSubtype = true;
          this.subType = res.data.roomDetail.subTypeName;
        }
        this.roomDetails = res.data;
        this.roomName = res.data.roomDetail.roomName;
        this.roomType = res.data.roomDetail.roomTypeName;
        this.floorName = res.data.roomDetail.floorName;
        if (this.roomType === COMMON_ROOM) {
          this.isCommonRoom = true;
        }
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(res.errorMessage!, header);
      }
    });
  }

  navigateForward(route: RouterEnum, params?: any) {
    let component: any;
    switch (route) {
      case RouterEnum.editRoom:
        component = CreateRoomComponent;
        break;
      case RouterEnum.residentList:
        component = ResidentsListComponent;
        break;
      case RouterEnum.wearerDetails:
        component = WearerDetailsPage;
        break;
      case RouterEnum.roomPlacement:
        component = SelectRoomPlacementPage;
        break;
      default:
        break;
    }
    if (!this.isWearerSummaryEnabled && route === RouterEnum.wearerDetails) {
      return;
    }
    this.ionNav.push(component, params);
  }

  navigateBack() {
    if (this.isRootPage) {
      this.dismiss();
    } else {
      this.ionNav.pop();
    }
  }

  deleteRoom() {
    const header = 'Delete This Room';
    const message =
      'You are about to delete this room. This action cannot be undone.';
    const deleteObj = {
      TIMEZONE: 'GMT',
      ROOMID: this.roomId,
    };
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
      },
      {
        text: 'Delete',
        handler: () => {
          this.settingsService.deleteRoom(deleteObj).subscribe((res) => {
            if (res) {
              this.navigateBack();
            }
          });
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    }
  }

  onRoomItems(entityType: string) {
    this.ionNav.push(AssignListPage, { entityId: this.roomId, entityType });
  }
}
