<app-unified-header title="Facility Floors" defaultHref="/facility-management"
  [saveBtn]="{title: 'Add', isDisable: floorList.length === maxFloors, rule: 'post'+EndPoints.managefloors}"
  (onSave)="navigateToAddFloor()"></app-unified-header>

<ion-content>
  <ion-list *ngIf="floorList.length; else noFloors">
    <ion-item lines="full">
      <ion-label class="title-l">Floors</ion-label>
    </ion-item>
    <ion-item detail lines="full" *ngFor="let floor of floorList" (click)="navigateToDetailsPage(floor)">
      <ion-label>{{floor.floorName}}</ion-label>
    </ion-item>
  </ion-list>
  <ng-template #noFloors>
    <p class="no-floor">No floors in this facility.</p>
  </ng-template>
</ion-content>