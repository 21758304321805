export const alertThresholdOptionInFOn = new Map([
  [99.3, 35.3],
  [99.7, 35.7],
  [99.9, 36.1],
  [100.4, 36.5],
  [100.8, 36.7],
  [101.2, 36.9],
  [101.5, 37.1],
]);
export const alertThresholdOptionInFOff = new Map([
  [95.6, 35.3],
  [96.3, 35.7],
  [97.0, 36.1],
  [97.7, 36.5],
  [98.1, 36.7],
  [98.5, 36.9],
  [98.8, 37.1],
]);
export const alertThresholdOptionInCOn = new Map([
  [37.4, 35.3],
  [37.6, 35.7],
  [37.8, 36.1],
  [38.0, 36.5],
  [38.2, 36.7],
  [38.4, 36.9],
  [38.6, 37.1],
]);
export const alertThresholdOptionInCOff = new Map([
  [35.3, 35.3],
  [35.7, 35.7],
  [36.1, 36.1],
  [36.5, 36.5],
  [36.7, 36.7],
  [36.9, 36.9],
  [37.1, 37.1],
]);

export const temperature: { [key: string]: Map<number, number> } = {
  alertThresholdOptionInFOn,
  alertThresholdOptionInFOff,
  alertThresholdOptionInCOn,
  alertThresholdOptionInCOff,
};
