<app-unified-header title="Place on Floor Plan" defaultHref="/room-list"></app-unified-header>


<ion-content>
  <ng-container *ngIf="roomDetails?.id">
    <ion-list-header class="detail-header">
      <ion-label>{{roomDetails.roomName}}</ion-label>
    </ion-list-header>
    <ion-item detail class="detail-item" lines="full" (click)="onPlacement('ROOM')">
      <ion-label>Center Point</ion-label>
      <ion-text slot="end">{{roomDetails.xValue && roomDetails.yValue ?
        '('+roomDetails.xValue+', '+roomDetails.yValue+')' :'None'}}</ion-text>
    </ion-item>
  </ng-container>
  <ng-container *ngIf="roomDetails?.id && !isCommonRoom">
    <ion-list-header class="detail-header">
      <ion-label>Bed</ion-label>
    </ion-list-header>
    <ion-item detail class="detail-item" lines="full" (click)="onPlacement('BED')">
      <ion-label>Bed Icon</ion-label>
      <ion-text slot="end">{{roomDetails.xyCordinate?.xValue &&
        roomDetails.xyCordinate?.yValue ?
        '('+roomDetails.xyCordinate.xValue+', '+roomDetails.xyCordinate.yValue+')' :'None'}}</ion-text>
    </ion-item>
  </ng-container>
  <div class="no-floor" *ngIf="!roomDetails?.id">
    This room is not assigned to any floor yet.
  </div>
</ion-content>