<ng-container [ngSwitch]="config.controlType">
  <ion-item (click)="detail && showDialog()" *ngSwitchCase="ControlType.TEXT" [detail]="detail"
    [ngClass]="{'interactive': detail}">
    <ion-label>{{config.label}}</ion-label>
    <div slot="end" class="custom-input">
      <ion-input [inputmode]="config.type" [value]="config.value" [placeholder]="config.showNone ? 'None' : ''" readonly
        [ngClass]="getClasses(config)"></ion-input>
    </div>
  </ion-item>
  <ion-item *ngSwitchCase="ControlType.TOGGLE">
    <ion-label>{{config.label}}</ion-label>
    <ion-toggle mode="ios" color="controls" slot="end" [class]="config.id" [checked]="config.value" *ngIf="detail"
      (ionChange)="toggleChanged($event)" [nAccess]="config.rule" [nReadOnly]="true"></ion-toggle>
    <div slot="end" class="custom-input" *ngIf="!detail">
      <ion-input [class]="config.id" [value]="config.value ? 'Yes' : 'No'" readonly></ion-input>
    </div>
  </ion-item>
  <ion-item *ngSwitchCase="ControlType.LINK" [detail]="detail" (click)="navigate()">
    <ion-label>{{config.label}}</ion-label>
    <div slot="end" class="custom-input a-{{config.type}}"
      [ngClass]="{'edit-mode': detail, 'input-link': config.controlType === ControlType.LINK && !detail && config.nav && config.value}">
      <ion-input [value]="config.value || none" [readonly]="!detail" [class]="config.id"></ion-input>
    </div>
  </ion-item>
  <!-- <ion-item *ngSwitchCase="ControlType.SELECT" [detail]="detail">
    <ion-label class="title-l">{{config.label}}</ion-label>
    <ion-select slot="end" [value]="config.value" interface="popover"
      (ionChange)="dropdownChanged($event)" [disabled]="!detail">
      <ion-select-option *ngFor="let item of config.selectOptions" [value]="item['ID']">{{item['label']}}</ion-select-option>
    </ion-select>
  </ion-item> -->
  <ion-note *ngIf="config.helpText" class="ion-padding-horizontal helpText">{{config.helpText}}</ion-note>
</ng-container>