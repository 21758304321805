<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Switch Corporate Account</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="switchAccount()" [disabled]="this.selectControl.value === currentAccount">Switch</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-searchbar (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar" animated
    placeholder="Type to search...">
  </ion-searchbar>
  <ion-list *ngIf="items.length; else noItemsAvailable">
    <ion-radio-group [formControl]="selectControl" (ionChange)="onSelect($event)">
      <ion-item *ngFor="let item of items">
        <ion-radio color="controls" justify="start" labelPlacement="end" mode="md"
          [value]="item.corporateId">{{item.corporateName}} <span
            *ngIf="item.corporateId === currentAccount">(Current)</span></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
  <ng-template #noItemsAvailable>
    <div class="ion-text-center">
      <p>No corporate accounts found.</p>
    </div>
  </ng-template>
</ion-content>