import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Directive({
  selector: '[nAccess]',
  standalone: true
})
export class AccessControlDirective implements AfterViewInit {
  @Input('nAccess') rule: string = '';

  constructor(
    private el: ElementRef,
    private authService: AuthService,
    public renderer: Renderer2,
  ) { }

  ngAfterViewInit() {
    const isEnaled = this.authService.isFeatureEnabled(this.rule);
    if (!isEnaled) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }
}
