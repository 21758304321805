export const REPORT_OPTIONS = [{
    key: '1', value: 'day', disabled: false
}, {
    key: '2', value: 'week', disabled: false
}, {
    key: '3', value: 'month', disabled: true
}, {
    key: '4', value: 'custom', disabled: true
}];
export const WG_OPTIONS_ALL = { id: 0, name: 'All', wearerGroupName: '' };
export const WG_OPTIONS_ASSETS = { id: 1, name: 'Assets', filterKey: 'metaData', filterValue: 'Assets', wearerGroupName: 'asset' };

export const ONDO_HOURS = 12;
export const RL_MIN = 6;
export const RL_MAX = 24;
export const AM = 'AM';
export const PM = 'PM';
export const FUTURE = 'FUTURE';
export const AM_PM_OPTIONS = [{ key: AM, value: 'AM' }, { key: PM, value: 'PM' }];
export const exposureThreshold = 130;
export const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const NONE = 'None';
export const REFRESH_INTERVAL = 2 * 30 * 1000; // 1min;
export const ROOM_SUMMARY_REFRESH_INTERVAL = 1 * 10 * 1000; // 1min;
export const sortingDelimiter = 0;
export const movingWearers = [7, 9, 11, 17];
